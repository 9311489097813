import type {AppSuiteUtility as EnterpriseApi} from '@verily-src/enterprise-app-suite-utility/src/verily-enterprise-app-suite-utility';
import {api} from '@verily-src/phaf-unified-api';
import maybeMockRpc from './maybeMockRpc';

export const mockProfessionalId = 'professionals/mocked_professional_id';

export const getProfessionalId = async (): Promise<string> =>
  maybeMockRpc(
    {},
    'getProfessionalId',
    () => (api as EnterpriseApi).getProfessionalId(),
    () => mockProfessionalId
  );
