import {Box, Typography} from '@mui/material';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {
  Button,
  PhotoLibraryIcon,
  SendIcon,
  TextField,
} from '@verily-src/react-design-system';
import * as linkify from 'linkifyjs';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import createBFFClient from '../../../../api/createBFFClient';
import {getParticipantRecordName} from '../../../../api/getParticipantRecordName';
import AttachmentInput from '../../../../foreground/components/AttachmentInput/AttachmentInput';
import AttachmentPreviewWithClose from '../../../../foreground/components/AttachmentPreview/AttachmentPreviewWithClose';
import HyperlinkPreviewHandler, {
  HyperlinkPreviewType,
} from '../../../../foreground/components/HyperlinkPreviewHandler/HyperlinkPreviewHandler';
import {useThemeContext} from '../../../../foreground/components/ThemeContext/ThemeContext';
import {CreateThreadSendBoxProps} from '../../../../foreground/utility/custComponentProps';
import filterHyperlinkByAllowList from '../../../../foreground/utility/filterHyperlinkByAllowList';
import styles from './SendBox.css';

export const AttachmentCreater = props => {
  const {t} = useTranslation();
  return (
    <AttachmentInput
      buttonProps={{
        sx: {
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          padding: '12px',
          borderRadius: '8px',
          border: 1,
          justifyContent: 'flex-start',
          borderColor: 'background.separator',
          '&:hover': {backgroundColor: 'background.separator'},
        },
      }}
      attachIconHandler={props.attachIconHandler}
    >
      <PhotoLibraryIcon color="action" />
      <Typography variant="body1">
        {
          // TODO: Delete feature flag after merged to production
          config.getBoolean('FEATURE_PDF_ATTACHMENT_ENABLED')
            ? t('attachBotton_createThread_file')
            : t('attachBotton_createThread_verilyMe')
        }
      </Typography>
    </AttachmentInput>
  );
};

export default function SendBox(props: CreateThreadSendBoxProps) {
  const [participantName, setParticipantName] = useState<string>('');
  const themeCont = useThemeContext();
  const applicationSuite = themeCont.applicationSuite;
  const applicationSuiteData = themeCont?.applicationSuiteData;
  const professionalAccountRefName =
    applicationSuiteData?.professionalAccountRefName;
  const participantRecordRefName =
    applicationSuiteData?.participantRecordRefName;
  const {t} = useTranslation();
  useEffect(() => {
    const getParticipantName = async () => {
      try {
        setParticipantName(
          (
            await getParticipantRecordName(
              createBFFClient(),
              professionalAccountRefName,
              participantRecordRefName,
              applicationSuite
            )
          ).participantRecordName
        );
      } catch (err) {
        console.log(err);
      }
    };
    getParticipantName();
  }, []);
  const [hyperlink, setHyperlink] = useState<string>('');
  const [shouldDisplayHyperlinkPreview, setShouldDisplayHyperlinkPreview] =
    useState<boolean>(false);
  const hyperlinkPreviewAllowList = useThemeContext().hyperlinkPreviewAllowList;
  const textFieldOnChangeHandler = e => {
    props.messageBoxHandler(e);
    const urls = filterHyperlinkByAllowList(
      linkify.find(e.target.value, 'url'),
      hyperlinkPreviewAllowList
    );
    const lastUrl = urls.length > 0 ? urls[urls.length - 1] : undefined;
    if (lastUrl === undefined) {
      setHyperlink('');
      setShouldDisplayHyperlinkPreview(false);
    } else if (lastUrl.value !== hyperlink) {
      setHyperlink(lastUrl.value);
      setShouldDisplayHyperlinkPreview(true);
    }
  };
  return (
    <div className={styles.SendBox}>
      <Box
        sx={{
          padding: '10px 16px',
          borderRadius: '5px',
          backgroundColor: 'background.canvas',
        }}
      >
        <Typography variant="body1" color="text.muted">
          {`To: ${participantName}`}
        </Typography>
      </Box>
      <TextField
        placeholder="Subject"
        autoFocus={true}
        fullWidth={true}
        onChange={props.threadBoxHandler}
        inputProps={{maxLength: 100}}
      />
      <div>
        <TextField
          id={props.textFieldId}
          placeholder="Compose a message"
          fullWidth={true}
          onChange={textFieldOnChangeHandler}
          multiline
          rows={4}
          InputProps={{
            style: {
              borderRadius: shouldDisplayHyperlinkPreview
                ? '8px 8px 0px 0px'
                : '8px',
            },
          }}
        />
        {config.getBoolean('FEATURE_HYPERLINK_PREVIEW_ENABLED') &&
          !props.fileAttachment &&
          shouldDisplayHyperlinkPreview && (
            <HyperlinkPreviewHandler
              type={HyperlinkPreviewType.InSendBox}
              width={161.67}
              height={97}
              titleHeight={24}
              url={
                (hyperlink.startsWith('https://') ||
                hyperlink.startsWith('http://')
                  ? ''
                  : 'https://') + hyperlink
              }
              removeDisplayHandler={() =>
                setShouldDisplayHyperlinkPreview(false)
              }
              sx={{
                display: 'flex',
                justifyContent: 'left',
                padding: '10px',
                border: 1.5,
                borderTop: 0,
                borderRadius: '0px 0px 8px 8px',
                borderColor: 'background.separator',
              }}
            />
          )}
      </div>
      {props.fileAttachment ? (
        <AttachmentPreviewWithClose
          mimeType={props.fileAttachment.type}
          url={props.fileAttachment.url}
          dimension={162}
          sxExtra={{alignSelf: 'flex-start'}}
          onClickHandler={props.closeAttachHandler}
          meta={props.fileAttachment.meta}
        />
      ) : (
        <AttachmentCreater attachIconHandler={props.attachIconHandler} />
      )}
      <div className={styles.Buttons}>
        <Button
          color="primary"
          variant="filled"
          aria-label={t('ariaSendMessage_common_common')}
          title={t('ariaSendMessage_common_common')}
          disabled={
            (!props.messageContent && !props.fileAttachment) ||
            !props.conversationName ||
            props.buttonLoading
          }
          onClick={props.sendIconHandler}
          icon={<SendIcon />}
          iconPosition="end"
          data-testid="send-icon"
          sx={{height: '56px', width: '56px'}}
        />
      </div>
    </div>
  );
}
