import {
  ApplicationSuite,
  ParticipantRecord,
} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service';
import {MessagingBFFServiceClient} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service.client';
import getRpcOptions from './getRpcOptions';
import maybeMockRpc from './maybeMockRpc';

export const fakeParticipantRecordName = 'Albert E.';
export const getParticipantRecordName = async (
  client: MessagingBFFServiceClient,
  professionalAccountRefName: string,
  participantRecordRefName: string,
  applicationSuite: ApplicationSuite
): Promise<ParticipantRecord> =>
  maybeMockRpc(
    participantRecordRefName,
    'getParticipantRecordName',
    async participantRecordRefName =>
      (
        await client.getParticipantRecordName(
          {
            participantRecordRefName: participantRecordRefName,
            professionalAccountRefName: professionalAccountRefName,
            applicationSuite: applicationSuite,
          },
          await getRpcOptions()
        )
      ).response,
    participantRecordRefName => ({
      participantRecordRefName: participantRecordRefName,
      participantRecordName: fakeParticipantRecordName,
    })
  );
