import {Box} from '@mui/material';
import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useThemeContext} from './components/ThemeContext/ThemeContext';
import CreateThread from './pages/CreateThread/CreateThread';
import ThreadDetail from './pages/ThreadDetail/ThreadDetail';
import ThreadsOverview from './pages/ThreadsOverview/ThreadsOverview';
import {MessagingState, PageState} from './utility/messagingState';

export default function Messaging(props) {
  const messagingCommon = useThemeContext().styles.Common;
  const [queryParameters] = useSearchParams();
  const threadId = queryParameters.get('messaging_thread_id');
  const [messagingState, setMessagingState] = useState<MessagingState>({
    page: PageState.ThreadsOverview,
  });

  useEffect(() => {
    const tmpTitle = document.title;
    document.title = props.pageTitle ? props.pageTitle : tmpTitle;
    return () => {
      document.title = tmpTitle;
    };
  }, []);
  useEffect(() => {
    if (threadId) {
      setMessagingState({
        page: PageState.ThreadDetail,
        threadId: threadId,
      });
    }
  }, [threadId]);
  const redirect = (newState: MessagingState) => {
    setMessagingState({...messagingState, ...newState});
  };
  const MessagingPage = () => {
    switch (messagingState.page) {
      case PageState.ThreadsOverview:
        return (
          <ThreadsOverview
            redirect={redirect}
            threadId={messagingState?.threadId}
          />
        );
      case PageState.ThreadDetail:
        return (
          <ThreadDetail
            redirect={redirect}
            threadId={messagingState?.threadId}
          />
        );
      case PageState.CreateThread:
        return <CreateThread redirect={redirect} />;
    }
  };
  return <Box sx={messagingCommon}>{MessagingPage()}</Box>;
}
