// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Overall__AeOB4{display:flex;flex:1;flex-direction:column;max-width:750px}`, "",{"version":3,"sources":["webpack://./src/customizations/verilyme/styles/CreateThread.css"],"names":[],"mappings":"AAAA,gBAGE,YAAa,CADb,MAAO,CAEP,qBAAsB,CAHtB,eAIF","sourcesContent":[".Overall {\n  max-width: 750px;\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Overall": `Overall__AeOB4`
};
export default ___CSS_LOADER_EXPORT___;
