// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Overall__sUJSt{display:flex;flex:1;flex-direction:column;padding:26px 0}.Threads__JdMFw{align-items:stretch;align-self:stretch;display:flex;flex:1 1 0;flex-direction:column;margin-top:8px;overflow-y:auto;padding:0 18px}`, "",{"version":3,"sources":["webpack://./src/customizations/verilyenterprise/styles/ThreadsOverview.css"],"names":[],"mappings":"AAAA,gBACE,YAAa,CAEb,MAAO,CADP,qBAAsB,CAEtB,cACF,CAEA,gBAKE,mBAAoB,CAEpB,kBAAmB,CANnB,YAAa,CAKb,UAAW,CAFX,qBAAsB,CADtB,cAAe,CAKf,eAAgB,CANhB,cAOF","sourcesContent":[".Overall {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  padding: 26px 0px;\n}\n\n.Threads {\n  display: flex;\n  padding: 0px 18px;\n  margin-top: 8px;\n  flex-direction: column;\n  align-items: stretch;\n  flex: 1 1 0;\n  align-self: stretch;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Overall": `Overall__sUJSt`,
	"Threads": `Threads__JdMFw`
};
export default ___CSS_LOADER_EXPORT___;
