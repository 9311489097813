import {Typography} from '@mui/material';
import {ArrowBackIcon, Button} from '@verily-src/react-design-system';
import {ThreadDetailHeaderProps} from '../../../../foreground/utility/custComponentProps';

export const BackButton = ({onClickHandler}) => (
  <Button
    onClick={onClickHandler}
    aria-label="Back to Message List"
    title="Back to Message List"
    color="neutral"
    variant="text"
    icon={<ArrowBackIcon />}
    data-testid="arrowback-icon"
  />
);

export default function Header(props: ThreadDetailHeaderProps) {
  return (
    <div
      style={{
        display: 'flex',
        padding: '0px 12px 16px 12px',
        alignItems: 'center',
      }}
    >
      <BackButton onClickHandler={props.onClickHandler} />
      <Typography variant="body1" sx={{wordBreak: 'break-word'}} component="h2">
        <strong>{props.threadName}</strong>
      </Typography>
    </div>
  );
}
