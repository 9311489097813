import {Box} from '@mui/material';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {
  Button,
  PhotoLibraryIcon,
  SendIcon,
  TextField,
} from '@verily-src/react-design-system';
import * as linkify from 'linkifyjs';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import AttachmentInput from '../../../../foreground/components/AttachmentInput/AttachmentInput';
import AttachmentPreviewWithClose from '../../../../foreground/components/AttachmentPreview/AttachmentPreviewWithClose';
import HyperlinkPreviewHandler, {
  HyperlinkPreviewType,
} from '../../../../foreground/components/HyperlinkPreviewHandler/HyperlinkPreviewHandler';
import {useThemeContext} from '../../../../foreground/components/ThemeContext/ThemeContext';
import {ThreadDetailSendBoxProps} from '../../../../foreground/utility/custComponentProps';
import filterHyperlinkByAllowList from '../../../../foreground/utility/filterHyperlinkByAllowList';
import styles from './SendBox.css';

export default function SendBox(props: ThreadDetailSendBoxProps) {
  const {t} = useTranslation();
  const [hyperlink, setHyperlink] = useState<string>('');
  const [shouldDisplayHyperlinkPreview, setShouldDisplayHyperlinkPreview] =
    useState<boolean>(false);
  const hyperlinkPreviewAllowList = useThemeContext().hyperlinkPreviewAllowList;
  const textFieldOnChangeHandler = e => {
    props.textBoxHandler(e);
    const urls = filterHyperlinkByAllowList(
      linkify.find(e.target.value, 'url'),
      hyperlinkPreviewAllowList
    );
    const lastUrl = urls.length > 0 ? urls[urls.length - 1] : undefined;
    if (lastUrl === undefined) {
      setHyperlink('');
      setShouldDisplayHyperlinkPreview(false);
    } else if (lastUrl.value !== hyperlink) {
      setHyperlink(lastUrl.value);
      setShouldDisplayHyperlinkPreview(true);
    }
  };
  const sendIconOnClickHandler = () => {
    props.sendIconHandler();
    setHyperlink('');
    setShouldDisplayHyperlinkPreview(false);
  };
  return (
    <div className={styles.SendBox}>
      <AttachmentInput
        buttonProps={{
          sx: {
            minHeight: '44px',
            minWidth: '44px',
            padding: '4px',
            '&:hover': {backgroundColor: 'state.hoverPrimary'},
          },
          disabled: !!props.fileAttachment,
        }}
        attachIconHandler={props.attachIconHandler}
      >
        <PhotoLibraryIcon color="action" />
      </AttachmentInput>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 'auto',
        }}
      >
        {props.fileAttachment && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'left',
              padding: '10px 16px 0px 16px',
              border: 1.5,
              borderBottom: 0,
              borderRadius: '8px 8px 0px 0px',
              borderColor: 'background.separator',
            }}
          >
            <AttachmentPreviewWithClose
              mimeType={props.fileAttachment.type}
              url={props.fileAttachment.url}
              dimension={162}
              onClickHandler={props.closeAttachHandler}
              meta={props.fileAttachment.meta}
            />
          </Box>
        )}
        {config.getBoolean('FEATURE_HYPERLINK_PREVIEW_ENABLED') &&
          !props.fileAttachment &&
          shouldDisplayHyperlinkPreview && (
            <HyperlinkPreviewHandler
              type={HyperlinkPreviewType.InSendBox}
              width={161.67}
              height={97}
              titleHeight={24}
              url={
                (hyperlink.startsWith('https://') ||
                hyperlink.startsWith('http://')
                  ? ''
                  : 'https://') + hyperlink
              }
              removeDisplayHandler={() =>
                setShouldDisplayHyperlinkPreview(false)
              }
              sx={{
                display: 'flex',
                justifyContent: 'left',
                padding: '10px 16px 10px 16px',
                border: 1.5,
                borderBottom: 0,
                borderRadius: '8px 8px 0px 0px',
                borderColor: 'background.separator',
              }}
            />
          )}
        <TextField
          id={props.textFieldId}
          placeholder="Type message"
          value={props.textBoxValue}
          maxRows={4}
          autoFocus={true}
          fullWidth={true}
          onChange={textFieldOnChangeHandler}
          InputProps={{
            style: {
              borderRadius:
                props.fileAttachment || shouldDisplayHyperlinkPreview
                  ? '0px 0px 8px 8px'
                  : '8px',
            },
          }}
        />
      </Box>
      <Button
        color="primary"
        variant="text"
        aria-label={t('ariaSendMessage_common_common')}
        title={t('ariaSendMessage_common_common')}
        onClick={sendIconOnClickHandler}
        icon={<SendIcon />}
        data-testid="send-icon"
        disabled={props.buttonDisable}
      />
    </div>
  );
}
