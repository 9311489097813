import {CssBaseline, ThemeProvider} from '@mui/material';
import {ApplicationSuite} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service';
import {createContext, useContext} from 'react';
/* eslint-disable @typescript-eslint/no-explicit-any */
type ComponentStyles = {
  ThreadsOverview: any;
  ThreadDetail: any;
  CreateThread: any;
  Common?: any;
};

export type ThemeContextType = {
  theme: any;
  styles: ComponentStyles;
  components: ComponentStyles;
  applicationSuite: ApplicationSuite;
  applicationSuiteData?: {
    professionalAccountRefName: string;
    participantRecordRefName: string;
  };
  hyperlinkPreviewAllowList: string[];
};
/* eslint-enable @typescript-eslint/no-explicit-any */

const ThemeContext = createContext<ThemeContextType>(null);
export const ThemeContextProvider = ({value, children}) => (
  <ThemeProvider theme={value.theme}>
    <CssBaseline />
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  </ThemeProvider>
);
export const useThemeContext = () => useContext(ThemeContext);
