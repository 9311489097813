import {Timestamp} from '@verily-src/verily1-protos/google/protobuf/timestamp';
import {
  ApplicationSuite,
  ListMessagesRequest_Filter,
  ListMessagesResponse,
  MessageWrap,
} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service';
import {MessagingBFFServiceClient} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service.client';
import {
  dayMs,
  hourMs,
  minuteMs,
} from '../customizations/verilyme/components/Common/timeConverter';
import getRpcOptions from './getRpcOptions';
import maybeMockRpc from './maybeMockRpc';

const nowMs = new Date().getTime();

export const listMessagesResponseMock: MessageWrap[] = [
  {
    senderName: 'Dr. Hirosky',
    message: {
      messageRefName: 'messages/msg7-7',
      threadId: 'mocked_thread_id',
      sentTime: Timestamp.fromDate(new Date(nowMs - 3 * hourMs)),
      senderRefName: 'professionalAccounts/mocked_provider_id',
      messageData: {
        messageText:
          'Hi Trish, I’m a doctor at Daybreak Oncology Clinic and happy to help.\n\n' +
          'I see that you’ve been logging severe stomach pain for 3 weeks now. ' +
          'What else can you tell me about your symptoms? You can also find ' +
          'helpful information on https://my.clevelandclinic.org/health, ' +
          'https://www.who.int/, www.mayoclinic.org/, www.google.com.',
      },
      isRead: false,
      isReadByParticipant: true,
    },
  },
  {
    senderName: 'Dr. Hirosky',
    message: {
      messageRefName: 'messages/msg7-6',
      threadId: 'mocked_thread_id',
      sentTime: Timestamp.fromDate(new Date(nowMs - 3 * hourMs - 6 * minuteMs)),
      senderRefName: 'professionalAccounts/mocked_provider_id',
      messageData: {
        messageText:
          'https://www.who.int/, www.mayoclinic.org/, www.google.com.',
      },
      isRead: false,
      isReadByParticipant: true,
    },
  },
  {
    senderName: 'Dr. Hirosky',
    message: {
      messageRefName: 'messages/msg7-5',
      threadId: 'mocked_thread_id',
      sentTime: Timestamp.fromDate(
        new Date(nowMs - 3 * hourMs - 6 * minuteMs - 50000)
      ),
      senderRefName: 'professionalAccounts/mocked_provider_id',
      messageData: {
        messageText: 'https://my.clevelandclinic.org/health',
      },
      isRead: false,
      isReadByParticipant: true,
    },
  },
  {
    senderName: 'Dr. Hirosky',
    message: {
      messageRefName: 'messages/msg7-4',
      threadId: 'mocked_thread_id',
      sentTime: Timestamp.fromDate(new Date(nowMs - 3 * hourMs - 8 * minuteMs)),
      senderRefName: 'professionalAccounts/mocked_provider_id',
      messageData: {
        messageText:
          'What else can you tell me about your symptoms? You can also find helpful information on',
      },
      isRead: false,
      isReadByParticipant: true,
    },
  },
  {
    senderName: 'Dr. Hirosky',
    message: {
      messageRefName: 'messages/msg7-3',
      threadId: 'mocked_thread_id',
      sentTime: Timestamp.fromDate(new Date(nowMs - 3 * hourMs - 9 * minuteMs)),
      senderRefName: 'professionalAccounts/mocked_provider_id',
      messageData: {
        messageText:
          'I see that you’ve been logging severe stomach pain for 3 weeks now.',
      },
      isRead: false,
      isReadByParticipant: true,
    },
  },
  {
    senderName: 'Dr. Hirosky',
    message: {
      messageRefName: 'messages/msg7-2',
      threadId: 'mocked_thread_id',
      sentTime: Timestamp.fromDate(
        new Date(nowMs - 3 * hourMs - 10 * minuteMs + 10000)
      ),
      senderRefName: 'professionalAccounts/mocked_provider_id',
      messageData: {
        messageText:
          'I’m a doctor at Daybreak Oncology Clinic and happy to help.',
      },
      isRead: false,
      isReadByParticipant: true,
    },
  },
  {
    senderName: 'Dr. Hirosky',
    message: {
      messageRefName: 'messages/msg7-1',
      threadId: 'mocked_thread_id',
      sentTime: Timestamp.fromDate(
        new Date(nowMs - 3 * hourMs - 10 * minuteMs - 30000)
      ),
      senderRefName: 'professionalAccounts/mocked_provider_id',
      messageData: {
        messageText: 'Hi Trish,',
      },
      isRead: false,
      isReadByParticipant: true,
    },
  },
  {
    senderName: 'Cassidy Williams',
    message: {
      messageRefName: 'messages/msg6',
      threadId: 'mocked_thread_id',
      sentTime: Timestamp.fromDate(new Date(nowMs - dayMs - hourMs)),
      senderRefName: 'professionalAccounts/mocked_provider_id',
      messageData: {
        messageText:
          'Hi Trish, I’m a nurse at Daybreak Oncology Clinic and happy to help.\n\n' +
          'I see that you’ve been logging severe stomach pain for 3 weeks now. ' +
          'What else can you tell me about your symptoms? You can also find ' +
          'helpful information on https://www.cdc.gov.',
      },
      isRead: false,
      isReadByParticipant: true,
    },
  },
  {
    senderName: 'Albert E',
    message: {
      messageRefName: 'messages/msg5',
      threadId: 'mocked_thread_id',
      sentTime: Timestamp.fromDate(new Date(nowMs - dayMs - hourMs)),
      senderRefName: 'participantRecords/mocked_patient_id',
      messageData: {
        messageText:
          'Hi there, I have a question for you about the stomach pain and nausea I’ve been having. I cannot find it on www.bing.com.',
      },
      isRead: false,
      isReadByParticipant: true,
    },
  },
  {
    senderName: 'Dr. Johnson',
    message: {
      messageRefName: 'messages/msg4',
      threadId: 'mocked_thread_id',
      sentTime: Timestamp.fromDate(new Date(nowMs - 5 * dayMs)),
      senderRefName: 'professionalAccounts/mocked_provider_id',
      messageData: {
        messageText:
          'Hi Trish, I see that you’ve been logging severe stomach pain for 3 weeks now. What else can you tell me about your symptoms? You can also find helpful information on www.google.com.',
      },
      isRead: false,
      isReadByParticipant: true,
    },
  },
  {
    senderName: 'Albert E',
    message: {
      messageRefName: 'messages/msg3-2',
      threadId: 'mocked_thread_id',
      sentTime: Timestamp.fromDate(new Date(nowMs - 5 * dayMs)),
      senderRefName: 'participantRecords/mocked_patient_id',
      messageData: {
        messageText:
          'I have a question for you about the stomach pain and nausea I’ve been having. I cannot find it on www.cdc.gov/.',
      },
      isRead: false,
      isReadByParticipant: true,
    },
  },
  {
    senderName: 'Albert E',
    message: {
      messageRefName: 'messages/msg3-1',
      threadId: 'mocked_thread_id',
      sentTime: Timestamp.fromDate(new Date(nowMs - 5 * dayMs - 30000)),
      senderRefName: 'participantRecords/mocked_patient_id',
      messageData: {
        messageText: 'Hi Dr. Johnson',
      },
      isRead: false,
      isReadByParticipant: true,
    },
  },
  {
    senderName: 'Dr. Johnson',
    message: {
      messageRefName: 'messages/msg2',
      threadId: 'mocked_thread_id',
      sentTime: Timestamp.fromDate(new Date(nowMs - 5 * dayMs)),
      senderRefName: 'professionalAccounts/mocked_provider_id',
      messageData: {
        messageText:
          'Hi Albert, I’m a doctor at ABC Oncology Clinic and happy to help.\n\nI see that you’ve been logging severe stomach pain for 3 weeks now. What else can you tell me about your symptoms? You can also find helpful information on www.google.com.',
      },
      isRead: false,
      isReadByParticipant: true,
    },
  },
  {
    senderName: 'Albert E',
    message: {
      messageRefName: 'messages/msg1',
      threadId: 'mocked_thread_id',
      sentTime: Timestamp.fromDate(new Date(nowMs - 35 * dayMs)),
      senderRefName: 'participantRecords/mocked_patient_id',
      messageData: {
        messageText:
          'Hi there, I have a question for you about the stomach pain and nausea I’ve been having. I cannot find it on www.bing.com.',
      },
      isRead: false,
      isReadByParticipant: true,
    },
  },
];

export const messagesWithImageMocks: MessageWrap[] = [
  {
    senderName: 'Dr. Brown',
    message: {
      messageRefName: 'messages/msg10',
      threadId: 'mocked_thread_id',
      sentTime: Timestamp.fromDate(new Date(nowMs)),
      senderRefName: 'professionalAccounts/mocked_provider_id',
      messageData: {
        messageText:
          'Sure. You can also search on wikimedia.org, here is an image from that site',
        attachment: {
          messageAttachmentId:
            'https://upload.wikimedia.org/wikipedia/commons/4/47/PNG_transparency_demonstration_1.png',
          mimeType: 'image/png',
          blob: null,
        },
      },
      isRead: false,
      isReadByParticipant: false,
    },
  },
  {
    senderName: 'Albert E',
    message: {
      messageRefName: 'messages/msg9',
      threadId: 'mocked_thread_id',
      sentTime: Timestamp.fromDate(new Date(nowMs - 21 * minuteMs)),
      senderRefName: 'participantRecords/mocked_patient_id',
      messageData: {
        messageText:
          'I am AFK right now. Will respond later. Meanwhile you can read this.',
        attachment: {
          messageAttachmentId:
            'https://cdn.filestackcontent.com/wcrjf9qPTCKXV3hMXDwK',
          mimeType: 'application/pdf',
          blob: null,
          metadata: {
            title: 'research.pdf',
            size: 308203,
          },
        },
      },
      isRead: false,
      isReadByParticipant: true,
    },
  },
];

export const listMessages = async (
  client: MessagingBFFServiceClient,
  filter: ListMessagesRequest_Filter,
  threadId: string,
  participantRecordRefName: string,
  professionalAccountRefName: string,
  applicationSuite: ApplicationSuite
): Promise<ListMessagesResponse> =>
  maybeMockRpc(
    filter,
    'listMessages',
    async filter =>
      (
        await client.listMessages(
          {
            filter: filter,
            threadId: threadId,
            participantRecordRefName: participantRecordRefName,
            professionalAccountRefName: professionalAccountRefName,
            pageToken: null,
            applicationSuite: applicationSuite,
          },
          await getRpcOptions()
        )
      ).response,
    async filter => {
      return {
        nextPageToken: null,
        messageWraps:
          Object.prototype.hasOwnProperty.call(filter, 'timeRange') &&
          Timestamp.toDate(filter.timeRange.lowerBound).getTime() >= nowMs
            ? []
            : [...messagesWithImageMocks, ...listMessagesResponseMock],
      };
    }
  );
