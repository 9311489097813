// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinnerOverlay__WR0WS{align-items:center;display:flex;justify-content:center;position:relative;top:3%;z-index:2}`, "",{"version":3,"sources":["webpack://./src/foreground/components/LoadingCircularProgress/LoadingCircularProgress.css"],"names":[],"mappings":"AAAA,uBAOE,kBAAmB,CADnB,YAAa,CAEb,sBAAuB,CAPvB,iBAAkB,CAClB,MAAO,CACP,SAMF","sourcesContent":[".spinnerOverlay {\n  position: relative;\n  top: 3%;\n  z-index: 2;\n\n  /* center the spinner in the overlay */\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinnerOverlay": `spinnerOverlay__WR0WS`
};
export default ___CSS_LOADER_EXPORT___;
