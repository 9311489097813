import {
  alpha,
  Avatar,
  Box,
  ListItem,
  Typography,
  useTheme,
} from '@mui/material';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {MessageWrap} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service';
import * as linkify from 'linkifyjs';
import {useTranslation} from 'react-i18next';
import AttachmentHandler from '../../../../foreground/components/AttachmentHandler/AttachmentHandler';
import HyperlinkPreviewHandler, {
  HyperlinkPreviewType,
} from '../../../../foreground/components/HyperlinkPreviewHandler/HyperlinkPreviewHandler';
import MessageFormater from '../../../../foreground/components/MessageFormater/MessageFormater';
import {useThemeContext} from '../../../../foreground/components/ThemeContext/ThemeContext';
import filterHyperlinkByAllowList from '../../../../foreground/utility/filterHyperlinkByAllowList';
import timeConverter from '../Common/timeConverter';
import styles from './Message.css';

export type MessageProps = {
  showSender: boolean;
  showTimeSince: boolean;
  messageWrap: MessageWrap;
};

export default function Message(props: MessageProps) {
  const {t} = useTranslation();
  const theme = useTheme();
  const isParticipant = props.messageWrap.message.senderRefName.startsWith(
    'participantRecords/'
  );
  const {sender, bkgColor, textColor, linkColor} = isParticipant
    ? {
        sender: t('you_threadDetail_verilyMe'),
        bkgColor: 'primary.main',
        textColor: 'primary.textOnMain',
        linkColor: 'primary.textOnMain',
      }
    : {
        sender: props.messageWrap.senderName,
        bkgColor: 'background.paper',
        textColor: 'text.default',
        linkColor: 'primary.main',
      };
  const hyperlinkPreviewAllowList = useThemeContext().hyperlinkPreviewAllowList;
  const urlInMessageText = !isParticipant
    ? filterHyperlinkByAllowList(
        linkify.find(props.messageWrap.message.messageData.messageText, 'url'),
        hyperlinkPreviewAllowList
      ).slice(0, 3)
    : [];

  return (
    <ListItem
      id={props.messageWrap.message.messageRefName}
      className={styles.MessageBox}
      data-testid="thread-message"
    >
      {props.showSender && (
        <Avatar
          sx={{
            height: 30,
            width: 30,
            color: theme.palette.text.muted,
            backgroundColor: alpha(theme.palette.neutral.main, 0.15),
            border: `1px solid ${theme.palette.neutral?.divider}`,
          }}
        >
          <Typography
            data-testid="message-avatar"
            variant="body1"
            color="text.muted"
          >
            {props.messageWrap.senderName
              .split(' ')
              .filter(x => x.length > 0)
              .map(x => x[0])
              .join('')}
          </Typography>
        </Avatar>
      )}
      <div
        className={
          props.showSender
            ? styles.MessageContent
            : styles.MessageContentWithLeftMargin
        }
      >
        {props.showSender && (
          <Typography data-testid="message-sender" variant="body2">
            {sender}
          </Typography>
        )}
        {!!props.messageWrap.message.messageData?.attachment && (
          <AttachmentHandler
            dimension={225}
            attachmentId={
              props.messageWrap.message.messageData.attachment
                .messageAttachmentId
            }
            meta={props.messageWrap.message.messageData.attachment.metadata}
            sx={{
              alignSelf: 'stretch',
            }}
          />
        )}
        {props.messageWrap.message.messageData.messageText && (
          <Box
            sx={{
              padding: '16px',
              borderRadius: '4px 24px 24px 24px',
              border: 0.5,
              borderColor: 'background.separator',
              backgroundColor: bkgColor,
            }}
          >
            <Typography
              data-testid="message-text"
              variant="body1"
              color={textColor}
              sx={{wordBreak: 'break-word', whiteSpace: 'pre-wrap'}}
            >
              <MessageFormater link={{color: linkColor}}>
                {props.messageWrap.message.messageData.messageText}
              </MessageFormater>
            </Typography>
          </Box>
        )}
        {config.getBoolean('FEATURE_HYPERLINK_PREVIEW_ENABLED') &&
          !props.messageWrap.message.messageData?.attachment &&
          urlInMessageText.map((url, index: number) => (
            <HyperlinkPreviewHandler
              key={index}
              type={HyperlinkPreviewType.UnderMessage}
              width={400}
              height={225}
              titleHeight={68}
              url={
                (url.value.startsWith('https://') ||
                url.value.startsWith('http://')
                  ? ''
                  : 'https://') + url.value
              }
              sx={{
                alignSelf: 'stretch',
              }}
            />
          ))}
        {props.showTimeSince && (
          <div className={styles.MessageCreateTimestamp}>
            <Typography
              data-testid="message-time-since"
              variant="caption"
              color="text.muted"
            >
              {timeConverter(props.messageWrap.message.sentTime, t)}
            </Typography>
          </div>
        )}
      </div>
    </ListItem>
  );
}
