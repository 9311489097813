import {EnterpriseLightTheme} from '@verily-src/react-design-system';
import {ApplicationSuite} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service';
import components from './components';
import styles from './styles';

export default {
  theme: EnterpriseLightTheme,
  styles,
  components,
  applicationSuite: ApplicationSuite.VERILY_ENTERPRISE,
};
