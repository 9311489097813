import {Timestamp} from '@verily-src/verily1-protos/google/protobuf/timestamp';
import {
  ApplicationSuite,
  ListThreadsRequest_Filter,
  ListThreadsResponse,
  ThreadWrap,
} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service';
import {MessagingBFFServiceClient} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service.client';
import getRpcOptions from './getRpcOptions';
import maybeMockRpc from './maybeMockRpc';

export const ListThreadsResponseMock: ThreadWrap[] = [
  {
    thread: {
      threadRefName: 'threads/the_first_thread_test',
      title: 'Title of the first thread',
      participantRecordId: 'mocked_patient_id',
      latestMessage: {
        senderName: 'Dr. Hirosky',
        message: {
          messageRefName: 'messages/msg1',
          threadId: 'mocked_thread_id',
          sentTime: Timestamp.fromDate(new Date(1692949320000)),
          senderRefName: 'professionalAccounts/mocked_provider_id',
          messageData: {
            messageText:
              'Hi Trish, I’m a nurse at Daybreak Oncology Clinic and happy to help. I see that you’ve been logging severe stomach pain for 3 weeks now. What else can you tell me about your symptoms?',
          },
          isRead: false,
          isReadByParticipant: false,
        },
      },
    },
  },
  {
    thread: {
      threadRefName: 'threads/the_second_thread_test',
      title: 'Title of the second thread',
      participantRecordId: 'mocked_patient_id',
      latestMessage: {
        senderName: 'you',
        message: {
          messageRefName: 'messages/msg2',
          threadId: 'mocked_thread_id',
          sentTime: Timestamp.fromDate(new Date(1692776520000)),
          senderRefName: 'participantRecords/mocked_patient_id',
          messageData: {
            messageText:
              'Hi there, I have a question for you about the stomach pain and nausea I’ve been having.',
            attachment: {
              messageAttachmentId: 'a_fake_pdf_id',
              blob: null,
              mimeType: 'application/pdf',
            },
          },
          isRead: true,
          isReadByParticipant: false,
        },
      },
    },
  },
  {
    thread: {
      threadRefName: 'threads/the_third_thread_test',
      title: 'Title of the third thread',
      participantRecordId: 'mocked_patient_id',
      latestMessage: {
        senderName: 'you',
        message: {
          messageRefName: 'messages/msg3',
          threadId: 'mocked_thread_id',
          sentTime: Timestamp.fromDate(new Date(1692627459000)),
          senderRefName: 'participantRecords/mocked_patient_id',
          messageData: {
            messageText: 'The last message in the third test thread.',
            attachment: {
              messageAttachmentId: 'a_fake_photo_id',
              blob: null,
              mimeType: 'image/png',
            },
          },
          isRead: true,
          isReadByParticipant: false,
        },
      },
    },
  },
  {
    thread: {
      threadRefName: 'threads/the_forth_thread_test',
      title:
        'Title of the forth thread that is extra long long long long and loooooooooooooooooooooooooooong',
      participantRecordId: 'mocked_patient_id',
      latestMessage: {
        senderName: 'you',
        message: {
          messageRefName: 'messages/msg4',
          threadId: 'mocked_thread_id',
          sentTime: Timestamp.fromDate(new Date(1692541059000)),
          senderRefName: 'participantRecords/mocked_patient_id',
          messageData: {
            messageText: 'The last message in the forth test thread.',
          },
          isRead: true,
          isReadByParticipant: false,
        },
      },
    },
  },
  {
    thread: {
      threadRefName: 'threads/the_fifth_thread_test',
      title: 'Title of the fifth thread',
      participantRecordId: 'mocked_patient_id',
      latestMessage: {
        senderName: 'you',
        message: {
          messageRefName: 'messages/msg5',
          threadId: 'mocked_thread_id',
          sentTime: Timestamp.fromDate(new Date(1689862659000)),
          senderRefName: 'participantRecords/mocked_patient_id',
          messageData: {
            messageText: 'The last message in the fifth test thread.',
          },
          isRead: true,
          isReadByParticipant: false,
        },
      },
    },
  },
  {
    thread: {
      threadRefName: 'threads/the_sixth_thread_test',
      title: 'Title of the sixth thread',
      participantRecordId: 'mocked_patient_id',
      latestMessage: {
        senderName: 'you',
        message: {
          messageRefName: 'messages/msg6',
          threadId: 'mocked_thread_id',
          sentTime: Timestamp.fromDate(new Date(1688480259000)),
          senderRefName: 'participantRecords/mocked_patient_id',
          messageData: {
            messageText: 'The last message in the sixth test thread.',
          },
          isRead: true,
          isReadByParticipant: false,
        },
      },
    },
  },
];
export const listThreads = async (
  client: MessagingBFFServiceClient,
  filter: ListThreadsRequest_Filter,
  participantRecordRefName: string,
  professionalAccountRefName: string,
  applicationSuite: ApplicationSuite
): Promise<ListThreadsResponse> =>
  maybeMockRpc(
    filter,
    'listThreads',
    async filter =>
      (
        await client.listThreads(
          {
            filter: filter,
            participantRecordRefName: participantRecordRefName,
            professionalAccountRefName: professionalAccountRefName,
            pageToken: null,
            applicationSuite: applicationSuite,
          },
          await getRpcOptions()
        )
      ).response,
    _ => ({
      nextPageToken: null,
      threadWraps: ListThreadsResponseMock,
    })
  );
