import {useMediaQuery} from '@mui/material';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {useEffect, useState} from 'react';
import {Route, Routes, useSearchParams} from 'react-router-dom';
import {DETAIL_VIEW_CARE} from '../../../../vhub/root-config/common/constants';
import createBFFClient from '../api/createBFFClient';
import {getHyperlinkPreviewAllowList} from '../api/getHyperlinkPreviewAllowList';
import {getProfessionalId} from '../api/getProfessionalId';
import ThreadsListener from '../background/components/ThreadsListener/ThreadsListener';
import verilyEnterpriseCusts from '../customizations/verilyenterprise';
import {breakpointValues} from '../customizations/verilyenterprise/styles/breakpoints';
import {ThemeContextProvider} from '../foreground/components/ThemeContext/ThemeContext';
import Messaging from '../foreground/Messaging';

export const DETAIL_VIEW_CARE_PATH = DETAIL_VIEW_CARE.replace(
  /^(\/enterprise)/,
  ''
);

export default function AppEnterprise() {
  // Responsive UI.
  const isLargeWindow = useMediaQuery(`(min-width:${breakpointValues.lg}px)`);
  if (isLargeWindow) {
    verilyEnterpriseCusts.styles.Common.minHeight = '100vh';
    verilyEnterpriseCusts.styles.Common.borderLeft = 2;
    verilyEnterpriseCusts.styles.Common.borderTop = 0;
  } else {
    verilyEnterpriseCusts.styles.Common.minHeight = '60vh';
    verilyEnterpriseCusts.styles.Common.borderLeft = 0;
    verilyEnterpriseCusts.styles.Common.borderTop = 2;
  }
  // Get participantRecordRefName.
  const [queryParameters] = useSearchParams();
  const participantRecordId = queryParameters.get('id');
  const participantRecordRefName = `participantRecords/${participantRecordId}`;
  // Get professionalAccountRefName.
  const [professionalAccountRefName, setProfessionalAccountRefName] =
    useState<string>('');
  const [hyperlinkPreviewAllowList, setHyperlinkPreviewAllowList] = useState<
    string[]
  >([]);
  useEffect(() => {
    const updateProfessionalAccountRefName = async () => {
      try {
        const professionalRef = await getProfessionalId();
        setProfessionalAccountRefName(
          professionalRef.replace(/^(professionals\/)/, 'professionalAccounts/')
        );
      } catch (err) {
        console.log('Error: failed to fetch professionalAccountRefName, ', err);
      }
    };
    updateProfessionalAccountRefName();
  }, []);

  useEffect(() => {
    if (config.getBoolean('FEATURE_HYPERLINK_PREVIEW_ENABLED')) {
      const hyperlinkAllowList = async () => {
        try {
          const client = createBFFClient();
          const allowListReceived = (await getHyperlinkPreviewAllowList(client))
            .hostnames;
          setHyperlinkPreviewAllowList(allowListReceived);
        } catch (err) {
          console.log(
            'Error: failed to get hyperlink preview allow list, ',
            err
          );
        }
      };
      hyperlinkAllowList();
    }
  }, []);

  if (!participantRecordId || !professionalAccountRefName) {
    return null;
  }
  // Construct enterpriseContext.
  const enterpriseContext = {
    ...verilyEnterpriseCusts,
    applicationSuiteData: {
      professionalAccountRefName,
      participantRecordRefName,
    },
    hyperlinkPreviewAllowList: hyperlinkPreviewAllowList,
  };
  return (
    <ThemeContextProvider value={enterpriseContext}>
      <Routes>
        <Route
          path={DETAIL_VIEW_CARE_PATH}
          element={
            <>
              <ThreadsListener />
              <Messaging />
            </>
          }
        />
      </Routes>
    </ThemeContextProvider>
  );
}
