import {Timestamp} from '@verily-src/verily1-protos/google/protobuf/timestamp';
// eslint-disable-next-line node/no-extraneous-import
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {DateTime} from 'luxon';

export default (timepb: Timestamp): string => {
  const timeDate = Timestamp.toDate(timepb);
  const timeDiff = new Date().getTime() - timeDate.getTime();
  /** If message received within the last 10min. */
  if (timeDiff < 600000) {
    if (config.getBoolean('FEATURE_READ_RECEIPTS_ENABLED')) {
      return 'just now';
    } else {
      return 'Just now';
    }
  }
  /** If message received within the last hour. */
  if (timeDiff < 3600000) {
    return `${Math.ceil(timeDiff / 60000)} min ago`;
  }
  /** If message received within the last day. */
  if (timeDiff < 24 * 3600000) {
    return `${Math.ceil(timeDiff / 3600000)} hr ago`;
  }
  /** Message received one day before, show dates instead. */
  return DateTime.fromJSDate(timeDate).toLocaleString(DateTime.DATE_MED);
};
