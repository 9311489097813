import {Box, Typography, useMediaQuery} from '@mui/material';
import {ArrowBackIcon, Button} from '@verily-src/react-design-system';
import {useTranslation} from 'react-i18next';
import {breakpointValues} from '../../styles/breakpoints';
export const BackButton = ({buttonSx, onClickHandler}) => {
  const {t} = useTranslation();
  return (
    <Button
      id="messaging_back_button"
      color="neutral"
      variant="text"
      icon={<ArrowBackIcon />}
      data-testid="arrowback-icon"
      onClick={onClickHandler}
      aria-label={t('ariaBack_common_verilyMe')}
      title={t('ariaBack_common_verilyMe')}
      sx={buttonSx}
    />
  );
};

export default ({head, onClickHandler}) => {
  const isDesktop = useMediaQuery(`(min-width:${breakpointValues.md}px)`);
  const boxExtraSx = isDesktop
    ? {padding: '0px 0px 24px 0px', margin: '0'}
    : {padding: '0px 0px 12px 0px', margin: '-4px 0 0 -4px'};
  const backButtonSx = isDesktop ? {} : {height: '48px', width: '48px'};
  const subjectSx = isDesktop
    ? {wordBreak: 'break-word'}
    : {
        overflow: 'hidden',
        height: '22px',
        wordBreak: 'break-all',
        marginLeft: '-4px',
      };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '8px',
        ...boxExtraSx,
      }}
      component="div"
    >
      <BackButton onClickHandler={onClickHandler} buttonSx={backButtonSx} />
      <Typography variant="body1" sx={subjectSx} component="h1">
        {head}
      </Typography>
    </Box>
  );
};
