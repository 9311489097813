import {Typography, useMediaQuery} from '@mui/material';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {ThreadsOverviewHeaderProps} from '../../../../foreground/utility/custComponentProps';
import hideNav from '../../../../foreground/utility/hideNav';
import {breakpointValues} from '../../styles/breakpoints';
import PlaceHolder from './PlaceHolder';

export default function Header({threads}: ThreadsOverviewHeaderProps) {
  useEffect(() => {
    hideNav(false);
  }, []);
  const noThread: boolean = threads.length === 0;
  const isDesktop = useMediaQuery(`(min-width:${breakpointValues.md}px)`);
  const headVariant = isDesktop ? 'display5' : 'display6';
  const headHeight = isDesktop ? `${96 - 24 * 2}px` : `${76 - 16 * 2}px`;
  const {t} = useTranslation();
  return (
    <>
      <Typography
        data-testid="overview-page-title"
        variant={headVariant}
        component="h1"
        sx={{
          fontWeight: 600,
          height: headHeight,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {t('heading_threadsOverview_verilyMe')}
      </Typography>
      {noThread && <PlaceHolder />}
    </>
  );
}
