import {Box, useTheme} from '@mui/material';
import {CircularProgress} from '@verily-src/react-design-system';
import {useTranslation} from 'react-i18next';

export default ({dimension}) => {
  const {t} = useTranslation();
  const theme = useTheme();
  return (
    <Box
      sx={{
        height: dimension,
        minWidth: dimension,
        width: '100%',
        backgroundColor: 'background.canvas',
        borderRadius: '16px',
        border: `1px solid ${theme.palette.neutral?.divider}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress
        useUnlaunchedComponent={true}
        size="24px"
        aria-label={t('ariaAttachLoading_threadDetail_common')}
        data-testid="attachment-loader"
      />
    </Box>
  );
};
