// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ThreadMD__kSqh1{align-items:center;align-self:stretch;display:flex;justify-content:space-between;margin-bottom:8px}.ThreadLastMessage__mg6ej{line-height:80%;margin-top:8px;max-height:40px;overflow:hidden}`, "",{"version":3,"sources":["webpack://./src/customizations/verilyenterprise/components/ThreadsOverview/Thread.css"],"names":[],"mappings":"AAAA,iBAIE,kBAAmB,CAHnB,kBAAmB,CACnB,YAAa,CACb,6BAA8B,CAE9B,iBACF,CACA,0BAEE,eAAgB,CADhB,cAAe,CAGf,eAAgB,CADhB,eAEF","sourcesContent":[".ThreadMD {\n  align-self: stretch;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 8px;\n}\n.ThreadLastMessage {\n  margin-top: 8px;\n  line-height: 80%;\n  overflow: hidden;\n  max-height: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ThreadMD": `ThreadMD__kSqh1`,
	"ThreadLastMessage": `ThreadLastMessage__mg6ej`
};
export default ___CSS_LOADER_EXPORT___;
