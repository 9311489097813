import {BrowserRouter, Route, Routes} from 'react-router-dom';
import AppEnterprise from './AppEnterprise';
import AppMe from './AppMe';

export default function Root() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="me/*" element={<AppMe />} />
        <Route path="enterprise/*" element={<AppEnterprise />} />
      </Routes>
    </BrowserRouter>
  );
}
