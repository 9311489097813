import {Box, Typography, useTheme} from '@mui/material';
import {RefreshIcon} from '@verily-src/react-design-system';
import {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';

export default ({dimension, callBack}) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const refreshColor = theme.palette.primary.main;
  const attachmentError = useRef(null);
  useEffect(() => {
    attachmentError.current.focus();
  }, []);

  return (
    <Box
      component="button"
      id="refresh_attachment_on_error"
      ref={attachmentError}
      onClick={callBack}
      sx={{
        display: 'flex',
        padding: '16px',
        height: dimension,
        minWidth: dimension,
        width: '100%',
        backgroundColor: 'background.canvas',
        borderRadius: '16px',
        border: `1px solid ${theme.palette.neutral?.divider}`,
        flexDirection: 'column',
        gap: '8px',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="body1" color="text.muted">
        {t('attachErrorMessage_threadDetail_common')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
        }}
      >
        <RefreshIcon
          sx={{
            ['& path']: {
              fill: refreshColor,
            },
          }}
        />
        <Typography variant="button1" sx={{color: refreshColor}}>
          {t('attachErrorRefresh_threadDetail_common')}
        </Typography>
      </Box>
    </Box>
  );
};
