// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinnerOverlay__Hpbn7{align-items:center;bottom:0;display:flex;justify-content:center;left:0;position:absolute;right:0;top:0;z-index:2}`, "",{"version":3,"sources":["webpack://./src/customizations/verilyenterprise/components/LoadingCircularProgress/LoadingCircularProgress.css"],"names":[],"mappings":"AAAA,uBAUE,kBAAmB,CAPnB,QAAS,CAMT,YAAa,CAEb,sBAAuB,CAPvB,MAAO,CAHP,iBAAkB,CAIlB,OAAQ,CAHR,KAAM,CAIN,SAMF","sourcesContent":[".spinnerOverlay {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  z-index: 2;\n\n  /* center the spinner in the overlay */\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinnerOverlay": `spinnerOverlay__Hpbn7`
};
export default ___CSS_LOADER_EXPORT___;
