import {
  ApplicationSuite,
  MessageAttachment,
} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service';
import {MessagingBFFServiceClient} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service.client';
import getRpcOptions from './getRpcOptions';
import maybeMockRpc from './maybeMockRpc';

export const getFakeMessageAttachment = async (
  messageAttachmentId: string
): Promise<MessageAttachment> => {
  const fileType = messageAttachmentId.split('.').slice(-1)[0];
  let attachmentType = 'application/pdf';
  if (fileType === 'png' || fileType == 'jpg' || fileType == 'jpeg') {
    attachmentType = `image/${fileType}`;
  }
  const attachment = await fetch(messageAttachmentId);
  return {
    messageAttachmentId: messageAttachmentId,
    blob: new Uint8Array(await attachment.arrayBuffer()),
    mimeType: attachmentType,
  };
};

export const getMessageAttachment = async (
  client: MessagingBFFServiceClient,
  messageAttachmentId: string,
  professionalAccountRefName: string,
  participantRecordRefName: string,
  applicationSuite: ApplicationSuite
): Promise<MessageAttachment> =>
  maybeMockRpc(
    messageAttachmentId,
    'getMessageAttachment',
    async messageAttachmentId =>
      (
        await client.getMessageAttachment(
          {
            messageAttachmentId: messageAttachmentId,
            participantRecordRefName: participantRecordRefName,
            professionalAccountRefName: professionalAccountRefName,
            applicationSuite: applicationSuite,
          },
          await getRpcOptions()
        )
      ).response,
    messageAttachmentId => getFakeMessageAttachment(messageAttachmentId)
  );
