import CreateThreadHeader from './CreateThread/Header';
import CreateThreadSendBox from './CreateThread/SendBox';
import ThreadDetailHeader from './ThreadDetail/Header';
import MessageContainer from './ThreadDetail/MessageContainer';
import ThreadDetailSendBox from './ThreadDetail/SendBox';
import ThreadsOverviewHeader from './ThreadsOverview/Header';
import ThreadsOverviewSend from './ThreadsOverview/SendButton';
import Thread from './ThreadsOverview/Thread';
const components = {
  ThreadDetail: {
    Header: ThreadDetailHeader,
    MessageContainer: MessageContainer,
    SendBox: ThreadDetailSendBox,
  },
  CreateThread: {
    SendBox: CreateThreadSendBox,
    Header: CreateThreadHeader,
  },
  ThreadsOverview: {
    Header: ThreadsOverviewHeader,
    Thread: Thread,
    SendButton: ThreadsOverviewSend,
  },
};

export default components;
