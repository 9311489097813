import {Box, Typography, useMediaQuery} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {breakpointValues} from '../../styles/breakpoints';
import PlaceHolderImage from './PlaceHolderImage';

export default function PlaceHolder() {
  const {t} = useTranslation();
  const isDesktop = useMediaQuery(`(min-width:${breakpointValues.md}px)`);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: isDesktop ? '16px' : '8px',
        padding: isDesktop ? `56px ${32 - 32}px` : `56px ${24 - 16}px`,
      }}
    >
      {PlaceHolderImage}
      <Typography
        data-testid="no-thread-message"
        variant="display5"
        component="h2"
        sx={{
          marginTop: isDesktop ? `${32 - 16}px` : `${32 - 8}px`,
          textAlign: 'center',
          fontSize: isDesktop ? undefined : 21,
          fontWeight: 600,
        }}
      >
        {t('placeHolder1_threadsOverview_verilyMe')}
      </Typography>
      <Typography
        data-testid="start-new-conversation"
        variant="body1"
        color="text.muted"
        sx={{textAlign: 'center'}}
      >
        {t('placeHolder2_threadsOverview_verilyMe')}
      </Typography>
    </Box>
  );
}
