import {HyperlinkPreviewResource} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service';
import {MessagingBFFServiceClient} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service.client';
import getRpcOptions from './getRpcOptions';
import maybeMockRpc from './maybeMockRpc';

const websiteTitle: Map<string, string> = new Map([
  ['cdc.gov', 'Centers for Disease Control and Prevention'],
  ['who.int', 'World Health Organization (WHO)'],
]);
export const getFakeHyperlinkPreviewResource = async (
  hyperlink: string
): Promise<HyperlinkPreviewResource> => {
  const hostname = new URL(hyperlink).hostname.replace(/^www\./, '');
  const title = websiteTitle.has(hostname) ? websiteTitle.get(hostname) : '404';
  const imageUrl =
    'https://www.cdc.gov/homepage/images/centers-for-disease-control-and-prevention.png?_=78381';
  const imageResp = await fetch(imageUrl);
  return {
    hyperlink: hyperlink,
    title: title,
    heroImage:
      hostname === 'cdc.gov'
        ? new Uint8Array(await imageResp.arrayBuffer())
        : undefined,
    mimeType: hostname === 'cdc.gov' ? 'image/png' : '',
  };
};

export const getHyperlinkPreviewResource = async (
  client: MessagingBFFServiceClient,
  hyperlink: string
): Promise<HyperlinkPreviewResource> =>
  maybeMockRpc(
    hyperlink,
    'getHyperlinkPreviewResource',
    async hyperlink =>
      (
        await client.getHyperlinkPreviewResource(
          {
            hyperlink: hyperlink,
          },
          await getRpcOptions()
        )
      ).response,
    hyperlink => getFakeHyperlinkPreviewResource(hyperlink)
  );
