export default (
  urls: {
    type: string;
    value: string;
    isLink: boolean;
    href: string;
    start: number;
    end: number;
  }[],
  allowList: string[]
) => {
  if (allowList === undefined) {
    return [];
  }
  return urls.filter(urlItem =>
    allowList.some(allowUrl => {
      if (urlItem.value.includes(allowUrl)) {
        const index = urlItem.value.indexOf(allowUrl);
        const followString = urlItem.value[index + allowUrl.length];
        if (
          followString !== undefined &&
          followString !== '/' &&
          followString! !== '.'
        ) {
          return false;
        }
        if (index === 0) {
          return true;
        } else {
          const prefixString = urlItem.value.substring(0, index);
          const allowedPrefixes = [
            'www.',
            'http:',
            'http://',
            'http://www.',
            'https:',
            'https://',
            'https://www.',
          ];
          return allowedPrefixes.includes(prefixString);
        }
      }
      return false;
    })
  );
};
