import {Timestamp} from '@verily-src/verily1-protos/google/protobuf/timestamp';
import {
  ApplicationSuite,
  Message,
  MessageWrap,
} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service';
import {MessagingBFFServiceClient} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service.client';
import {v4 as uuidv4} from 'uuid';
import getRpcOptions from './getRpcOptions';
import maybeMockRpc from './maybeMockRpc';

export const createMessage = async (
  client: MessagingBFFServiceClient,
  message: Message,
  participantRecordRefName: string,
  professionalAccountRefName: string,
  applicationSuite: ApplicationSuite
): Promise<MessageWrap> =>
  maybeMockRpc(
    {message: message, participantRecordRefName: participantRecordRefName},
    'createMessage',
    async messageData =>
      (
        await client.createMessage(
          {
            requestId: uuidv4(),
            message: messageData.message,
            participantRecordRefName: messageData.participantRecordRefName,
            professionalAccountRefName: professionalAccountRefName,
            applicationSuite: applicationSuite,
          },
          await getRpcOptions()
        )
      ).response,
    messageData => ({
      senderName: 'mocked_sender_name',
      message: {
        ...messageData.message,
        messageRefName: 'messages/createMessage_mocked_message_id',
        sentTime: Timestamp.fromDate(new Date()),
      },
    })
  );
