// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Overall__jf1F0{display:flex;flex:1;flex-direction:column;padding:26px 23px}`, "",{"version":3,"sources":["webpack://./src/customizations/verilyenterprise/styles/CreateThread.css"],"names":[],"mappings":"AAAA,gBACE,YAAa,CAEb,MAAO,CACP,qBAAsB,CAFtB,iBAGF","sourcesContent":[".Overall {\n  display: flex;\n  padding: 26px 23px;\n  flex: 1;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Overall": `Overall__jf1F0`
};
export default ___CSS_LOADER_EXPORT___;
