// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SendBox__PC5Uj{align-items:flex-end;align-self:stretch;display:flex;gap:4px;margin-top:12px}.SendBox__PC5Uj::-webkit-scrollbar{display:none}`, "",{"version":3,"sources":["webpack://./src/customizations/verilyenterprise/components/ThreadDetail/SendBox.css"],"names":[],"mappings":"AAAA,gBAEE,oBAAqB,CAGrB,kBAAmB,CAJnB,YAAa,CAEb,OAAQ,CACR,eAEF,CAEA,mCACE,YACF","sourcesContent":[".SendBox {\n  display: flex;\n  align-items: flex-end;\n  gap: 4px;\n  margin-top: 12px;\n  align-self: stretch;\n}\n/* Hide scrollbar for Chrome, Safari and Opera */\n.SendBox::-webkit-scrollbar {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SendBox": `SendBox__PC5Uj`
};
export default ___CSS_LOADER_EXPORT___;
