import {Nav, NavItem} from '@verily-src/nav';
import {ThreadWrap} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {getThreads} from '../../utility/threadNotifier';

type NavItemType = {
  route: NavItem;
  unread: boolean;
};

const getNavItem = (unread: boolean, text: string): NavItemType => ({
  route: {
    name: text,
    path: '/me/messaging',
    order: 2,
    icon: unread
      ? 'UnreadMessageVerilyMeOutlinedIcon'
      : 'MessageVerilyMeOutlinedIcon',
    selectedIcon: unread
      ? 'UnreadMessageVerilyMeFilledIcon'
      : 'MessageVerilyMeFilledIcon',
    children: [],
  },
  unread: unread,
});

const unreadStatus = (threads: ThreadWrap[]): boolean => {
  // TODO: update unread message status logic.
  const unreadCount = threads
    .map(t => (t.thread.latestMessage.message.isRead ? 0 : 1))
    .reduce((a, b) => a + b, 0);
  return unreadCount > 0;
};

export default function NavUpdater() {
  const {t} = useTranslation();
  const navText = t('text_nav_verilyMe');
  useEffect(() => {
    if (Nav === undefined) {
      return;
    }
    let currentNav: NavItemType = getNavItem(false, navText);
    Nav.addItem(currentNav.route);
    const sub = getThreads().subscribe({
      next: obs => {
        const unread = unreadStatus(obs.threads);
        if (currentNav.unread !== unread) {
          Nav.removeItem(currentNav.route);
          currentNav = getNavItem(unread, navText);
          Nav.addItem(currentNav.route);
        }
      },
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);
  return null;
}
