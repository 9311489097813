// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SendBox__BYe9n{-ms-overflow-style:none;align-items:stretch;display:flex;flex:1 1 0;flex-direction:column;gap:16px;overflow-y:auto;scrollbar-width:none}.SendBox__BYe9n::-webkit-scrollbar{display:none}.Warning__azoON{text-align:center}`, "",{"version":3,"sources":["webpack://./src/customizations/verilyme/components/CreateThread/SendBox.css"],"names":[],"mappings":"AAAA,gBAOE,uBAAwB,CAJxB,mBAAoB,CADpB,YAAa,CADb,UAAW,CAGX,qBAAsB,CACtB,QAAS,CACT,eAAgB,CAEhB,oBACF,CAEA,mCACE,YACF,CAEA,gBACE,iBACF","sourcesContent":[".SendBox {\n  flex: 1 1 0;\n  display: flex;\n  align-items: stretch;\n  flex-direction: column;\n  gap: 16px;\n  overflow-y: auto;\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n}\n/* Hide scrollbar for Chrome, Safari and Opera */\n.SendBox::-webkit-scrollbar {\n  display: none;\n}\n\n.Warning {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SendBox": `SendBox__BYe9n`,
	"Warning": `Warning__azoON`
};
export default ___CSS_LOADER_EXPORT___;
