import {Button, CreateIcon} from '@verily-src/react-design-system';
import {ThreadsOverviewSendButtonProps} from '../../../../foreground/utility/custComponentProps';
export default function SendButton({
  newMessageOnClick,
}: ThreadsOverviewSendButtonProps) {
  return (
    <Button
      data-testid="create-message-thread"
      color="primary"
      variant="filled"
      onClick={newMessageOnClick}
      aria-label="Create Message Thread"
      title="Create Message Thread"
      icon={<CreateIcon data-testid="create-icon" />}
      sx={{
        height: '56px',
        width: '56px',
        position: 'absolute',
        right: '32px',
        bottom: '32px',
        zIndex: 1,
      }}
    />
  );
}
