// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Overall__gIDLG{display:flex;flex:1;flex-direction:column}.Threads__QS_0l{-ms-overflow-style:none;align-items:stretch;align-self:stretch;display:flex;flex:1 1 0;flex-direction:column;gap:8px;margin-top:24px;overflow-y:auto;scrollbar-width:none}.Threads__QS_0l::-webkit-scrollbar{display:none}`, "",{"version":3,"sources":["webpack://./src/customizations/verilyme/styles/ThreadsOverview.css"],"names":[],"mappings":"AAAA,gBACE,YAAa,CAEb,MAAO,CADP,qBAEF,CAEA,gBASE,uBAAwB,CALxB,mBAAoB,CAGpB,kBAAmB,CANnB,YAAa,CAKb,UAAW,CAHX,qBAAsB,CAEtB,OAAQ,CAHR,eAAgB,CAMhB,eAAgB,CAEhB,oBACF,CAGA,mCACE,YACF","sourcesContent":[".Overall {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n}\n\n.Threads {\n  display: flex;\n  margin-top: 24px;\n  flex-direction: column;\n  align-items: stretch;\n  gap: 8px;\n  flex: 1 1 0;\n  align-self: stretch;\n  overflow-y: auto;\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n}\n\n/* Hide scrollbar for Chrome, Safari and Opera */\n.Threads::-webkit-scrollbar {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Overall": `Overall__gIDLG`,
	"Threads": `Threads__QS_0l`
};
export default ___CSS_LOADER_EXPORT___;
