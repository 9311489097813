import {Box} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import createBFFClient from '../../../api/createBFFClient';
import {getMessageAttachment} from '../../../api/getMessageAttachment';
import createAttachmentUrl from '../../utility/createAttachmentUrl';
import {FileAttachmentMeta} from '../../utility/custComponentProps';
import AttachmentPreview from '../AttachmentPreview/AttachmentPreview';
import {useThemeContext} from '../ThemeContext/ThemeContext';
import AttachmentError from './AttachmentError';
import AttachmentLoading from './AttachmentLoading';

/* eslint-disable @typescript-eslint/no-explicit-any */
type AttachmentHandlerProps = {
  dimension: number;
  attachmentId: string;
  sx?: any;
  meta?: FileAttachmentMeta;
};
/* eslint-enable @typescript-eslint/no-explicit-any */

enum AttachmentStateEnum {
  unseen = 0,
  loading = 1,
  loaded = 2,
  error = 3,
}

type AttachmentState = {
  status: AttachmentStateEnum;
  url: string;
  mimeType: string;
};

export default (props: AttachmentHandlerProps) => {
  const [attachState, setAttachState] = useState<AttachmentState>({
    status: AttachmentStateEnum.unseen,
    url: '',
    mimeType: '',
  });
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [focusOnAttachment, setFocusOnAttachment] = useState<boolean>(false);
  const themeCont = useThemeContext();
  const applicationSuite = themeCont.applicationSuite;
  const applicationSuiteData = themeCont?.applicationSuiteData;
  const professionalAccountRefName =
    applicationSuiteData?.professionalAccountRefName;
  const participantRecordRefName =
    applicationSuiteData?.participantRecordRefName;
  const label = 'View file in a new tab';
  const loadAttachment = async () => {
    if (
      attachState.status === AttachmentStateEnum.loading ||
      attachState.status === AttachmentStateEnum.loaded
    ) {
      return;
    }
    // load attachment here.
    try {
      setAttachState({
        status: AttachmentStateEnum.loading,
        url: '',
        mimeType: '',
      });
      const client = createBFFClient();
      const attachmentData = await getMessageAttachment(
        client,
        props.attachmentId,
        professionalAccountRefName,
        participantRecordRefName,
        applicationSuite
      );
      const url = createAttachmentUrl(
        attachmentData.blob,
        attachmentData.mimeType
      );
      setAttachState({
        status: AttachmentStateEnum.loaded,
        url: url,
        mimeType: attachmentData.mimeType,
      });
    } catch (err) {
      console.log(err);
      setAttachState({
        status: AttachmentStateEnum.error,
        url: '',
        mimeType: '',
      });
      setFocusOnAttachment(true);
    }
  };
  useEffect(() => {
    if (isVisible && attachState.status === AttachmentStateEnum.unseen) {
      loadAttachment();
    }
  }, [isVisible]);
  const seenRef = useCallback((node: HTMLDivElement) => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null,
        threshold: 0.1,
      }
    );

    if (node) {
      setTimeout(() => {
        observer.observe(node);
      }, 100);
    }
  }, []);

  const renderAttachment = () => {
    switch (attachState.status) {
      case AttachmentStateEnum.unseen:
        return <AttachmentLoading dimension={props.dimension} />;
      case AttachmentStateEnum.loading:
        return <AttachmentLoading dimension={props.dimension} />;
      case AttachmentStateEnum.loaded:
        return (
          <AttachmentPreview
            mimeType={attachState.mimeType}
            url={attachState.url}
            label={label}
            dimension={props.dimension}
            meta={props?.meta}
            focusOn={focusOnAttachment}
          />
        );
      case AttachmentStateEnum.error:
        return (
          <AttachmentError
            dimension={props.dimension}
            callBack={loadAttachment}
          />
        );
    }
    return null;
  };

  return (
    <Box sx={props.sx} ref={seenRef}>
      {renderAttachment()}
    </Box>
  );
};
