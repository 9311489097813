import {Box, SxProps} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import createBFFClient from '../../../api/createBFFClient';
import {getHyperlinkPreviewResource} from '../../../api/getHyperlinkPreviewResource';
import createAttachmentUrl from '../../utility/createAttachmentUrl';
import HyperlinkPreview, {
  HyperlinkPreviewTopRightButtonType,
} from '../HyperlinkPreview/HyperlinkPreview';

export enum HyperlinkPreviewType {
  UnderMessage = 0,
  InSendBox = 1,
}

interface HyperlinkPreviewHandlerProps {
  type: HyperlinkPreviewType;
  url: string;
  width: number;
  height: number;
  titleHeight: number;
  removeDisplayHandler?: () => void;
  sx?: SxProps;
}

enum HyperlinkPreviewStateEnum {
  Unseen = 0,
  Loading = 1,
  LoadedAll = 2,
  ErrorImage = 3,
  Error = 4,
}

interface HyperlinkPreviewState {
  status: HyperlinkPreviewStateEnum;
  url: string;
  title?: string;
  clickable?: boolean;
  loading?: boolean;
  mimeType?: string;
  imageData?: Uint8Array;
}

export default (props: HyperlinkPreviewHandlerProps) => {
  const {t} = useTranslation();
  const client = createBFFClient();
  const [hyperState, setHyperState] = useState<HyperlinkPreviewState>({
    status: HyperlinkPreviewStateEnum.Unseen,
    url: '',
  });
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const label = t('ariaHyperlinkPreviewText_common_common');
  const loadHyperlinkPreview = async () => {
    if (
      props.type === HyperlinkPreviewType.UnderMessage &&
      hyperState.status !== HyperlinkPreviewStateEnum.Unseen
    ) {
      return;
    }
    // Begin preview as loading
    setHyperState({
      status: HyperlinkPreviewStateEnum.Loading,
      url: props.url,
      title: t('ariaLoadingText_common_common'),
      clickable: false,
      loading: true,
    });
    // Fetch website information with URL from BFF.
    try {
      const resource = await getHyperlinkPreviewResource(client, props.url);
      const title =
        resource.title === ''
          ? t('ariaTitleNotAvailableText_common_common')
          : resource.title;
      if (resource.hyperlink === '') {
        setHyperState({
          status: HyperlinkPreviewStateEnum.Error,
          url: props.url,
          title: title,
          clickable: false,
          loading: false,
        });
      } else {
        if (resource.heroImage && resource.heroImage.length > 0) {
          setHyperState({
            status: HyperlinkPreviewStateEnum.LoadedAll,
            url: props.url,
            title: title,
            clickable: true,
            loading: false,
            mimeType: resource.mimeType,
            imageData: resource.heroImage,
          });
        } else {
          setHyperState({
            status: HyperlinkPreviewStateEnum.ErrorImage,
            url: props.url,
            title: title,
            clickable: true,
            loading: false,
          });
        }
      }
    } catch (err) {
      setHyperState({
        status: HyperlinkPreviewStateEnum.Error,
        url: props.url,
        title: t('ariaTitleNotAvailableText_common_common'),
        clickable: false,
        loading: false,
      });
    }
  };

  useEffect(() => {
    if (isVisible && hyperState.status === HyperlinkPreviewStateEnum.Unseen) {
      loadHyperlinkPreview();
    }
  }, [isVisible]);
  const seenRef = useCallback((node: HTMLDivElement) => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null,
        threshold: 0.1,
      }
    );

    if (node) {
      setTimeout(() => {
        observer.observe(node);
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (isVisible) {
      loadHyperlinkPreview();
    }
  }, [props.url]);
  useEffect(() => {
    if (
      props.type === HyperlinkPreviewType.InSendBox &&
      hyperState.status === HyperlinkPreviewStateEnum.Error
    ) {
      props.removeDisplayHandler();
    }
  });

  const renderHyperlinkPreviewUnderMessage = () => {
    if (hyperState.status === HyperlinkPreviewStateEnum.Unseen) {
      return <Box sx={{height: props.height}} />;
    }
    return (
      <Box sx={props.sx}>
        <HyperlinkPreview
          clickable={hyperState.clickable}
          loading={hyperState.loading}
          url={hyperState.url}
          title={hyperState.title}
          imageUrl={
            hyperState.status === HyperlinkPreviewStateEnum.LoadedAll
              ? createAttachmentUrl(hyperState.imageData, hyperState.mimeType)
              : undefined
          }
          width={props.width}
          height={props.height}
          titleHeight={props.titleHeight}
          topRightButton={
            hyperState.clickable && !hyperState.loading
              ? HyperlinkPreviewTopRightButtonType.OpenInNewTab
              : undefined
          }
          label={label}
        />
      </Box>
    );
  };

  const renderHyperlinkPreviewInSendBox = () => {
    if (
      hyperState.status === HyperlinkPreviewStateEnum.ErrorImage ||
      hyperState.status === HyperlinkPreviewStateEnum.LoadedAll
    ) {
      return (
        <Box sx={props.sx}>
          <HyperlinkPreview
            clickable={hyperState.clickable}
            loading={hyperState.loading}
            url={hyperState.url}
            title={hyperState.title}
            imageUrl={
              hyperState.status === HyperlinkPreviewStateEnum.LoadedAll
                ? createAttachmentUrl(hyperState.imageData, hyperState.mimeType)
                : undefined
            }
            width={props.width}
            height={props.height}
            titleHeight={props.titleHeight}
            topRightButton={HyperlinkPreviewTopRightButtonType.ClosePreview}
            topRightButtonOnClick={props.removeDisplayHandler}
            label={label}
          />
        </Box>
      );
    }
    return null;
  };

  return (
    <div ref={seenRef}>
      {props.type === HyperlinkPreviewType.UnderMessage &&
        renderHyperlinkPreviewUnderMessage()}
      {props.type === HyperlinkPreviewType.InSendBox &&
        renderHyperlinkPreviewInSendBox()}
    </div>
  );
};
