import {Box, Button as MuiButton} from '@mui/material';
import {ApplicationSuite} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service';
import {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {FileAttachmentMeta} from '../../utility/custComponentProps';
import {useThemeContext} from '../ThemeContext/ThemeContext';
import FilePreview from './FilePreview';
import ImagePreview from './ImagePreview';

type AttachmentPreviewProps = {
  mimeType: string;
  url: string;
  dimension: number;
  label?: string;
  meta?: FileAttachmentMeta;
  focusOn?: boolean;
};

function PreviewUnclickable(props: AttachmentPreviewProps) {
  if (props.mimeType.startsWith('image/')) {
    return (
      <ImagePreview
        url={props.url}
        dimension={props.dimension}
        alt={props.label}
      />
    );
  }
  return (
    <FilePreview
      mimeType={props.mimeType}
      url={props.url}
      dimension={props.dimension}
      meta={props.meta}
    />
  );
}

export default function AttachmentPreview(props: AttachmentPreviewProps) {
  const {t} = useTranslation();
  const themeContext = useThemeContext();
  const fileName = props?.meta?.title || 'unnamed';
  const onClickDownload = () => {
    const link = document.createElement('a');
    link.href = props.url;
    link.download = fileName;
    link.target = '_blank';
    link.click();
  };
  const onClickOpen = () => {
    window.open(props.url, '_blank');
  };
  const attachmentTestId = props.mimeType.startsWith('image/')
    ? 'open-image'
    : 'open-attachment';
  const onClick = () => {
    props.mimeType.startsWith('image/') || props.mimeType === 'application/pdf'
      ? onClickOpen()
      : onClickDownload();
  };

  const getAriaLabelOfAttachment = () => {
    return props.mimeType.startsWith('image/')
      ? t('ariaAttachedImage_threadDetail_common')
      : themeContext?.applicationSuite === ApplicationSuite.VERILY_ME
      ? `${t('ariaAttachedFile_threadDetail_common')} ${props.meta?.title}, ${
          Math.ceil(props.meta?.size / 10000) / 100
        } ${t('ariaMegabtes_threadDetail_common')}`
      : `${t('ariaAttachedFile_threadDetail_common')} ${props.meta?.title}`;
  };

  const attachmentView = useRef(null);
  useEffect(() => {
    if (props.focusOn) {
      attachmentView.current.focus();
    }
  }, []);

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <PreviewUnclickable {...props} />
      <MuiButton
        id={`${props.mimeType}_${fileName}`}
        ref={attachmentView}
        data-testid={attachmentTestId}
        onClick={onClick}
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
        aria-label={getAriaLabelOfAttachment()}
      />
    </Box>
  );
}
