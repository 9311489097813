// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MessageContainer__ThAF9{display:flex;flex:1 1 0;flex-direction:column-reverse;justify-content:space-between}.MessageContainer__ThAF9::-webkit-scrollbar{display:none}.MessageList__Jvgaq{align-items:stretch;display:flex;flex:1 1 0;flex-direction:column-reverse;gap:24px;justify-content:flex-start;overflow:auto;padding:0 23px}`, "",{"version":3,"sources":["webpack://./src/customizations/verilyenterprise/components/ThreadDetail/MessageContainer.css"],"names":[],"mappings":"AAAA,yBAEE,YAAa,CADb,UAAW,CAGX,6BAA8B,CAD9B,6BAEF,CAGA,4CACE,YACF,CAEA,oBAIE,mBAAoB,CADpB,YAAa,CAFb,UAAW,CAKX,6BAA8B,CAC9B,QAAS,CAFT,0BAA2B,CAH3B,aAAc,CAMd,cACF","sourcesContent":[".MessageContainer {\n  flex: 1 1 0;\n  display: flex;\n  justify-content: space-between;\n  flex-direction: column-reverse;\n}\n\n/* Hide scrollbar for Chrome, Safari and Opera */\n.MessageContainer::-webkit-scrollbar {\n  display: none;\n}\n\n.MessageList {\n  flex: 1 1 0;\n  overflow: auto;\n  display: flex;\n  align-items: stretch;\n  justify-content: flex-start;\n  flex-direction: column-reverse;\n  gap: 24px;\n  padding: 0px 23px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MessageContainer": `MessageContainer__ThAF9`,
	"MessageList": `MessageList__Jvgaq`
};
export default ___CSS_LOADER_EXPORT___;
