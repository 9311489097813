import {useMediaQuery} from '@mui/material';
import {AddIcon, Button} from '@verily-src/react-design-system';
import {useTranslation} from 'react-i18next';
import {ThreadsOverviewSendButtonProps} from '../../../../foreground/utility/custComponentProps';
import {breakpointValues} from '../../styles/breakpoints';

export default function SendButton({
  newMessageOnClick,
}: ThreadsOverviewSendButtonProps) {
  const isDesktop = useMediaQuery(`(min-width:${breakpointValues.md}px)`);
  const extraSx = isDesktop
    ? {right: '32px', top: '32px'}
    : {
        right: '14px',
        top: '14px',
        height: '48px',
        width: '48px',
      };
  const {t} = useTranslation();
  return (
    <div
      onClick={newMessageOnClick}
      style={{
        position: 'absolute',
        zIndex: 1,
        ...extraSx,
      }}
    >
      <Button
        id="messaging_composethread"
        color={isDesktop ? 'primary' : 'neutral'}
        variant={isDesktop ? 'filled' : 'outlined'}
        icon={<AddIcon />}
        iconPosition="start"
        data-testid="new-message-button"
        aria-label={t('ariaNewButton_threadsOverview_verilyMe')}
        title={t('ariaNewButton_threadsOverview_verilyMe')}
        sx={isDesktop ? {} : {margin: '4px !important'}}
      >
        {isDesktop ? t('newButton_threadsOverview_verilyMe') : ''}
      </Button>
    </div>
  );
}
