import {GetHyperlinkPreviewAllowListResponse} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service';
import {MessagingBFFServiceClient} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service.client';
import getRpcOptions from './getRpcOptions';
import maybeMockRpc from './maybeMockRpc';

const hyperlinkPreviewAllowlistMock = ['cdc.gov', 'who.int', 'mayoclinic.org'];

export const getHyperlinkPreviewAllowList = async (
  client: MessagingBFFServiceClient
): Promise<GetHyperlinkPreviewAllowListResponse> =>
  maybeMockRpc(
    {},
    'getHyperlinkPreviewAllowList',
    async () =>
      (await client.getHyperlinkPreviewAllowList({}, await getRpcOptions()))
        .response,
    _ => ({hostnames: hyperlinkPreviewAllowlistMock})
  );
