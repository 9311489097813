import {
  ApplicationSuite,
  Thread,
  ThreadWrap,
} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service';
import {MessagingBFFServiceClient} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service.client';
import {v4 as uuidv4} from 'uuid';
import getRpcOptions from './getRpcOptions';
import maybeMockRpc from './maybeMockRpc';

export const createThread = async (
  client: MessagingBFFServiceClient,
  thread: Thread,
  professionalAccountRefName: string,
  participantRecordRefName: string,
  applicationSuite: ApplicationSuite
): Promise<ThreadWrap> =>
  maybeMockRpc(
    thread,
    'createThread',
    async thread =>
      (
        await client.createThread(
          {
            requestId: uuidv4(),
            thread: thread,
            professionalAccountRefName: professionalAccountRefName,
            participantRecordRefName: participantRecordRefName,
            applicationSuite: applicationSuite,
          },
          await getRpcOptions()
        )
      ).response,
    thread => ({
      organization: null,
      thread: {
        ...thread,
        threadRefName: 'threads/createThread_mocked_thread_id',
      },
    })
  );
