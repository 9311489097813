import {Button as MuiButton} from '@mui/material';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {Snackbar} from '@verily-src/react-design-system';
import {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

// size limit 6 MB
const sizeLimit = 6000000;

export const clearTarget = event => {
  const target = event.target as HTMLButtonElement;
  target.value = null;
};

export default props => {
  const {t} = useTranslation();
  const hiddenFileInput = useRef(null);
  const [sizeError, setSizeError] = useState<boolean>(false);
  const attachHandler = async e => {
    const attachment = e.target.files?.item(0);
    if (attachment.size > sizeLimit) {
      const attachButton = document.getElementById('attach-button');
      attachButton.setAttribute('aria-label', t('ariaAttachmentError_common'));
      const errorAriaLabel = setTimeout(
        () => attachButton.setAttribute('aria-label', title),
        500
      );
      setSizeError(true);
      return () => clearTimeout(errorAriaLabel);
    } else {
      props.attachIconHandler(attachment);
    }
  };

  // TODO: Delete feature flag after merged to production
  const enableAttachPdf = config.getBoolean('FEATURE_PDF_ATTACHMENT_ENABLED');
  const title = enableAttachPdf
    ? t('ariaAttachFile_common_common')
    : t('ariaAttachPhoto_common_common');
  const hiddenInput = (
    <input
      type="file"
      data-testid="attach-button"
      accept={
        enableAttachPdf
          ? 'image/png, image/jpeg, application/pdf'
          : 'image/png, image/jpeg'
      }
      onChange={attachHandler}
      onClick={clearTarget}
      ref={hiddenFileInput}
      style={{display: 'none'}}
    />
  );
  const addAttachment = () => {
    hiddenFileInput.current.click();
  };

  return (
    <>
      <MuiButton
        {...props?.buttonProps}
        id="attach-button"
        onClick={addAttachment}
        title={title}
      >
        {props.children}
        {hiddenInput}
      </MuiButton>
      <Snackbar
        open={sizeError}
        color="error"
        autoHideDuration={6000}
        onClose={() => setSizeError(false)}
        text={t('attachmentOversize_common_common')}
        aria-label={t('ariaAttachmentError_common')}
      />
    </>
  );
};
