import {Box, Typography} from '@mui/material';
import {Button, CloseIcon} from '@verily-src/react-design-system';
import {CreateThreadHeaderProps} from '../../../../foreground/utility/custComponentProps';

export const BackButton = ({onClickHandler}: CreateThreadHeaderProps) => (
  <Button
    onClick={onClickHandler}
    aria-label="Cancel New Message Thread"
    title="Cancel New Message Thread"
    color="neutral"
    variant="text"
    icon={<CloseIcon />}
    data-testid="close-icon"
  />
);

export default function Header({onClickHandler}) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="body1" component="h2">
        <strong>Messages</strong>
      </Typography>
      <BackButton onClickHandler={onClickHandler} />
    </Box>
  );
}
