import {alpha, Box, Button as MuiButton, useTheme} from '@mui/material';
import {CloseIcon} from '@verily-src/react-design-system';
import {useTranslation} from 'react-i18next';
import {FileAttachmentMeta} from '../../utility/custComponentProps';
import AttachmentPreview from './AttachmentPreview';

/* eslint-disable @typescript-eslint/no-explicit-any */
type AttachmentPreviewWithCloseProps = {
  mimeType: string;
  url: string;
  dimension: number;
  onClickHandler: () => void;
  sxExtra?: any;
  meta?: FileAttachmentMeta;
};
/* eslint-enable @typescript-eslint/no-explicit-any */
export const CloseButton = ({onClickHandler}) => {
  const theme = useTheme();
  const {t} = useTranslation();
  return (
    <MuiButton
      aria-label={t('ariaAttachRemove_common_common')}
      title={t('ariaAttachRemove_common_common')}
      onClick={onClickHandler}
      data-testid="attachment-close-button"
      sx={{
        position: 'absolute',
        top: '0',
        right: '0',
        minWidth: '48px',
        minHeight: '48px',
        padding: 0,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '16px',
          border: `1px,solid,${alpha(theme.palette.background.paper, 0.75)}`,
          backgroundColor: 'background.contrast',
          '&:hover': {backgroundColor: 'icon.muted'},
          height: '32px',
          width: '32px',
        }}
      >
        <CloseIcon
          sx={{
            height: '16px',
            width: '16px',
            ['& path']: {
              fill: theme.palette.background.paper,
              fillOpacity: '0.75',
            },
          }}
        />
      </Box>
    </MuiButton>
  );
};

export default function AttachmentPreviewWithClose(
  props: AttachmentPreviewWithCloseProps
) {
  const label = `An attached ${
    props.mimeType.startsWith('image/') ? 'photo' : 'file'
  }.`;
  const minDimension = '45px';
  return (
    <Box
      sx={{
        ...props.sxExtra,
        position: 'relative',
        minHeight: minDimension,
        minWidth: minDimension,
        paddingBottom: '8px',
      }}
    >
      <AttachmentPreview
        mimeType={props.mimeType}
        url={props.url}
        label={label}
        dimension={props.dimension}
        meta={props?.meta}
      />
      <CloseButton onClickHandler={props.onClickHandler} />
    </Box>
  );
}
