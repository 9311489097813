import {ThreadWrap} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service';
import {BehaviorSubject} from 'rxjs';

type ThreadsObservable = {
  threads: ThreadWrap[];
  error: Error | null;
  maxUpdateTime: number;
};
const defaultValue: ThreadsObservable = {
  threads: [],
  error: new Error('not initialized'),
  maxUpdateTime: 0,
};
const threadsObservable = new BehaviorSubject<ThreadsObservable>(defaultValue);
export const pubThreads = (t: ThreadsObservable) => {
  if (t.threads === undefined || t.threads === null) {
    t.threads = [];
  }
  threadsObservable.next(t);
};
export const getThreads = () => threadsObservable;
export const resetThreads = () => {
  threadsObservable.next(defaultValue);
};
