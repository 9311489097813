import {Box} from '@mui/material';

type ImagePreviewProps = {
  url: string;
  dimension: number;
  alt?: string;
};

export default function ImagePreview(props: ImagePreviewProps) {
  return (
    <Box
      component="img"
      sx={{
        maxHeight: props.dimension,
        maxWidth: props.dimension,
        borderRadius: '16px',
        objectFit: 'cover',
      }}
      src={props.url}
      alt={props.alt}
      data-testid="attachment-image"
      aria-hidden={true}
    />
  );
}
