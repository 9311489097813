import {Box, Typography, useTheme} from '@mui/material';
import {FileAttachmentMeta} from '../../utility/custComponentProps';

import {
  FileDownloadIcon,
  PictureAsPdfIcon,
} from '@verily-src/react-design-system';

type FilePreviewProps = {
  mimeType: string;
  url: string;
  dimension: number;
  meta?: FileAttachmentMeta;
};

const TitleBox = ({title, size, dimension}) => {
  const largePreview = dimension > 200;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderTop: 0.5,
        borderColor: 'background.separator',
        padding: '8px 16px',
        gap: '8px',
      }}
    >
      <Typography
        variant={largePreview ? 'body1' : 'caption'}
        sx={{
          overflow: 'hidden',
          display: '-webkit-box',
          textOverflow: 'ellipsis',
          wordBreak: 'break-all',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 3,
        }}
        data-testid="attachment-file-title"
      >
        <strong>{title || 'unnamed'}</strong>
      </Typography>
      {!!size && largePreview && (
        <Typography variant="body2" color="text.muted">
          <strong>{`${Math.ceil(size / 10000) / 100}MB`}</strong>
        </Typography>
      )}
    </Box>
  );
};

const FilePlaceHolderIcon = ({mimeType}) => {
  const theme = useTheme();
  const PlaceHolderIcon =
    mimeType === 'application/pdf' ? PictureAsPdfIcon : FileDownloadIcon;
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <PlaceHolderIcon
        sx={{
          height: '48px',
          width: '48px',
          ['& path']: {
            fill: theme.palette.primary.main,
          },
        }}
      />
    </Box>
  );
};

export default function FilePreview(props: FilePreviewProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        border: 0.5,
        borderRadius: '16px',
        borderColor: 'background.separator',
        backgroundColor: 'background.paper',
        width: props.dimension,
        height: props.dimension,
      }}
      aria-hidden={true}
    >
      <Box
        sx={{
          flex: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '16px 16px 0px 0px',
          overflow: 'hidden',
        }}
      >
        <object
          type={props.mimeType}
          data={props.url}
          width="120%"
          height="100%"
          tabIndex={-1}
          style={{pointerEvents: 'none'}}
          data-testid="file-object"
        >
          <FilePlaceHolderIcon mimeType={props.mimeType} />
        </object>
      </Box>
      <TitleBox
        title={props?.meta?.title}
        size={props?.meta?.size}
        dimension={props.dimension}
      />
    </Box>
  );
}
