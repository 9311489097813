import {ApplicationSuite} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service';
import {MessagingBFFServiceClient} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service.client';
import getRpcOptions from './getRpcOptions';
import maybeMockRpc from './maybeMockRpc';

export const setThreadAsRead = async (
  client: MessagingBFFServiceClient,
  threadId: string,
  professionalAccountRefName: string,
  participantRecordRefName: string,
  applicationSuite: ApplicationSuite
) =>
  maybeMockRpc(
    threadId,
    'setThreadAsRead',
    async threadId =>
      (
        await client.setThreadAsRead(
          {
            threadRefName: `threads/${threadId}`,
            professionalAccountRefName: professionalAccountRefName,
            participantRecordRefName: participantRecordRefName,
            applicationSuite: applicationSuite,
          },
          await getRpcOptions()
        )
      ).response,
    _ => ({})
  );
