// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SendBox__qW0VJ{-ms-overflow-style:none;display:flex;flex:1 1 0;flex-direction:column;gap:16px;margin-top:18px;overflow-y:auto;scrollbar-width:none}.SendBox__qW0VJ::-webkit-scrollbar{display:none}.Buttons__yFPqw{bottom:32px;position:absolute;right:32px;z-index:1}`, "",{"version":3,"sources":["webpack://./src/customizations/verilyenterprise/components/CreateThread/SendBox.css"],"names":[],"mappings":"AAAA,gBAOE,uBAAwB,CALxB,YAAa,CADb,UAAW,CAEX,qBAAsB,CACtB,QAAS,CACT,eAAgB,CAChB,eAAgB,CAEhB,oBACF,CAEA,mCACE,YACF,CACA,gBAGE,WAAY,CAFZ,iBAAkB,CAClB,UAAW,CAEX,SACF","sourcesContent":[".SendBox {\n  flex: 1 1 0;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  margin-top: 18px;\n  overflow-y: auto;\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n}\n/* Hide scrollbar for Chrome, Safari and Opera */\n.SendBox::-webkit-scrollbar {\n  display: none;\n}\n.Buttons {\n  position: absolute;\n  right: 32px;\n  bottom: 32px;\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SendBox": `SendBox__qW0VJ`,
	"Buttons": `Buttons__yFPqw`
};
export default ___CSS_LOADER_EXPORT___;
