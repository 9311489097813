import {Link} from '@mui/material';
import Linkify from 'linkify-react';
/* eslint-disable @typescript-eslint/no-explicit-any */
type MessageFormater = {
  children: string;
  link?: any;
};
/* eslint-enable @typescript-eslint/no-explicit-any */
export default function MessageFormater(props: MessageFormater) {
  const render = ({attributes, content}) => {
    const {href} = attributes;
    return (
      <strong>
        <Link href={href} target="_blank" {...props.link}>
          {content}
        </Link>
      </strong>
    );
  };
  return (
    <Linkify options={{render: {url: render}, defaultProtocol: 'https'}}>
      {props.children}
    </Linkify>
  );
}
