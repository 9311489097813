// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Overall__9uHmc{display:flex;flex:1;flex-direction:column;max-width:750px}`, "",{"version":3,"sources":["webpack://./src/customizations/verilyme/styles/ThreadDetail.css"],"names":[],"mappings":"AAAA,gBACE,YAAa,CAGb,MAAO,CAFP,qBAAsB,CACtB,eAEF","sourcesContent":[".Overall {\n  display: flex;\n  flex-direction: column;\n  max-width: 750px;\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Overall": `Overall__9uHmc`
};
export default ___CSS_LOADER_EXPORT___;
