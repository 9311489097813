import {Box, ListItem, Typography} from '@mui/material';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {MessageWrap} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service';
import * as linkify from 'linkifyjs';
import AttachmentHandler from '../../../../foreground/components/AttachmentHandler/AttachmentHandler';
import HyperlinkPreviewHandler, {
  HyperlinkPreviewType,
} from '../../../../foreground/components/HyperlinkPreviewHandler/HyperlinkPreviewHandler';
import MessageFormater from '../../../../foreground/components/MessageFormater/MessageFormater';
import {useThemeContext} from '../../../../foreground/components/ThemeContext/ThemeContext';
import filterHyperlinkByAllowList from '../../../../foreground/utility/filterHyperlinkByAllowList';
import timeConverter from '../Common/timeConverter';

export default function Message(props: MessageWrap) {
  const isParticipant = props.message.senderRefName.startsWith(
    'participantRecords/'
  );
  const {messageBox, backgroundColor, borderColor, messagePosition} =
    isParticipant
      ? {
          messageBox: {marginRight: '45px'},
          backgroundColor: 'neutral.background',
          borderColor: 'neutral.background',
          messagePosition: 'flex-start',
        }
      : {
          messageBox: {marginLeft: '45px'},
          backgroundColor: 'background.paper',
          borderColor: 'neutral.divider',
          messagePosition: 'flex-end',
        };
  const hyperlinkPreviewAllowList = useThemeContext().hyperlinkPreviewAllowList;
  const urlInMessageText = !isParticipant
    ? filterHyperlinkByAllowList(
        linkify.find(props.message.messageData.messageText, 'url'),
        hyperlinkPreviewAllowList
      ).slice(0, 3)
    : [];
  return (
    <ListItem
      id={props.message.messageRefName}
      data-testid="thread-message"
      sx={{
        ...messageBox,
        display: 'flex',
        flexDirection: 'column',
        alignItems: messagePosition,
        width: 'auto',
        gap: '8px',
        padding: 0,
      }}
    >
      <Box
        sx={{
          backgroundColor: backgroundColor,
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          alignSelf: messagePosition,
          gap: '8px',
          borderRadius: '16px',
          border: 1,
          borderColor: borderColor,
        }}
      >
        <Typography data-testid="message-sender" variant="body2">
          <strong>{props.senderName}</strong>
        </Typography>
        {props.message.messageData.messageText && (
          <Typography
            data-testid="message-text"
            variant="body2"
            sx={{wordBreak: 'break-word', whiteSpace: 'pre-wrap'}}
          >
            <MessageFormater>
              {props.message.messageData.messageText}
            </MessageFormater>
          </Typography>
        )}
      </Box>
      {!!props.message.messageData?.attachment && (
        <AttachmentHandler
          dimension={200}
          attachmentId={
            props.message.messageData.attachment.messageAttachmentId
          }
          meta={props.message.messageData.attachment.metadata}
          sx={{alignSelf: messagePosition}}
        />
      )}
      {config.getBoolean('FEATURE_HYPERLINK_PREVIEW_ENABLED') &&
        !props.message.messageData?.attachment &&
        urlInMessageText.map((url, index: number) => (
          <HyperlinkPreviewHandler
            key={index}
            type={HyperlinkPreviewType.UnderMessage}
            width={203}
            height={135}
            titleHeight={48}
            url={
              (url.value.startsWith('https://') ||
              url.value.startsWith('http://')
                ? ''
                : 'https://') + url.value
            }
            sx={{
              alignSelf: messagePosition,
            }}
          />
        ))}

      <Typography
        data-testid="message-time-since"
        variant="caption"
        color="text.muted"
        sx={{alignSelf: messagePosition, padding: '0px 16px'}}
      >
        {config.getBoolean('FEATURE_READ_RECEIPTS_ENABLED') && (
          <Typography
            data-testid="message-sent-text"
            component="span"
            variant="caption"
            color="text.muted"
          >
            {'Sent\u00A0'}
          </Typography>
        )}
        {timeConverter(props.message.sentTime)}
        {config.getBoolean('FEATURE_READ_RECEIPTS_ENABLED') &&
          !isParticipant &&
          props.message.isReadByParticipant && (
            <Typography
              data-testid="message-read-sign"
              component="span"
              variant="caption"
              color="text.emphasized"
            >
              {'\u00A0\u00A0Read'}
            </Typography>
          )}
      </Typography>
    </ListItem>
  );
}
