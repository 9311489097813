import {List} from '@mui/material';
import {useEffect, useRef, useState} from 'react';
import {ThreadDetailMessageContainerProps} from '../../../../foreground/utility/custComponentProps';
import LoadingCircularProgress from '../LoadingCircularProgress/LoadingCircularProgress';
import Message from './Message';
import styles from './MessageContainer.css';

export default function MessageContainer(
  props: ThreadDetailMessageContainerProps
) {
  const [messageCount, setMessageCouint] = useState(0);
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({behavior: 'smooth'});
  };

  useEffect(() => {
    if (messageCount !== props.messages.length) {
      setMessageCouint(props.messages.length);
      scrollToBottom();
    }
  });

  return (
    <div className={styles.MessageContainer}>
      <List className={styles.MessageList}>
        <li ref={messagesEndRef} />
        {props.messages.map(m => (
          <Message key={m.message.messageRefName} {...m} />
        ))}
      </List>
      <LoadingCircularProgress loading={props.loading} />
    </div>
  );
}
