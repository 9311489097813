// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Overall__shv0M{display:flex;flex:1;flex-direction:column;padding:26px 0 16px}`, "",{"version":3,"sources":["webpack://./src/customizations/verilyenterprise/styles/ThreadDetail.css"],"names":[],"mappings":"AAAA,gBACE,YAAa,CAEb,MAAO,CADP,qBAAsB,CAEtB,mBACF","sourcesContent":[".Overall {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  padding: 26px 0px 16px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Overall": `Overall__shv0M`
};
export default ___CSS_LOADER_EXPORT___;
