// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MessageBox__yAUBQ{align-items:flex-start;align-self:stretch;display:flex;gap:12px;margin-top:8px;padding:0}.MessageContent__jKxjO,.MessageContentWithLeftMargin__H8W2w{align-items:flex-start;display:flex;flex:1 1 0;flex-direction:column;gap:8px}.MessageContentWithLeftMargin__H8W2w{margin-left:42px}.MessageCreateTimestamp__FSrw9{align-self:stretch;margin-bottom:8px;text-align:right}`, "",{"version":3,"sources":["webpack://./src/customizations/verilyme/components/ThreadDetail/Message.css"],"names":[],"mappings":"AAAA,mBAGE,sBAAuB,CAEvB,kBAAmB,CAHnB,YAAa,CAEb,QAAS,CAHT,cAAe,CAKf,SACF,CAQA,4DAHE,sBAAuB,CAHvB,YAAa,CACb,UAAW,CACX,qBAAsB,CAEtB,OASF,CAPA,qCAME,gBACF,CACA,+BAEE,kBAAmB,CACnB,iBAAkB,CAFlB,gBAGF","sourcesContent":[".MessageBox {\n  margin-top: 8px;\n  display: flex;\n  align-items: flex-start;\n  gap: 12px;\n  align-self: stretch;\n  padding: 0;\n}\n.MessageContent {\n  display: flex;\n  flex: 1 1 0;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 8px;\n}\n.MessageContentWithLeftMargin {\n  display: flex;\n  flex: 1 1 0;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 8px;\n  margin-left: 42px;\n}\n.MessageCreateTimestamp {\n  text-align: right;\n  align-self: stretch;\n  margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MessageBox": `MessageBox__yAUBQ`,
	"MessageContent": `MessageContent__jKxjO`,
	"MessageContentWithLeftMargin": `MessageContentWithLeftMargin__H8W2w`,
	"MessageCreateTimestamp": `MessageCreateTimestamp__FSrw9`
};
export default ___CSS_LOADER_EXPORT___;
