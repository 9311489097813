import {Timestamp} from '@verily-src/verily1-protos/google/protobuf/timestamp';
import {ThreadWrap} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {getThreads} from '../../../background/utility/threadNotifier';
import LoadingCircularProgress from '../../components/LoadingCircularProgress/LoadingCircularProgress';
import {useThemeContext} from '../../components/ThemeContext/ThemeContext';
import {MessagingState, PageState} from '../../utility/messagingState';

export type ThreadsOverviewProps = {
  threadId?: string;
  redirect: (a: MessagingState) => void;
};

export default function ThreadsOverview(props: ThreadsOverviewProps) {
  const {t} = useTranslation();
  const themeCont = useThemeContext();
  const styles = themeCont.styles.ThreadsOverview;
  const Thread = themeCont.components.ThreadsOverview.Thread;
  const Header = themeCont.components.ThreadsOverview.Header;
  const SendButton = themeCont.components.ThreadsOverview.SendButton;
  const redirectThread = (threadId: string) => () =>
    props.redirect({page: PageState.ThreadDetail, threadId: threadId});
  const [loading, setLoading] = useState<boolean>(true);
  const [threads, setThreads] = useState<ThreadWrap[]>([]);
  const newMessageOnClick = () => {
    props.redirect({page: PageState.CreateThread});
  };
  const loadingAria = loading
    ? {
        'aria-describedby': t('ariaLoading_threadsOverview_common'),
        'aria-busy': true,
      }
    : {};
  useEffect(() => {
    const sub = getThreads().subscribe({
      next: obs => {
        if (obs.error !== null) {
          setLoading(true);
        } else {
          setLoading(false);
          setThreads(obs.threads);
        }
      },
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);

  return (
    <div className={styles.Overall} {...loadingAria}>
      <Header threads={threads} />
      <LoadingCircularProgress loading={loading} />
      <SendButton newMessageOnClick={newMessageOnClick} />
      <div className={styles.Threads}>
        {threads.map((t, index: number) => {
          const threadId = t.thread.threadRefName.replace(/^(threads\/)/, '');
          const threadTime = Timestamp.toDate(
            t.thread.latestMessage.message.sentTime
          ).getTime();
          return (
            <Thread
              key={`${threadId}_${threadTime}`}
              {...t}
              autoFocus={threadId === props.threadId}
              redirect={redirectThread(threadId)}
              ariaLabel={`Message ${index + 1} out of ${threads.length}, ${
                t.thread.title
              }`}
            />
          );
        })}
      </div>
    </div>
  );
}
