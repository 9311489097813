import {
  ApplicationSuite,
  MessagingConfiguration,
} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service';
import {MessagingBFFServiceClient} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service.client';
import getRpcOptions from './getRpcOptions';
import mockMessagingConfigurationRpc from './mockMessagingConfigurationRpc';

const getMessagingConfigurationMock = () => ({
  isMessagingEnabled:
    process.env.REACT_APP_IS_MESSAGING_ENABLED.toString() === 'true',
});

export const getMessagingConfiguration = async (
  client: MessagingBFFServiceClient,
  applicationSuite: ApplicationSuite
): Promise<MessagingConfiguration> =>
  mockMessagingConfigurationRpc(
    applicationSuite,
    'getMessagingConfiguration',
    async applicationSuite =>
      (
        await client.getMessagingConfiguration(
          {applicationSuite},
          await getRpcOptions()
        )
      ).response,
    _ => getMessagingConfigurationMock()
  );
