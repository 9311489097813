export enum PageState {
  ThreadsOverview = 0,
  ThreadDetail = 1,
  CreateThread = 2,
}

export type MessagingState = {
  page: PageState;
  threadId?: string;
};
