import {useTranslation} from 'react-i18next';
import {CreateThreadHeaderProps} from '../../../../foreground/utility/custComponentProps';
import CommonHeader from '../Common/CommonHeader';

export default function Header({onClickHandler}: CreateThreadHeaderProps) {
  const {t} = useTranslation();
  return (
    <CommonHeader
      head={t('heading_createThread_verilyMe')}
      onClickHandler={onClickHandler}
    />
  );
}
