import CreateThreadStyles from './CreateThread.css';
import ThreadDetailStyles from './ThreadDetail.css';
import ThreadsOverviewStyles from './ThreadsOverview.css';

const styles = {
  ThreadsOverview: ThreadsOverviewStyles,
  ThreadDetail: ThreadDetailStyles,
  CreateThread: CreateThreadStyles,
  Common: {
    display: 'flex',
    borderLeft: 0,
    borderTop: 0,
    borderColor: 'background.separator',
    backgroundColor: 'background.paper',
    position: 'relative',
    minHeight: '0vh',
  },
};

export default styles;
