/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,ts_nocheck,eslint_disable
// @generated from protobuf file "proto/google/fhir/proto/r4/core/valuesets.proto" (package "google.fhir.r4.core", syntax proto3)
// tslint:disable
// @ts-nocheck
//
//    Copyright 2019 Google Inc.
//
//    Licensed under the Apache License, Version 2.0 (the "License");
//    you may not use this file except in compliance with the License.
//    You may obtain a copy of the License at
//
//        https://www.apache.org/licenses/LICENSE-2.0
//
//    Unless required by applicable law or agreed to in writing, software
//    distributed under the License is distributed on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//    See the License for the specific language governing permissions and
//    limitations under the License.
//
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * UCUM units for recording body length measures such as height and head
 * circumference See http://hl7.org/fhir/ValueSet/ucum-bodylength
 *
 * @generated from protobuf message google.fhir.r4.core.BodyLengthUnitsValueSet
 */
export interface BodyLengthUnitsValueSet {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.BodyLengthUnitsValueSet.Value
 */
export enum BodyLengthUnitsValueSet_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: CM = 1;
     */
    CM = 1,
    /**
     * @generated from protobuf enum value: IN_I = 2;
     */
    IN_I = 2
}
/**
 * UCUM units for recording Body Temperature
 * See http://hl7.org/fhir/ValueSet/ucum-bodytemp
 *
 * @generated from protobuf message google.fhir.r4.core.BodyTemperatureUnitsValueSet
 */
export interface BodyTemperatureUnitsValueSet {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.BodyTemperatureUnitsValueSet.Value
 */
export enum BodyTemperatureUnitsValueSet_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: CEL = 1;
     */
    CEL = 1,
    /**
     * @generated from protobuf enum value: DEG_F = 2;
     */
    DEG_F = 2
}
/**
 * UCUM units for recording Body Weight
 * See http://hl7.org/fhir/ValueSet/ucum-bodyweight
 *
 * @generated from protobuf message google.fhir.r4.core.BodyWeightUnitsValueSet
 */
export interface BodyWeightUnitsValueSet {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.BodyWeightUnitsValueSet.Value
 */
export enum BodyWeightUnitsValueSet_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: KG = 1;
     */
    KG = 1,
    /**
     * @generated from protobuf enum value: LB_AV = 2;
     */
    LB_AV = 2,
    /**
     * @generated from protobuf enum value: G = 3;
     */
    G = 3
}
/**
 * Resource types defined as part of FHIR that can be represented as in-line
 * definitions of a care plan activity. See
 * http://hl7.org/fhir/ValueSet/care-plan-activity-kind
 *
 * @generated from protobuf message google.fhir.r4.core.CarePlanActivityKindValueSet
 */
export interface CarePlanActivityKindValueSet {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.CarePlanActivityKindValueSet.Value
 */
export enum CarePlanActivityKindValueSet_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: APPOINTMENT = 1;
     */
    APPOINTMENT = 1,
    /**
     * @generated from protobuf enum value: COMMUNICATION_REQUEST = 2;
     */
    COMMUNICATION_REQUEST = 2,
    /**
     * @generated from protobuf enum value: DEVICE_REQUEST = 3;
     */
    DEVICE_REQUEST = 3,
    /**
     * @generated from protobuf enum value: MEDICATION_REQUEST = 4;
     */
    MEDICATION_REQUEST = 4,
    /**
     * @generated from protobuf enum value: NUTRITION_ORDER = 5;
     */
    NUTRITION_ORDER = 5,
    /**
     * @generated from protobuf enum value: TASK = 6;
     */
    TASK = 6,
    /**
     * @generated from protobuf enum value: SERVICE_REQUEST = 7;
     */
    SERVICE_REQUEST = 7,
    /**
     * @generated from protobuf enum value: VISION_PRESCRIPTION = 8;
     */
    VISION_PRESCRIPTION = 8
}
/**
 * Codes indicating the degree of authority/intentionality associated with a
 * care plan. See http://hl7.org/fhir/ValueSet/care-plan-intent
 *
 * @generated from protobuf message google.fhir.r4.core.CarePlanIntentValueSet
 */
export interface CarePlanIntentValueSet {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.CarePlanIntentValueSet.Value
 */
export enum CarePlanIntentValueSet_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: PROPOSAL = 1;
     */
    PROPOSAL = 1,
    /**
     * @generated from protobuf enum value: PLAN = 2;
     */
    PLAN = 2,
    /**
     * @generated from protobuf enum value: ORDER = 3;
     */
    ORDER = 3,
    /**
     * @generated from protobuf enum value: OPTION = 4;
     */
    OPTION = 4
}
/**
 * Codes that reflect the current state of a clinical impression within its
 * overall lifecycle. See http://hl7.org/fhir/ValueSet/clinicalimpression-status
 *
 * @generated from protobuf message google.fhir.r4.core.ClinicalImpressionStatusValueSet
 */
export interface ClinicalImpressionStatusValueSet {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ClinicalImpressionStatusValueSet.Value
 */
export enum ClinicalImpressionStatusValueSet_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: IN_PROGRESS = 1;
     */
    IN_PROGRESS = 1,
    /**
     * @generated from protobuf enum value: COMPLETED = 2;
     */
    COMPLETED = 2,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 3;
     */
    ENTERED_IN_ERROR = 3
}
/**
 * A set of codes each of which specifies a certain subcategory of the name part
 * in addition to the main name part type. See
 * http://hl7.org/fhir/ValueSet/name-part-qualifier
 *
 * @generated from protobuf message google.fhir.r4.core.EntityNamePartQualifierValueSet
 */
export interface EntityNamePartQualifierValueSet {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.EntityNamePartQualifierValueSet.Value
 */
export enum EntityNamePartQualifierValueSet_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: LS = 1;
     */
    LS = 1,
    /**
     * @generated from protobuf enum value: AC = 2;
     */
    AC = 2,
    /**
     * @generated from protobuf enum value: NB = 3;
     */
    NB = 3,
    /**
     * @generated from protobuf enum value: PR = 4;
     */
    PR = 4,
    /**
     * @generated from protobuf enum value: HON = 5;
     */
    HON = 5,
    /**
     * @generated from protobuf enum value: BR = 6;
     */
    BR = 6,
    /**
     * @generated from protobuf enum value: AD = 7;
     */
    AD = 7,
    /**
     * @generated from protobuf enum value: SP = 8;
     */
    SP = 8,
    /**
     * @generated from protobuf enum value: MID = 9;
     */
    MID = 9,
    /**
     * @generated from protobuf enum value: CL = 10;
     */
    CL = 10,
    /**
     * @generated from protobuf enum value: IN = 11;
     */
    IN = 11,
    /**
     * @generated from protobuf enum value: VV = 12;
     */
    VV = 12
}
/**
 * Real world event relating to the schedule.
 * See http://hl7.org/fhir/ValueSet/event-timing
 *
 * @generated from protobuf message google.fhir.r4.core.EventTimingValueSet
 */
export interface EventTimingValueSet {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.EventTimingValueSet.Value
 */
export enum EventTimingValueSet_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: MORN = 1;
     */
    MORN = 1,
    /**
     * @generated from protobuf enum value: MORN_EARLY = 2;
     */
    MORN_EARLY = 2,
    /**
     * @generated from protobuf enum value: MORN_LATE = 3;
     */
    MORN_LATE = 3,
    /**
     * @generated from protobuf enum value: NOON = 4;
     */
    NOON = 4,
    /**
     * @generated from protobuf enum value: AFT = 5;
     */
    AFT = 5,
    /**
     * @generated from protobuf enum value: AFT_EARLY = 6;
     */
    AFT_EARLY = 6,
    /**
     * @generated from protobuf enum value: AFT_LATE = 7;
     */
    AFT_LATE = 7,
    /**
     * @generated from protobuf enum value: EVE = 8;
     */
    EVE = 8,
    /**
     * @generated from protobuf enum value: EVE_EARLY = 9;
     */
    EVE_EARLY = 9,
    /**
     * @generated from protobuf enum value: EVE_LATE = 10;
     */
    EVE_LATE = 10,
    /**
     * @generated from protobuf enum value: NIGHT = 11;
     */
    NIGHT = 11,
    /**
     * @generated from protobuf enum value: PHS = 12;
     */
    PHS = 12,
    /**
     * @generated from protobuf enum value: HS = 13;
     */
    HS = 13,
    /**
     * @generated from protobuf enum value: WAKE = 14;
     */
    WAKE = 14,
    /**
     * @generated from protobuf enum value: C = 15;
     */
    C = 15,
    /**
     * @generated from protobuf enum value: CM = 16;
     */
    CM = 16,
    /**
     * @generated from protobuf enum value: CD = 17;
     */
    CD = 17,
    /**
     * @generated from protobuf enum value: CV = 18;
     */
    CV = 18,
    /**
     * @generated from protobuf enum value: AC = 19;
     */
    AC = 19,
    /**
     * @generated from protobuf enum value: ACM = 20;
     */
    ACM = 20,
    /**
     * @generated from protobuf enum value: ACD = 21;
     */
    ACD = 21,
    /**
     * @generated from protobuf enum value: ACV = 22;
     */
    ACV = 22,
    /**
     * @generated from protobuf enum value: PC = 23;
     */
    PC = 23,
    /**
     * @generated from protobuf enum value: PCM = 24;
     */
    PCM = 24,
    /**
     * @generated from protobuf enum value: PCD = 25;
     */
    PCD = 25,
    /**
     * @generated from protobuf enum value: PCV = 26;
     */
    PCV = 26
}
/**
 * A list of all the concrete types defined in this version of the FHIR
 * specification - Abstract Types, Data Types and Resource Types. See
 * http://hl7.org/fhir/ValueSet/all-types
 *
 * @generated from protobuf message google.fhir.r4.core.FHIRAllTypesValueSet
 */
export interface FHIRAllTypesValueSet {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.FHIRAllTypesValueSet.Value
 */
export enum FHIRAllTypesValueSet_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ADDRESS = 1;
     */
    ADDRESS = 1,
    /**
     * @generated from protobuf enum value: AGE = 2;
     */
    AGE = 2,
    /**
     * @generated from protobuf enum value: ANNOTATION = 3;
     */
    ANNOTATION = 3,
    /**
     * @generated from protobuf enum value: ATTACHMENT = 4;
     */
    ATTACHMENT = 4,
    /**
     * @generated from protobuf enum value: BACKBONE_ELEMENT = 5;
     */
    BACKBONE_ELEMENT = 5,
    /**
     * @generated from protobuf enum value: CODEABLE_CONCEPT = 6;
     */
    CODEABLE_CONCEPT = 6,
    /**
     * @generated from protobuf enum value: CODING = 7;
     */
    CODING = 7,
    /**
     * @generated from protobuf enum value: CONTACT_DETAIL = 8;
     */
    CONTACT_DETAIL = 8,
    /**
     * @generated from protobuf enum value: CONTACT_POINT = 9;
     */
    CONTACT_POINT = 9,
    /**
     * @generated from protobuf enum value: CONTRIBUTOR = 10;
     */
    CONTRIBUTOR = 10,
    /**
     * @generated from protobuf enum value: COUNT = 11;
     */
    COUNT = 11,
    /**
     * @generated from protobuf enum value: DATA_REQUIREMENT = 12;
     */
    DATA_REQUIREMENT = 12,
    /**
     * @generated from protobuf enum value: DISTANCE = 13;
     */
    DISTANCE = 13,
    /**
     * @generated from protobuf enum value: DOSAGE = 14;
     */
    DOSAGE = 14,
    /**
     * @generated from protobuf enum value: DURATION = 15;
     */
    DURATION = 15,
    /**
     * @generated from protobuf enum value: ELEMENT = 16;
     */
    ELEMENT = 16,
    /**
     * @generated from protobuf enum value: ELEMENT_DEFINITION = 17;
     */
    ELEMENT_DEFINITION = 17,
    /**
     * @generated from protobuf enum value: EXPRESSION = 18;
     */
    EXPRESSION = 18,
    /**
     * @generated from protobuf enum value: EXTENSION = 19;
     */
    EXTENSION = 19,
    /**
     * @generated from protobuf enum value: HUMAN_NAME = 20;
     */
    HUMAN_NAME = 20,
    /**
     * @generated from protobuf enum value: IDENTIFIER = 21;
     */
    IDENTIFIER = 21,
    /**
     * @generated from protobuf enum value: MARKETING_STATUS = 22;
     */
    MARKETING_STATUS = 22,
    /**
     * @generated from protobuf enum value: META = 23;
     */
    META = 23,
    /**
     * @generated from protobuf enum value: MONEY = 24;
     */
    MONEY = 24,
    /**
     * @generated from protobuf enum value: MONEY_QUANTITY = 25;
     */
    MONEY_QUANTITY = 25,
    /**
     * @generated from protobuf enum value: NARRATIVE = 26;
     */
    NARRATIVE = 26,
    /**
     * @generated from protobuf enum value: PARAMETER_DEFINITION = 27;
     */
    PARAMETER_DEFINITION = 27,
    /**
     * @generated from protobuf enum value: PERIOD = 28;
     */
    PERIOD = 28,
    /**
     * @generated from protobuf enum value: POPULATION = 29;
     */
    POPULATION = 29,
    /**
     * @generated from protobuf enum value: PROD_CHARACTERISTIC = 30;
     */
    PROD_CHARACTERISTIC = 30,
    /**
     * @generated from protobuf enum value: PRODUCT_SHELF_LIFE = 31;
     */
    PRODUCT_SHELF_LIFE = 31,
    /**
     * @generated from protobuf enum value: QUANTITY = 32;
     */
    QUANTITY = 32,
    /**
     * @generated from protobuf enum value: RANGE = 33;
     */
    RANGE = 33,
    /**
     * @generated from protobuf enum value: RATIO = 34;
     */
    RATIO = 34,
    /**
     * @generated from protobuf enum value: REFERENCE = 35;
     */
    REFERENCE = 35,
    /**
     * @generated from protobuf enum value: RELATED_ARTIFACT = 36;
     */
    RELATED_ARTIFACT = 36,
    /**
     * @generated from protobuf enum value: SAMPLED_DATA = 37;
     */
    SAMPLED_DATA = 37,
    /**
     * @generated from protobuf enum value: SIGNATURE = 38;
     */
    SIGNATURE = 38,
    /**
     * @generated from protobuf enum value: SIMPLE_QUANTITY = 39;
     */
    SIMPLE_QUANTITY = 39,
    /**
     * @generated from protobuf enum value: SUBSTANCE_AMOUNT = 40;
     */
    SUBSTANCE_AMOUNT = 40,
    /**
     * @generated from protobuf enum value: TIMING = 41;
     */
    TIMING = 41,
    /**
     * @generated from protobuf enum value: TRIGGER_DEFINITION = 42;
     */
    TRIGGER_DEFINITION = 42,
    /**
     * @generated from protobuf enum value: USAGE_CONTEXT = 43;
     */
    USAGE_CONTEXT = 43,
    /**
     * @generated from protobuf enum value: BASE64_BINARY = 44;
     */
    BASE64_BINARY = 44,
    /**
     * @generated from protobuf enum value: BOOLEAN = 45;
     */
    BOOLEAN = 45,
    /**
     * @generated from protobuf enum value: CANONICAL = 46;
     */
    CANONICAL = 46,
    /**
     * @generated from protobuf enum value: CODE = 47;
     */
    CODE = 47,
    /**
     * @generated from protobuf enum value: DATE = 48;
     */
    DATE = 48,
    /**
     * @generated from protobuf enum value: DATE_TIME = 49;
     */
    DATE_TIME = 49,
    /**
     * @generated from protobuf enum value: DECIMAL = 50;
     */
    DECIMAL = 50,
    /**
     * @generated from protobuf enum value: ID = 51;
     */
    ID = 51,
    /**
     * @generated from protobuf enum value: INSTANT = 52;
     */
    INSTANT = 52,
    /**
     * @generated from protobuf enum value: INTEGER = 53;
     */
    INTEGER = 53,
    /**
     * @generated from protobuf enum value: MARKDOWN = 54;
     */
    MARKDOWN = 54,
    /**
     * @generated from protobuf enum value: OID = 55;
     */
    OID = 55,
    /**
     * @generated from protobuf enum value: POSITIVE_INT = 56;
     */
    POSITIVE_INT = 56,
    /**
     * @generated from protobuf enum value: STRING = 57;
     */
    STRING = 57,
    /**
     * @generated from protobuf enum value: TIME = 58;
     */
    TIME = 58,
    /**
     * @generated from protobuf enum value: UNSIGNED_INT = 59;
     */
    UNSIGNED_INT = 59,
    /**
     * @generated from protobuf enum value: URI = 60;
     */
    URI = 60,
    /**
     * @generated from protobuf enum value: URL = 61;
     */
    URL = 61,
    /**
     * @generated from protobuf enum value: UUID = 62;
     */
    UUID = 62,
    /**
     * @generated from protobuf enum value: XHTML = 63;
     */
    XHTML = 63,
    /**
     * @generated from protobuf enum value: ACCOUNT = 64;
     */
    ACCOUNT = 64,
    /**
     * @generated from protobuf enum value: ACTIVITY_DEFINITION = 65;
     */
    ACTIVITY_DEFINITION = 65,
    /**
     * @generated from protobuf enum value: ADVERSE_EVENT = 66;
     */
    ADVERSE_EVENT = 66,
    /**
     * @generated from protobuf enum value: ALLERGY_INTOLERANCE = 67;
     */
    ALLERGY_INTOLERANCE = 67,
    /**
     * @generated from protobuf enum value: APPOINTMENT = 68;
     */
    APPOINTMENT = 68,
    /**
     * @generated from protobuf enum value: APPOINTMENT_RESPONSE = 69;
     */
    APPOINTMENT_RESPONSE = 69,
    /**
     * @generated from protobuf enum value: AUDIT_EVENT = 70;
     */
    AUDIT_EVENT = 70,
    /**
     * @generated from protobuf enum value: BASIC = 71;
     */
    BASIC = 71,
    /**
     * @generated from protobuf enum value: BINARY = 72;
     */
    BINARY = 72,
    /**
     * @generated from protobuf enum value: BIOLOGICALLY_DERIVED_PRODUCT = 73;
     */
    BIOLOGICALLY_DERIVED_PRODUCT = 73,
    /**
     * @generated from protobuf enum value: BODY_STRUCTURE = 74;
     */
    BODY_STRUCTURE = 74,
    /**
     * @generated from protobuf enum value: BUNDLE = 75;
     */
    BUNDLE = 75,
    /**
     * @generated from protobuf enum value: CAPABILITY_STATEMENT = 76;
     */
    CAPABILITY_STATEMENT = 76,
    /**
     * @generated from protobuf enum value: CARE_PLAN = 77;
     */
    CARE_PLAN = 77,
    /**
     * @generated from protobuf enum value: CARE_TEAM = 78;
     */
    CARE_TEAM = 78,
    /**
     * @generated from protobuf enum value: CATALOG_ENTRY = 79;
     */
    CATALOG_ENTRY = 79,
    /**
     * @generated from protobuf enum value: CHARGE_ITEM = 80;
     */
    CHARGE_ITEM = 80,
    /**
     * @generated from protobuf enum value: CHARGE_ITEM_DEFINITION = 81;
     */
    CHARGE_ITEM_DEFINITION = 81,
    /**
     * @generated from protobuf enum value: CLAIM = 82;
     */
    CLAIM = 82,
    /**
     * @generated from protobuf enum value: CLAIM_RESPONSE = 83;
     */
    CLAIM_RESPONSE = 83,
    /**
     * @generated from protobuf enum value: CLINICAL_IMPRESSION = 84;
     */
    CLINICAL_IMPRESSION = 84,
    /**
     * @generated from protobuf enum value: CODE_SYSTEM = 85;
     */
    CODE_SYSTEM = 85,
    /**
     * @generated from protobuf enum value: COMMUNICATION = 86;
     */
    COMMUNICATION = 86,
    /**
     * @generated from protobuf enum value: COMMUNICATION_REQUEST = 87;
     */
    COMMUNICATION_REQUEST = 87,
    /**
     * @generated from protobuf enum value: COMPARTMENT_DEFINITION = 88;
     */
    COMPARTMENT_DEFINITION = 88,
    /**
     * @generated from protobuf enum value: COMPOSITION = 89;
     */
    COMPOSITION = 89,
    /**
     * @generated from protobuf enum value: CONCEPT_MAP = 90;
     */
    CONCEPT_MAP = 90,
    /**
     * @generated from protobuf enum value: CONDITION = 91;
     */
    CONDITION = 91,
    /**
     * @generated from protobuf enum value: CONSENT = 92;
     */
    CONSENT = 92,
    /**
     * @generated from protobuf enum value: CONTRACT = 93;
     */
    CONTRACT = 93,
    /**
     * @generated from protobuf enum value: COVERAGE = 94;
     */
    COVERAGE = 94,
    /**
     * @generated from protobuf enum value: COVERAGE_ELIGIBILITY_REQUEST = 95;
     */
    COVERAGE_ELIGIBILITY_REQUEST = 95,
    /**
     * @generated from protobuf enum value: COVERAGE_ELIGIBILITY_RESPONSE = 96;
     */
    COVERAGE_ELIGIBILITY_RESPONSE = 96,
    /**
     * @generated from protobuf enum value: DETECTED_ISSUE = 97;
     */
    DETECTED_ISSUE = 97,
    /**
     * @generated from protobuf enum value: DEVICE = 98;
     */
    DEVICE = 98,
    /**
     * @generated from protobuf enum value: DEVICE_DEFINITION = 99;
     */
    DEVICE_DEFINITION = 99,
    /**
     * @generated from protobuf enum value: DEVICE_METRIC = 100;
     */
    DEVICE_METRIC = 100,
    /**
     * @generated from protobuf enum value: DEVICE_REQUEST = 101;
     */
    DEVICE_REQUEST = 101,
    /**
     * @generated from protobuf enum value: DEVICE_USE_STATEMENT = 102;
     */
    DEVICE_USE_STATEMENT = 102,
    /**
     * @generated from protobuf enum value: DIAGNOSTIC_REPORT = 103;
     */
    DIAGNOSTIC_REPORT = 103,
    /**
     * @generated from protobuf enum value: DOCUMENT_MANIFEST = 104;
     */
    DOCUMENT_MANIFEST = 104,
    /**
     * @generated from protobuf enum value: DOCUMENT_REFERENCE = 105;
     */
    DOCUMENT_REFERENCE = 105,
    /**
     * @generated from protobuf enum value: DOMAIN_RESOURCE = 106;
     */
    DOMAIN_RESOURCE = 106,
    /**
     * @generated from protobuf enum value: EFFECT_EVIDENCE_SYNTHESIS = 107;
     */
    EFFECT_EVIDENCE_SYNTHESIS = 107,
    /**
     * @generated from protobuf enum value: ENCOUNTER = 108;
     */
    ENCOUNTER = 108,
    /**
     * @generated from protobuf enum value: ENDPOINT = 109;
     */
    ENDPOINT = 109,
    /**
     * @generated from protobuf enum value: ENROLLMENT_REQUEST = 110;
     */
    ENROLLMENT_REQUEST = 110,
    /**
     * @generated from protobuf enum value: ENROLLMENT_RESPONSE = 111;
     */
    ENROLLMENT_RESPONSE = 111,
    /**
     * @generated from protobuf enum value: EPISODE_OF_CARE = 112;
     */
    EPISODE_OF_CARE = 112,
    /**
     * @generated from protobuf enum value: EVENT_DEFINITION = 113;
     */
    EVENT_DEFINITION = 113,
    /**
     * @generated from protobuf enum value: EVIDENCE = 114;
     */
    EVIDENCE = 114,
    /**
     * @generated from protobuf enum value: EVIDENCE_VARIABLE = 115;
     */
    EVIDENCE_VARIABLE = 115,
    /**
     * @generated from protobuf enum value: EXAMPLE_SCENARIO = 116;
     */
    EXAMPLE_SCENARIO = 116,
    /**
     * @generated from protobuf enum value: EXPLANATION_OF_BENEFIT = 117;
     */
    EXPLANATION_OF_BENEFIT = 117,
    /**
     * @generated from protobuf enum value: FAMILY_MEMBER_HISTORY = 118;
     */
    FAMILY_MEMBER_HISTORY = 118,
    /**
     * @generated from protobuf enum value: FLAG = 119;
     */
    FLAG = 119,
    /**
     * @generated from protobuf enum value: GOAL = 120;
     */
    GOAL = 120,
    /**
     * @generated from protobuf enum value: GRAPH_DEFINITION = 121;
     */
    GRAPH_DEFINITION = 121,
    /**
     * @generated from protobuf enum value: GROUP = 122;
     */
    GROUP = 122,
    /**
     * @generated from protobuf enum value: GUIDANCE_RESPONSE = 123;
     */
    GUIDANCE_RESPONSE = 123,
    /**
     * @generated from protobuf enum value: HEALTHCARE_SERVICE = 124;
     */
    HEALTHCARE_SERVICE = 124,
    /**
     * @generated from protobuf enum value: IMAGING_STUDY = 125;
     */
    IMAGING_STUDY = 125,
    /**
     * @generated from protobuf enum value: IMMUNIZATION = 126;
     */
    IMMUNIZATION = 126,
    /**
     * @generated from protobuf enum value: IMMUNIZATION_EVALUATION = 127;
     */
    IMMUNIZATION_EVALUATION = 127,
    /**
     * @generated from protobuf enum value: IMMUNIZATION_RECOMMENDATION = 128;
     */
    IMMUNIZATION_RECOMMENDATION = 128,
    /**
     * @generated from protobuf enum value: IMPLEMENTATION_GUIDE = 129;
     */
    IMPLEMENTATION_GUIDE = 129,
    /**
     * @generated from protobuf enum value: INSURANCE_PLAN = 130;
     */
    INSURANCE_PLAN = 130,
    /**
     * @generated from protobuf enum value: INVOICE = 131;
     */
    INVOICE = 131,
    /**
     * @generated from protobuf enum value: LIBRARY = 132;
     */
    LIBRARY = 132,
    /**
     * @generated from protobuf enum value: LINKAGE = 133;
     */
    LINKAGE = 133,
    /**
     * @generated from protobuf enum value: LIST = 134;
     */
    LIST = 134,
    /**
     * @generated from protobuf enum value: LOCATION = 135;
     */
    LOCATION = 135,
    /**
     * @generated from protobuf enum value: MEASURE = 136;
     */
    MEASURE = 136,
    /**
     * @generated from protobuf enum value: MEASURE_REPORT = 137;
     */
    MEASURE_REPORT = 137,
    /**
     * @generated from protobuf enum value: MEDIA = 138;
     */
    MEDIA = 138,
    /**
     * @generated from protobuf enum value: MEDICATION = 139;
     */
    MEDICATION = 139,
    /**
     * @generated from protobuf enum value: MEDICATION_ADMINISTRATION = 140;
     */
    MEDICATION_ADMINISTRATION = 140,
    /**
     * @generated from protobuf enum value: MEDICATION_DISPENSE = 141;
     */
    MEDICATION_DISPENSE = 141,
    /**
     * @generated from protobuf enum value: MEDICATION_KNOWLEDGE = 142;
     */
    MEDICATION_KNOWLEDGE = 142,
    /**
     * @generated from protobuf enum value: MEDICATION_REQUEST = 143;
     */
    MEDICATION_REQUEST = 143,
    /**
     * @generated from protobuf enum value: MEDICATION_STATEMENT = 144;
     */
    MEDICATION_STATEMENT = 144,
    /**
     * @generated from protobuf enum value: MEDICINAL_PRODUCT = 145;
     */
    MEDICINAL_PRODUCT = 145,
    /**
     * @generated from protobuf enum value: MEDICINAL_PRODUCT_AUTHORIZATION = 146;
     */
    MEDICINAL_PRODUCT_AUTHORIZATION = 146,
    /**
     * @generated from protobuf enum value: MEDICINAL_PRODUCT_CONTRAINDICATION = 147;
     */
    MEDICINAL_PRODUCT_CONTRAINDICATION = 147,
    /**
     * @generated from protobuf enum value: MEDICINAL_PRODUCT_INDICATION = 148;
     */
    MEDICINAL_PRODUCT_INDICATION = 148,
    /**
     * @generated from protobuf enum value: MEDICINAL_PRODUCT_INGREDIENT = 149;
     */
    MEDICINAL_PRODUCT_INGREDIENT = 149,
    /**
     * @generated from protobuf enum value: MEDICINAL_PRODUCT_INTERACTION = 150;
     */
    MEDICINAL_PRODUCT_INTERACTION = 150,
    /**
     * @generated from protobuf enum value: MEDICINAL_PRODUCT_MANUFACTURED = 151;
     */
    MEDICINAL_PRODUCT_MANUFACTURED = 151,
    /**
     * @generated from protobuf enum value: MEDICINAL_PRODUCT_PACKAGED = 152;
     */
    MEDICINAL_PRODUCT_PACKAGED = 152,
    /**
     * @generated from protobuf enum value: MEDICINAL_PRODUCT_PHARMACEUTICAL = 153;
     */
    MEDICINAL_PRODUCT_PHARMACEUTICAL = 153,
    /**
     * @generated from protobuf enum value: MEDICINAL_PRODUCT_UNDESIRABLE_EFFECT = 154;
     */
    MEDICINAL_PRODUCT_UNDESIRABLE_EFFECT = 154,
    /**
     * @generated from protobuf enum value: MESSAGE_DEFINITION = 155;
     */
    MESSAGE_DEFINITION = 155,
    /**
     * @generated from protobuf enum value: MESSAGE_HEADER = 156;
     */
    MESSAGE_HEADER = 156,
    /**
     * @generated from protobuf enum value: MOLECULAR_SEQUENCE = 157;
     */
    MOLECULAR_SEQUENCE = 157,
    /**
     * @generated from protobuf enum value: NAMING_SYSTEM = 158;
     */
    NAMING_SYSTEM = 158,
    /**
     * @generated from protobuf enum value: NUTRITION_ORDER = 159;
     */
    NUTRITION_ORDER = 159,
    /**
     * @generated from protobuf enum value: OBSERVATION = 160;
     */
    OBSERVATION = 160,
    /**
     * @generated from protobuf enum value: OBSERVATION_DEFINITION = 161;
     */
    OBSERVATION_DEFINITION = 161,
    /**
     * @generated from protobuf enum value: OPERATION_DEFINITION = 162;
     */
    OPERATION_DEFINITION = 162,
    /**
     * @generated from protobuf enum value: OPERATION_OUTCOME = 163;
     */
    OPERATION_OUTCOME = 163,
    /**
     * @generated from protobuf enum value: ORGANIZATION = 164;
     */
    ORGANIZATION = 164,
    /**
     * @generated from protobuf enum value: ORGANIZATION_AFFILIATION = 165;
     */
    ORGANIZATION_AFFILIATION = 165,
    /**
     * @generated from protobuf enum value: PARAMETERS = 166;
     */
    PARAMETERS = 166,
    /**
     * @generated from protobuf enum value: PATIENT = 167;
     */
    PATIENT = 167,
    /**
     * @generated from protobuf enum value: PAYMENT_NOTICE = 168;
     */
    PAYMENT_NOTICE = 168,
    /**
     * @generated from protobuf enum value: PAYMENT_RECONCILIATION = 169;
     */
    PAYMENT_RECONCILIATION = 169,
    /**
     * @generated from protobuf enum value: PERSON = 170;
     */
    PERSON = 170,
    /**
     * @generated from protobuf enum value: PLAN_DEFINITION = 171;
     */
    PLAN_DEFINITION = 171,
    /**
     * @generated from protobuf enum value: PRACTITIONER = 172;
     */
    PRACTITIONER = 172,
    /**
     * @generated from protobuf enum value: PRACTITIONER_ROLE = 173;
     */
    PRACTITIONER_ROLE = 173,
    /**
     * @generated from protobuf enum value: PROCEDURE = 174;
     */
    PROCEDURE = 174,
    /**
     * @generated from protobuf enum value: PROVENANCE = 175;
     */
    PROVENANCE = 175,
    /**
     * @generated from protobuf enum value: QUESTIONNAIRE = 176;
     */
    QUESTIONNAIRE = 176,
    /**
     * @generated from protobuf enum value: QUESTIONNAIRE_RESPONSE = 177;
     */
    QUESTIONNAIRE_RESPONSE = 177,
    /**
     * @generated from protobuf enum value: RELATED_PERSON = 178;
     */
    RELATED_PERSON = 178,
    /**
     * @generated from protobuf enum value: REQUEST_GROUP = 179;
     */
    REQUEST_GROUP = 179,
    /**
     * @generated from protobuf enum value: RESEARCH_DEFINITION = 180;
     */
    RESEARCH_DEFINITION = 180,
    /**
     * @generated from protobuf enum value: RESEARCH_ELEMENT_DEFINITION = 181;
     */
    RESEARCH_ELEMENT_DEFINITION = 181,
    /**
     * @generated from protobuf enum value: RESEARCH_STUDY = 182;
     */
    RESEARCH_STUDY = 182,
    /**
     * @generated from protobuf enum value: RESEARCH_SUBJECT = 183;
     */
    RESEARCH_SUBJECT = 183,
    /**
     * @generated from protobuf enum value: RESOURCE = 184;
     */
    RESOURCE = 184,
    /**
     * @generated from protobuf enum value: RISK_ASSESSMENT = 185;
     */
    RISK_ASSESSMENT = 185,
    /**
     * @generated from protobuf enum value: RISK_EVIDENCE_SYNTHESIS = 186;
     */
    RISK_EVIDENCE_SYNTHESIS = 186,
    /**
     * @generated from protobuf enum value: SCHEDULE = 187;
     */
    SCHEDULE = 187,
    /**
     * @generated from protobuf enum value: SEARCH_PARAMETER = 188;
     */
    SEARCH_PARAMETER = 188,
    /**
     * @generated from protobuf enum value: SERVICE_REQUEST = 189;
     */
    SERVICE_REQUEST = 189,
    /**
     * @generated from protobuf enum value: SLOT = 190;
     */
    SLOT = 190,
    /**
     * @generated from protobuf enum value: SPECIMEN = 191;
     */
    SPECIMEN = 191,
    /**
     * @generated from protobuf enum value: SPECIMEN_DEFINITION = 192;
     */
    SPECIMEN_DEFINITION = 192,
    /**
     * @generated from protobuf enum value: STRUCTURE_DEFINITION = 193;
     */
    STRUCTURE_DEFINITION = 193,
    /**
     * @generated from protobuf enum value: STRUCTURE_MAP = 194;
     */
    STRUCTURE_MAP = 194,
    /**
     * @generated from protobuf enum value: SUBSCRIPTION = 195;
     */
    SUBSCRIPTION = 195,
    /**
     * @generated from protobuf enum value: SUBSTANCE = 196;
     */
    SUBSTANCE = 196,
    /**
     * @generated from protobuf enum value: SUBSTANCE_NUCLEIC_ACID = 197;
     */
    SUBSTANCE_NUCLEIC_ACID = 197,
    /**
     * @generated from protobuf enum value: SUBSTANCE_POLYMER = 198;
     */
    SUBSTANCE_POLYMER = 198,
    /**
     * @generated from protobuf enum value: SUBSTANCE_PROTEIN = 199;
     */
    SUBSTANCE_PROTEIN = 199,
    /**
     * @generated from protobuf enum value: SUBSTANCE_REFERENCE_INFORMATION = 200;
     */
    SUBSTANCE_REFERENCE_INFORMATION = 200,
    /**
     * @generated from protobuf enum value: SUBSTANCE_SOURCE_MATERIAL = 201;
     */
    SUBSTANCE_SOURCE_MATERIAL = 201,
    /**
     * @generated from protobuf enum value: SUBSTANCE_SPECIFICATION = 202;
     */
    SUBSTANCE_SPECIFICATION = 202,
    /**
     * @generated from protobuf enum value: SUPPLY_DELIVERY = 203;
     */
    SUPPLY_DELIVERY = 203,
    /**
     * @generated from protobuf enum value: SUPPLY_REQUEST = 204;
     */
    SUPPLY_REQUEST = 204,
    /**
     * @generated from protobuf enum value: TASK = 205;
     */
    TASK = 205,
    /**
     * @generated from protobuf enum value: TERMINOLOGY_CAPABILITIES = 206;
     */
    TERMINOLOGY_CAPABILITIES = 206,
    /**
     * @generated from protobuf enum value: TEST_REPORT = 207;
     */
    TEST_REPORT = 207,
    /**
     * @generated from protobuf enum value: TEST_SCRIPT = 208;
     */
    TEST_SCRIPT = 208,
    /**
     * @generated from protobuf enum value: VALUE_SET = 209;
     */
    VALUE_SET = 209,
    /**
     * @generated from protobuf enum value: VERIFICATION_RESULT = 210;
     */
    VERIFICATION_RESULT = 210,
    /**
     * @generated from protobuf enum value: VISION_PRESCRIPTION = 211;
     */
    VISION_PRESCRIPTION = 211,
    /**
     * @generated from protobuf enum value: TYPE = 212;
     */
    TYPE = 212,
    /**
     * @generated from protobuf enum value: ANY = 213;
     */
    ANY = 213
}
/**
 * A list of all the concrete types defined in this version of the FHIR
 * specification - Data Types and Resource Types. See
 * http://hl7.org/fhir/ValueSet/defined-types
 *
 * @generated from protobuf message google.fhir.r4.core.FHIRDefinedTypeValueSet
 */
export interface FHIRDefinedTypeValueSet {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.FHIRDefinedTypeValueSet.Value
 */
export enum FHIRDefinedTypeValueSet_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ADDRESS = 1;
     */
    ADDRESS = 1,
    /**
     * @generated from protobuf enum value: AGE = 2;
     */
    AGE = 2,
    /**
     * @generated from protobuf enum value: ANNOTATION = 3;
     */
    ANNOTATION = 3,
    /**
     * @generated from protobuf enum value: ATTACHMENT = 4;
     */
    ATTACHMENT = 4,
    /**
     * @generated from protobuf enum value: BACKBONE_ELEMENT = 5;
     */
    BACKBONE_ELEMENT = 5,
    /**
     * @generated from protobuf enum value: CODEABLE_CONCEPT = 6;
     */
    CODEABLE_CONCEPT = 6,
    /**
     * @generated from protobuf enum value: CODING = 7;
     */
    CODING = 7,
    /**
     * @generated from protobuf enum value: CONTACT_DETAIL = 8;
     */
    CONTACT_DETAIL = 8,
    /**
     * @generated from protobuf enum value: CONTACT_POINT = 9;
     */
    CONTACT_POINT = 9,
    /**
     * @generated from protobuf enum value: CONTRIBUTOR = 10;
     */
    CONTRIBUTOR = 10,
    /**
     * @generated from protobuf enum value: COUNT = 11;
     */
    COUNT = 11,
    /**
     * @generated from protobuf enum value: DATA_REQUIREMENT = 12;
     */
    DATA_REQUIREMENT = 12,
    /**
     * @generated from protobuf enum value: DISTANCE = 13;
     */
    DISTANCE = 13,
    /**
     * @generated from protobuf enum value: DOSAGE = 14;
     */
    DOSAGE = 14,
    /**
     * @generated from protobuf enum value: DURATION = 15;
     */
    DURATION = 15,
    /**
     * @generated from protobuf enum value: ELEMENT = 16;
     */
    ELEMENT = 16,
    /**
     * @generated from protobuf enum value: ELEMENT_DEFINITION = 17;
     */
    ELEMENT_DEFINITION = 17,
    /**
     * @generated from protobuf enum value: EXPRESSION = 18;
     */
    EXPRESSION = 18,
    /**
     * @generated from protobuf enum value: EXTENSION = 19;
     */
    EXTENSION = 19,
    /**
     * @generated from protobuf enum value: HUMAN_NAME = 20;
     */
    HUMAN_NAME = 20,
    /**
     * @generated from protobuf enum value: IDENTIFIER = 21;
     */
    IDENTIFIER = 21,
    /**
     * @generated from protobuf enum value: MARKETING_STATUS = 22;
     */
    MARKETING_STATUS = 22,
    /**
     * @generated from protobuf enum value: META = 23;
     */
    META = 23,
    /**
     * @generated from protobuf enum value: MONEY = 24;
     */
    MONEY = 24,
    /**
     * @generated from protobuf enum value: MONEY_QUANTITY = 25;
     */
    MONEY_QUANTITY = 25,
    /**
     * @generated from protobuf enum value: NARRATIVE = 26;
     */
    NARRATIVE = 26,
    /**
     * @generated from protobuf enum value: PARAMETER_DEFINITION = 27;
     */
    PARAMETER_DEFINITION = 27,
    /**
     * @generated from protobuf enum value: PERIOD = 28;
     */
    PERIOD = 28,
    /**
     * @generated from protobuf enum value: POPULATION = 29;
     */
    POPULATION = 29,
    /**
     * @generated from protobuf enum value: PROD_CHARACTERISTIC = 30;
     */
    PROD_CHARACTERISTIC = 30,
    /**
     * @generated from protobuf enum value: PRODUCT_SHELF_LIFE = 31;
     */
    PRODUCT_SHELF_LIFE = 31,
    /**
     * @generated from protobuf enum value: QUANTITY = 32;
     */
    QUANTITY = 32,
    /**
     * @generated from protobuf enum value: RANGE = 33;
     */
    RANGE = 33,
    /**
     * @generated from protobuf enum value: RATIO = 34;
     */
    RATIO = 34,
    /**
     * @generated from protobuf enum value: REFERENCE = 35;
     */
    REFERENCE = 35,
    /**
     * @generated from protobuf enum value: RELATED_ARTIFACT = 36;
     */
    RELATED_ARTIFACT = 36,
    /**
     * @generated from protobuf enum value: SAMPLED_DATA = 37;
     */
    SAMPLED_DATA = 37,
    /**
     * @generated from protobuf enum value: SIGNATURE = 38;
     */
    SIGNATURE = 38,
    /**
     * @generated from protobuf enum value: SIMPLE_QUANTITY = 39;
     */
    SIMPLE_QUANTITY = 39,
    /**
     * @generated from protobuf enum value: SUBSTANCE_AMOUNT = 40;
     */
    SUBSTANCE_AMOUNT = 40,
    /**
     * @generated from protobuf enum value: TIMING = 41;
     */
    TIMING = 41,
    /**
     * @generated from protobuf enum value: TRIGGER_DEFINITION = 42;
     */
    TRIGGER_DEFINITION = 42,
    /**
     * @generated from protobuf enum value: USAGE_CONTEXT = 43;
     */
    USAGE_CONTEXT = 43,
    /**
     * @generated from protobuf enum value: BASE64_BINARY = 44;
     */
    BASE64_BINARY = 44,
    /**
     * @generated from protobuf enum value: BOOLEAN = 45;
     */
    BOOLEAN = 45,
    /**
     * @generated from protobuf enum value: CANONICAL = 46;
     */
    CANONICAL = 46,
    /**
     * @generated from protobuf enum value: CODE = 47;
     */
    CODE = 47,
    /**
     * @generated from protobuf enum value: DATE = 48;
     */
    DATE = 48,
    /**
     * @generated from protobuf enum value: DATE_TIME = 49;
     */
    DATE_TIME = 49,
    /**
     * @generated from protobuf enum value: DECIMAL = 50;
     */
    DECIMAL = 50,
    /**
     * @generated from protobuf enum value: ID = 51;
     */
    ID = 51,
    /**
     * @generated from protobuf enum value: INSTANT = 52;
     */
    INSTANT = 52,
    /**
     * @generated from protobuf enum value: INTEGER = 53;
     */
    INTEGER = 53,
    /**
     * @generated from protobuf enum value: MARKDOWN = 54;
     */
    MARKDOWN = 54,
    /**
     * @generated from protobuf enum value: OID = 55;
     */
    OID = 55,
    /**
     * @generated from protobuf enum value: POSITIVE_INT = 56;
     */
    POSITIVE_INT = 56,
    /**
     * @generated from protobuf enum value: STRING = 57;
     */
    STRING = 57,
    /**
     * @generated from protobuf enum value: TIME = 58;
     */
    TIME = 58,
    /**
     * @generated from protobuf enum value: UNSIGNED_INT = 59;
     */
    UNSIGNED_INT = 59,
    /**
     * @generated from protobuf enum value: URI = 60;
     */
    URI = 60,
    /**
     * @generated from protobuf enum value: URL = 61;
     */
    URL = 61,
    /**
     * @generated from protobuf enum value: UUID = 62;
     */
    UUID = 62,
    /**
     * @generated from protobuf enum value: XHTML = 63;
     */
    XHTML = 63,
    /**
     * @generated from protobuf enum value: ACCOUNT = 64;
     */
    ACCOUNT = 64,
    /**
     * @generated from protobuf enum value: ACTIVITY_DEFINITION = 65;
     */
    ACTIVITY_DEFINITION = 65,
    /**
     * @generated from protobuf enum value: ADVERSE_EVENT = 66;
     */
    ADVERSE_EVENT = 66,
    /**
     * @generated from protobuf enum value: ALLERGY_INTOLERANCE = 67;
     */
    ALLERGY_INTOLERANCE = 67,
    /**
     * @generated from protobuf enum value: APPOINTMENT = 68;
     */
    APPOINTMENT = 68,
    /**
     * @generated from protobuf enum value: APPOINTMENT_RESPONSE = 69;
     */
    APPOINTMENT_RESPONSE = 69,
    /**
     * @generated from protobuf enum value: AUDIT_EVENT = 70;
     */
    AUDIT_EVENT = 70,
    /**
     * @generated from protobuf enum value: BASIC = 71;
     */
    BASIC = 71,
    /**
     * @generated from protobuf enum value: BINARY = 72;
     */
    BINARY = 72,
    /**
     * @generated from protobuf enum value: BIOLOGICALLY_DERIVED_PRODUCT = 73;
     */
    BIOLOGICALLY_DERIVED_PRODUCT = 73,
    /**
     * @generated from protobuf enum value: BODY_STRUCTURE = 74;
     */
    BODY_STRUCTURE = 74,
    /**
     * @generated from protobuf enum value: BUNDLE = 75;
     */
    BUNDLE = 75,
    /**
     * @generated from protobuf enum value: CAPABILITY_STATEMENT = 76;
     */
    CAPABILITY_STATEMENT = 76,
    /**
     * @generated from protobuf enum value: CARE_PLAN = 77;
     */
    CARE_PLAN = 77,
    /**
     * @generated from protobuf enum value: CARE_TEAM = 78;
     */
    CARE_TEAM = 78,
    /**
     * @generated from protobuf enum value: CATALOG_ENTRY = 79;
     */
    CATALOG_ENTRY = 79,
    /**
     * @generated from protobuf enum value: CHARGE_ITEM = 80;
     */
    CHARGE_ITEM = 80,
    /**
     * @generated from protobuf enum value: CHARGE_ITEM_DEFINITION = 81;
     */
    CHARGE_ITEM_DEFINITION = 81,
    /**
     * @generated from protobuf enum value: CLAIM = 82;
     */
    CLAIM = 82,
    /**
     * @generated from protobuf enum value: CLAIM_RESPONSE = 83;
     */
    CLAIM_RESPONSE = 83,
    /**
     * @generated from protobuf enum value: CLINICAL_IMPRESSION = 84;
     */
    CLINICAL_IMPRESSION = 84,
    /**
     * @generated from protobuf enum value: CODE_SYSTEM = 85;
     */
    CODE_SYSTEM = 85,
    /**
     * @generated from protobuf enum value: COMMUNICATION = 86;
     */
    COMMUNICATION = 86,
    /**
     * @generated from protobuf enum value: COMMUNICATION_REQUEST = 87;
     */
    COMMUNICATION_REQUEST = 87,
    /**
     * @generated from protobuf enum value: COMPARTMENT_DEFINITION = 88;
     */
    COMPARTMENT_DEFINITION = 88,
    /**
     * @generated from protobuf enum value: COMPOSITION = 89;
     */
    COMPOSITION = 89,
    /**
     * @generated from protobuf enum value: CONCEPT_MAP = 90;
     */
    CONCEPT_MAP = 90,
    /**
     * @generated from protobuf enum value: CONDITION = 91;
     */
    CONDITION = 91,
    /**
     * @generated from protobuf enum value: CONSENT = 92;
     */
    CONSENT = 92,
    /**
     * @generated from protobuf enum value: CONTRACT = 93;
     */
    CONTRACT = 93,
    /**
     * @generated from protobuf enum value: COVERAGE = 94;
     */
    COVERAGE = 94,
    /**
     * @generated from protobuf enum value: COVERAGE_ELIGIBILITY_REQUEST = 95;
     */
    COVERAGE_ELIGIBILITY_REQUEST = 95,
    /**
     * @generated from protobuf enum value: COVERAGE_ELIGIBILITY_RESPONSE = 96;
     */
    COVERAGE_ELIGIBILITY_RESPONSE = 96,
    /**
     * @generated from protobuf enum value: DETECTED_ISSUE = 97;
     */
    DETECTED_ISSUE = 97,
    /**
     * @generated from protobuf enum value: DEVICE = 98;
     */
    DEVICE = 98,
    /**
     * @generated from protobuf enum value: DEVICE_DEFINITION = 99;
     */
    DEVICE_DEFINITION = 99,
    /**
     * @generated from protobuf enum value: DEVICE_METRIC = 100;
     */
    DEVICE_METRIC = 100,
    /**
     * @generated from protobuf enum value: DEVICE_REQUEST = 101;
     */
    DEVICE_REQUEST = 101,
    /**
     * @generated from protobuf enum value: DEVICE_USE_STATEMENT = 102;
     */
    DEVICE_USE_STATEMENT = 102,
    /**
     * @generated from protobuf enum value: DIAGNOSTIC_REPORT = 103;
     */
    DIAGNOSTIC_REPORT = 103,
    /**
     * @generated from protobuf enum value: DOCUMENT_MANIFEST = 104;
     */
    DOCUMENT_MANIFEST = 104,
    /**
     * @generated from protobuf enum value: DOCUMENT_REFERENCE = 105;
     */
    DOCUMENT_REFERENCE = 105,
    /**
     * @generated from protobuf enum value: DOMAIN_RESOURCE = 106;
     */
    DOMAIN_RESOURCE = 106,
    /**
     * @generated from protobuf enum value: EFFECT_EVIDENCE_SYNTHESIS = 107;
     */
    EFFECT_EVIDENCE_SYNTHESIS = 107,
    /**
     * @generated from protobuf enum value: ENCOUNTER = 108;
     */
    ENCOUNTER = 108,
    /**
     * @generated from protobuf enum value: ENDPOINT = 109;
     */
    ENDPOINT = 109,
    /**
     * @generated from protobuf enum value: ENROLLMENT_REQUEST = 110;
     */
    ENROLLMENT_REQUEST = 110,
    /**
     * @generated from protobuf enum value: ENROLLMENT_RESPONSE = 111;
     */
    ENROLLMENT_RESPONSE = 111,
    /**
     * @generated from protobuf enum value: EPISODE_OF_CARE = 112;
     */
    EPISODE_OF_CARE = 112,
    /**
     * @generated from protobuf enum value: EVENT_DEFINITION = 113;
     */
    EVENT_DEFINITION = 113,
    /**
     * @generated from protobuf enum value: EVIDENCE = 114;
     */
    EVIDENCE = 114,
    /**
     * @generated from protobuf enum value: EVIDENCE_VARIABLE = 115;
     */
    EVIDENCE_VARIABLE = 115,
    /**
     * @generated from protobuf enum value: EXAMPLE_SCENARIO = 116;
     */
    EXAMPLE_SCENARIO = 116,
    /**
     * @generated from protobuf enum value: EXPLANATION_OF_BENEFIT = 117;
     */
    EXPLANATION_OF_BENEFIT = 117,
    /**
     * @generated from protobuf enum value: FAMILY_MEMBER_HISTORY = 118;
     */
    FAMILY_MEMBER_HISTORY = 118,
    /**
     * @generated from protobuf enum value: FLAG = 119;
     */
    FLAG = 119,
    /**
     * @generated from protobuf enum value: GOAL = 120;
     */
    GOAL = 120,
    /**
     * @generated from protobuf enum value: GRAPH_DEFINITION = 121;
     */
    GRAPH_DEFINITION = 121,
    /**
     * @generated from protobuf enum value: GROUP = 122;
     */
    GROUP = 122,
    /**
     * @generated from protobuf enum value: GUIDANCE_RESPONSE = 123;
     */
    GUIDANCE_RESPONSE = 123,
    /**
     * @generated from protobuf enum value: HEALTHCARE_SERVICE = 124;
     */
    HEALTHCARE_SERVICE = 124,
    /**
     * @generated from protobuf enum value: IMAGING_STUDY = 125;
     */
    IMAGING_STUDY = 125,
    /**
     * @generated from protobuf enum value: IMMUNIZATION = 126;
     */
    IMMUNIZATION = 126,
    /**
     * @generated from protobuf enum value: IMMUNIZATION_EVALUATION = 127;
     */
    IMMUNIZATION_EVALUATION = 127,
    /**
     * @generated from protobuf enum value: IMMUNIZATION_RECOMMENDATION = 128;
     */
    IMMUNIZATION_RECOMMENDATION = 128,
    /**
     * @generated from protobuf enum value: IMPLEMENTATION_GUIDE = 129;
     */
    IMPLEMENTATION_GUIDE = 129,
    /**
     * @generated from protobuf enum value: INSURANCE_PLAN = 130;
     */
    INSURANCE_PLAN = 130,
    /**
     * @generated from protobuf enum value: INVOICE = 131;
     */
    INVOICE = 131,
    /**
     * @generated from protobuf enum value: LIBRARY = 132;
     */
    LIBRARY = 132,
    /**
     * @generated from protobuf enum value: LINKAGE = 133;
     */
    LINKAGE = 133,
    /**
     * @generated from protobuf enum value: LIST = 134;
     */
    LIST = 134,
    /**
     * @generated from protobuf enum value: LOCATION = 135;
     */
    LOCATION = 135,
    /**
     * @generated from protobuf enum value: MEASURE = 136;
     */
    MEASURE = 136,
    /**
     * @generated from protobuf enum value: MEASURE_REPORT = 137;
     */
    MEASURE_REPORT = 137,
    /**
     * @generated from protobuf enum value: MEDIA = 138;
     */
    MEDIA = 138,
    /**
     * @generated from protobuf enum value: MEDICATION = 139;
     */
    MEDICATION = 139,
    /**
     * @generated from protobuf enum value: MEDICATION_ADMINISTRATION = 140;
     */
    MEDICATION_ADMINISTRATION = 140,
    /**
     * @generated from protobuf enum value: MEDICATION_DISPENSE = 141;
     */
    MEDICATION_DISPENSE = 141,
    /**
     * @generated from protobuf enum value: MEDICATION_KNOWLEDGE = 142;
     */
    MEDICATION_KNOWLEDGE = 142,
    /**
     * @generated from protobuf enum value: MEDICATION_REQUEST = 143;
     */
    MEDICATION_REQUEST = 143,
    /**
     * @generated from protobuf enum value: MEDICATION_STATEMENT = 144;
     */
    MEDICATION_STATEMENT = 144,
    /**
     * @generated from protobuf enum value: MEDICINAL_PRODUCT = 145;
     */
    MEDICINAL_PRODUCT = 145,
    /**
     * @generated from protobuf enum value: MEDICINAL_PRODUCT_AUTHORIZATION = 146;
     */
    MEDICINAL_PRODUCT_AUTHORIZATION = 146,
    /**
     * @generated from protobuf enum value: MEDICINAL_PRODUCT_CONTRAINDICATION = 147;
     */
    MEDICINAL_PRODUCT_CONTRAINDICATION = 147,
    /**
     * @generated from protobuf enum value: MEDICINAL_PRODUCT_INDICATION = 148;
     */
    MEDICINAL_PRODUCT_INDICATION = 148,
    /**
     * @generated from protobuf enum value: MEDICINAL_PRODUCT_INGREDIENT = 149;
     */
    MEDICINAL_PRODUCT_INGREDIENT = 149,
    /**
     * @generated from protobuf enum value: MEDICINAL_PRODUCT_INTERACTION = 150;
     */
    MEDICINAL_PRODUCT_INTERACTION = 150,
    /**
     * @generated from protobuf enum value: MEDICINAL_PRODUCT_MANUFACTURED = 151;
     */
    MEDICINAL_PRODUCT_MANUFACTURED = 151,
    /**
     * @generated from protobuf enum value: MEDICINAL_PRODUCT_PACKAGED = 152;
     */
    MEDICINAL_PRODUCT_PACKAGED = 152,
    /**
     * @generated from protobuf enum value: MEDICINAL_PRODUCT_PHARMACEUTICAL = 153;
     */
    MEDICINAL_PRODUCT_PHARMACEUTICAL = 153,
    /**
     * @generated from protobuf enum value: MEDICINAL_PRODUCT_UNDESIRABLE_EFFECT = 154;
     */
    MEDICINAL_PRODUCT_UNDESIRABLE_EFFECT = 154,
    /**
     * @generated from protobuf enum value: MESSAGE_DEFINITION = 155;
     */
    MESSAGE_DEFINITION = 155,
    /**
     * @generated from protobuf enum value: MESSAGE_HEADER = 156;
     */
    MESSAGE_HEADER = 156,
    /**
     * @generated from protobuf enum value: MOLECULAR_SEQUENCE = 157;
     */
    MOLECULAR_SEQUENCE = 157,
    /**
     * @generated from protobuf enum value: NAMING_SYSTEM = 158;
     */
    NAMING_SYSTEM = 158,
    /**
     * @generated from protobuf enum value: NUTRITION_ORDER = 159;
     */
    NUTRITION_ORDER = 159,
    /**
     * @generated from protobuf enum value: OBSERVATION = 160;
     */
    OBSERVATION = 160,
    /**
     * @generated from protobuf enum value: OBSERVATION_DEFINITION = 161;
     */
    OBSERVATION_DEFINITION = 161,
    /**
     * @generated from protobuf enum value: OPERATION_DEFINITION = 162;
     */
    OPERATION_DEFINITION = 162,
    /**
     * @generated from protobuf enum value: OPERATION_OUTCOME = 163;
     */
    OPERATION_OUTCOME = 163,
    /**
     * @generated from protobuf enum value: ORGANIZATION = 164;
     */
    ORGANIZATION = 164,
    /**
     * @generated from protobuf enum value: ORGANIZATION_AFFILIATION = 165;
     */
    ORGANIZATION_AFFILIATION = 165,
    /**
     * @generated from protobuf enum value: PARAMETERS = 166;
     */
    PARAMETERS = 166,
    /**
     * @generated from protobuf enum value: PATIENT = 167;
     */
    PATIENT = 167,
    /**
     * @generated from protobuf enum value: PAYMENT_NOTICE = 168;
     */
    PAYMENT_NOTICE = 168,
    /**
     * @generated from protobuf enum value: PAYMENT_RECONCILIATION = 169;
     */
    PAYMENT_RECONCILIATION = 169,
    /**
     * @generated from protobuf enum value: PERSON = 170;
     */
    PERSON = 170,
    /**
     * @generated from protobuf enum value: PLAN_DEFINITION = 171;
     */
    PLAN_DEFINITION = 171,
    /**
     * @generated from protobuf enum value: PRACTITIONER = 172;
     */
    PRACTITIONER = 172,
    /**
     * @generated from protobuf enum value: PRACTITIONER_ROLE = 173;
     */
    PRACTITIONER_ROLE = 173,
    /**
     * @generated from protobuf enum value: PROCEDURE = 174;
     */
    PROCEDURE = 174,
    /**
     * @generated from protobuf enum value: PROVENANCE = 175;
     */
    PROVENANCE = 175,
    /**
     * @generated from protobuf enum value: QUESTIONNAIRE = 176;
     */
    QUESTIONNAIRE = 176,
    /**
     * @generated from protobuf enum value: QUESTIONNAIRE_RESPONSE = 177;
     */
    QUESTIONNAIRE_RESPONSE = 177,
    /**
     * @generated from protobuf enum value: RELATED_PERSON = 178;
     */
    RELATED_PERSON = 178,
    /**
     * @generated from protobuf enum value: REQUEST_GROUP = 179;
     */
    REQUEST_GROUP = 179,
    /**
     * @generated from protobuf enum value: RESEARCH_DEFINITION = 180;
     */
    RESEARCH_DEFINITION = 180,
    /**
     * @generated from protobuf enum value: RESEARCH_ELEMENT_DEFINITION = 181;
     */
    RESEARCH_ELEMENT_DEFINITION = 181,
    /**
     * @generated from protobuf enum value: RESEARCH_STUDY = 182;
     */
    RESEARCH_STUDY = 182,
    /**
     * @generated from protobuf enum value: RESEARCH_SUBJECT = 183;
     */
    RESEARCH_SUBJECT = 183,
    /**
     * @generated from protobuf enum value: RESOURCE = 184;
     */
    RESOURCE = 184,
    /**
     * @generated from protobuf enum value: RISK_ASSESSMENT = 185;
     */
    RISK_ASSESSMENT = 185,
    /**
     * @generated from protobuf enum value: RISK_EVIDENCE_SYNTHESIS = 186;
     */
    RISK_EVIDENCE_SYNTHESIS = 186,
    /**
     * @generated from protobuf enum value: SCHEDULE = 187;
     */
    SCHEDULE = 187,
    /**
     * @generated from protobuf enum value: SEARCH_PARAMETER = 188;
     */
    SEARCH_PARAMETER = 188,
    /**
     * @generated from protobuf enum value: SERVICE_REQUEST = 189;
     */
    SERVICE_REQUEST = 189,
    /**
     * @generated from protobuf enum value: SLOT = 190;
     */
    SLOT = 190,
    /**
     * @generated from protobuf enum value: SPECIMEN = 191;
     */
    SPECIMEN = 191,
    /**
     * @generated from protobuf enum value: SPECIMEN_DEFINITION = 192;
     */
    SPECIMEN_DEFINITION = 192,
    /**
     * @generated from protobuf enum value: STRUCTURE_DEFINITION = 193;
     */
    STRUCTURE_DEFINITION = 193,
    /**
     * @generated from protobuf enum value: STRUCTURE_MAP = 194;
     */
    STRUCTURE_MAP = 194,
    /**
     * @generated from protobuf enum value: SUBSCRIPTION = 195;
     */
    SUBSCRIPTION = 195,
    /**
     * @generated from protobuf enum value: SUBSTANCE = 196;
     */
    SUBSTANCE = 196,
    /**
     * @generated from protobuf enum value: SUBSTANCE_NUCLEIC_ACID = 197;
     */
    SUBSTANCE_NUCLEIC_ACID = 197,
    /**
     * @generated from protobuf enum value: SUBSTANCE_POLYMER = 198;
     */
    SUBSTANCE_POLYMER = 198,
    /**
     * @generated from protobuf enum value: SUBSTANCE_PROTEIN = 199;
     */
    SUBSTANCE_PROTEIN = 199,
    /**
     * @generated from protobuf enum value: SUBSTANCE_REFERENCE_INFORMATION = 200;
     */
    SUBSTANCE_REFERENCE_INFORMATION = 200,
    /**
     * @generated from protobuf enum value: SUBSTANCE_SOURCE_MATERIAL = 201;
     */
    SUBSTANCE_SOURCE_MATERIAL = 201,
    /**
     * @generated from protobuf enum value: SUBSTANCE_SPECIFICATION = 202;
     */
    SUBSTANCE_SPECIFICATION = 202,
    /**
     * @generated from protobuf enum value: SUPPLY_DELIVERY = 203;
     */
    SUPPLY_DELIVERY = 203,
    /**
     * @generated from protobuf enum value: SUPPLY_REQUEST = 204;
     */
    SUPPLY_REQUEST = 204,
    /**
     * @generated from protobuf enum value: TASK = 205;
     */
    TASK = 205,
    /**
     * @generated from protobuf enum value: TERMINOLOGY_CAPABILITIES = 206;
     */
    TERMINOLOGY_CAPABILITIES = 206,
    /**
     * @generated from protobuf enum value: TEST_REPORT = 207;
     */
    TEST_REPORT = 207,
    /**
     * @generated from protobuf enum value: TEST_SCRIPT = 208;
     */
    TEST_SCRIPT = 208,
    /**
     * @generated from protobuf enum value: VALUE_SET = 209;
     */
    VALUE_SET = 209,
    /**
     * @generated from protobuf enum value: VERIFICATION_RESULT = 210;
     */
    VERIFICATION_RESULT = 210,
    /**
     * @generated from protobuf enum value: VISION_PRESCRIPTION = 211;
     */
    VISION_PRESCRIPTION = 211
}
/**
 * A code that represents the preferred display order of the components of a
 * human name. See http://hl7.org/fhir/ValueSet/name-assembly-order
 *
 * @generated from protobuf message google.fhir.r4.core.HumanNameAssemblyOrderValueSet
 */
export interface HumanNameAssemblyOrderValueSet {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.HumanNameAssemblyOrderValueSet.Value
 */
export enum HumanNameAssemblyOrderValueSet_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: NL1 = 1;
     */
    NL1 = 1,
    /**
     * @generated from protobuf enum value: NL2 = 2;
     */
    NL2 = 2,
    /**
     * @generated from protobuf enum value: NL3 = 3;
     */
    NL3 = 3,
    /**
     * @generated from protobuf enum value: NL4 = 4;
     */
    NL4 = 4,
    /**
     * @generated from protobuf enum value: F = 5;
     */
    F = 5,
    /**
     * @generated from protobuf enum value: G = 6;
     */
    G = 6,
    /**
     * @generated from protobuf enum value: UNK = 7;
     */
    UNK = 7
}
/**
 * The value set to instantiate this attribute should be drawn from a
 * terminologically robust code system that consists of or contains concepts to
 * support describing the current status of the evaluation for vaccine
 * administration event. See
 * http://hl7.org/fhir/ValueSet/immunization-evaluation-status
 *
 * @generated from protobuf message google.fhir.r4.core.ImmunizationEvaluationStatusCodesValueSet
 */
export interface ImmunizationEvaluationStatusCodesValueSet {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ImmunizationEvaluationStatusCodesValueSet.Value
 */
export enum ImmunizationEvaluationStatusCodesValueSet_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: COMPLETED = 1;
     */
    COMPLETED = 1,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 2;
     */
    ENTERED_IN_ERROR = 2
}
/**
 * The value set to instantiate this attribute should be drawn from a
 * terminologically robust code system that consists of or contains concepts to
 * support describing the current status of the administered dose of vaccine.
 * See http://hl7.org/fhir/ValueSet/immunization-status
 *
 * @generated from protobuf message google.fhir.r4.core.ImmunizationStatusCodesValueSet
 */
export interface ImmunizationStatusCodesValueSet {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ImmunizationStatusCodesValueSet.Value
 */
export enum ImmunizationStatusCodesValueSet_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: COMPLETED = 1;
     */
    COMPLETED = 1,
    /**
     * @generated from protobuf enum value: ENTERED_IN_ERROR = 2;
     */
    ENTERED_IN_ERROR = 2,
    /**
     * @generated from protobuf enum value: NOT_DONE = 3;
     */
    NOT_DONE = 3
}
/**
 * LDL Cholesterol codes - measured or calculated
 * See http://hl7.org/fhir/ValueSet/ldlcholesterol-codes
 *
 * @generated from protobuf message google.fhir.r4.core.LDLCodesValueSet
 */
export interface LDLCodesValueSet {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.LDLCodesValueSet.Value
 */
export enum LDLCodesValueSet_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: LDL_CHOLESTEROL_ASSAY = 1;
     */
    LDL_CHOLESTEROL_ASSAY = 1,
    /**
     * @generated from protobuf enum value: CHOLESTEROL_IN_LDL_MASS_VOLUME_IN_SERUM_OR_PLASMA_BY_CALCULATION = 2;
     */
    CHOLESTEROL_IN_LDL_MASS_VOLUME_IN_SERUM_OR_PLASMA_BY_CALCULATION = 2
}
/**
 * A set of codes for each different representation of a name.
 * See http://hl7.org/fhir/ValueSet/name-v3-representation
 *
 * @generated from protobuf message google.fhir.r4.core.NameRepresentationUseValueSet
 */
export interface NameRepresentationUseValueSet {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.NameRepresentationUseValueSet.Value
 */
export enum NameRepresentationUseValueSet_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ABC = 1;
     */
    ABC = 1,
    /**
     * @generated from protobuf enum value: IDE = 2;
     */
    IDE = 2,
    /**
     * @generated from protobuf enum value: SYL = 3;
     */
    SYL = 3
}
/**
 * The value set includes the v3 RoleCode PRN (parent), TWIN (twin) and all of
 * their specializations.  It covers the relationships needed to establish
 * genetic pedigree relationships between family members. See
 * http://hl7.org/fhir/ValueSet/parent-relationship-codes
 *
 * @generated from protobuf message google.fhir.r4.core.ParentRelationshipCodesValueSet
 */
export interface ParentRelationshipCodesValueSet {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ParentRelationshipCodesValueSet.Value
 */
export enum ParentRelationshipCodesValueSet_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: PRN = 1;
     */
    PRN = 1,
    /**
     * @generated from protobuf enum value: ADOPTP = 2;
     */
    ADOPTP = 2,
    /**
     * @generated from protobuf enum value: ADOPTF = 3;
     */
    ADOPTF = 3,
    /**
     * @generated from protobuf enum value: ADOPTM = 4;
     */
    ADOPTM = 4,
    /**
     * @generated from protobuf enum value: FTH = 5;
     */
    FTH = 5,
    /**
     * @generated from protobuf enum value: FTHFOST = 6;
     */
    FTHFOST = 6,
    /**
     * @generated from protobuf enum value: NFTH = 7;
     */
    NFTH = 7,
    /**
     * @generated from protobuf enum value: NFTHF = 8;
     */
    NFTHF = 8,
    /**
     * @generated from protobuf enum value: STPFTH = 9;
     */
    STPFTH = 9,
    /**
     * @generated from protobuf enum value: MTH = 10;
     */
    MTH = 10,
    /**
     * @generated from protobuf enum value: GESTM = 11;
     */
    GESTM = 11,
    /**
     * @generated from protobuf enum value: MTHFOST = 12;
     */
    MTHFOST = 12,
    /**
     * @generated from protobuf enum value: NMTH = 13;
     */
    NMTH = 13,
    /**
     * @generated from protobuf enum value: NMTHF = 14;
     */
    NMTHF = 14,
    /**
     * @generated from protobuf enum value: STPMTH = 15;
     */
    STPMTH = 15,
    /**
     * @generated from protobuf enum value: NPRN = 16;
     */
    NPRN = 16,
    /**
     * @generated from protobuf enum value: PRNFOST = 17;
     */
    PRNFOST = 17,
    /**
     * @generated from protobuf enum value: STPPRN = 18;
     */
    STPPRN = 18,
    /**
     * @generated from protobuf enum value: TWIN = 19;
     */
    TWIN = 19,
    /**
     * @generated from protobuf enum value: FTWIN = 20;
     */
    FTWIN = 20,
    /**
     * @generated from protobuf enum value: ITWIN = 21;
     */
    ITWIN = 21
}
/**
 * Uses of an address not included in Address.use.
 * See http://hl7.org/fhir/ValueSet/postal-address-use
 *
 * @generated from protobuf message google.fhir.r4.core.PostalAddressUseValueSet
 */
export interface PostalAddressUseValueSet {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.PostalAddressUseValueSet.Value
 */
export enum PostalAddressUseValueSet_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: BAD = 1;
     */
    BAD = 1,
    /**
     * @generated from protobuf enum value: CONF = 2;
     */
    CONF = 2,
    /**
     * @generated from protobuf enum value: HP = 3;
     */
    HP = 3,
    /**
     * @generated from protobuf enum value: HV = 4;
     */
    HV = 4,
    /**
     * @generated from protobuf enum value: DIR = 5;
     */
    DIR = 5,
    /**
     * @generated from protobuf enum value: PUB = 6;
     */
    PUB = 6,
    /**
     * @generated from protobuf enum value: PHYS = 7;
     */
    PHYS = 7,
    /**
     * @generated from protobuf enum value: PST = 8;
     */
    PST = 8
}
/**
 * Codes specifying the type of probability distribution.
 * See http://hl7.org/fhir/ValueSet/probability-distribution-type
 *
 * @generated from protobuf message google.fhir.r4.core.ProbabilityDistributionTypeValueSet
 */
export interface ProbabilityDistributionTypeValueSet {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.ProbabilityDistributionTypeValueSet.Value
 */
export enum ProbabilityDistributionTypeValueSet_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: B = 1;
     */
    B = 1,
    /**
     * @generated from protobuf enum value: E = 2;
     */
    E = 2,
    /**
     * @generated from protobuf enum value: F = 3;
     */
    F = 3,
    /**
     * @generated from protobuf enum value: G = 4;
     */
    G = 4,
    /**
     * @generated from protobuf enum value: LN = 5;
     */
    LN = 5,
    /**
     * @generated from protobuf enum value: N = 6;
     */
    N = 6,
    /**
     * @generated from protobuf enum value: T = 7;
     */
    T = 7,
    /**
     * @generated from protobuf enum value: U = 8;
     */
    U = 8,
    /**
     * @generated from protobuf enum value: X2 = 9;
     */
    X2 = 9
}
/**
 * Codes describing how the QuestionnaireResponse was populated
 * See http://hl7.org/fhir/ValueSet/questionnaireresponse-mode
 *
 * @generated from protobuf message google.fhir.r4.core.QuestionnaireResponseModeValueSet
 */
export interface QuestionnaireResponseModeValueSet {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.QuestionnaireResponseModeValueSet.Value
 */
export enum QuestionnaireResponseModeValueSet_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: ELECTRONIC = 1;
     */
    ELECTRONIC = 1,
    /**
     * @generated from protobuf enum value: VERBAL = 2;
     */
    VERBAL = 2,
    /**
     * @generated from protobuf enum value: WRITTEN = 3;
     */
    WRITTEN = 3
}
/**
 * The value set includes the v3 RoleCode SIB (sibling) and all of its
 * specializations.  It covers the relationships needed to establish genetic
 * pedigree relationships between family members. See
 * http://hl7.org/fhir/ValueSet/sibling-relationship-codes
 *
 * @generated from protobuf message google.fhir.r4.core.SiblingRelationshipCodesValueSet
 */
export interface SiblingRelationshipCodesValueSet {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.SiblingRelationshipCodesValueSet.Value
 */
export enum SiblingRelationshipCodesValueSet_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: SIB = 1;
     */
    SIB = 1,
    /**
     * @generated from protobuf enum value: BRO = 2;
     */
    BRO = 2,
    /**
     * @generated from protobuf enum value: HBRO = 3;
     */
    HBRO = 3,
    /**
     * @generated from protobuf enum value: NBRO = 4;
     */
    NBRO = 4,
    /**
     * @generated from protobuf enum value: TWINBRO = 5;
     */
    TWINBRO = 5,
    /**
     * @generated from protobuf enum value: FTWINBRO = 6;
     */
    FTWINBRO = 6,
    /**
     * @generated from protobuf enum value: ITWINBRO = 7;
     */
    ITWINBRO = 7,
    /**
     * @generated from protobuf enum value: STPBRO = 8;
     */
    STPBRO = 8,
    /**
     * @generated from protobuf enum value: HSIB = 9;
     */
    HSIB = 9,
    /**
     * @generated from protobuf enum value: HSIS = 10;
     */
    HSIS = 10,
    /**
     * @generated from protobuf enum value: NSIB = 11;
     */
    NSIB = 11,
    /**
     * @generated from protobuf enum value: NSIS = 12;
     */
    NSIS = 12,
    /**
     * @generated from protobuf enum value: TWINSIS = 13;
     */
    TWINSIS = 13,
    /**
     * @generated from protobuf enum value: FTWINSIS = 14;
     */
    FTWINSIS = 14,
    /**
     * @generated from protobuf enum value: ITWINSIS = 15;
     */
    ITWINSIS = 15,
    /**
     * @generated from protobuf enum value: TWIN = 16;
     */
    TWIN = 16,
    /**
     * @generated from protobuf enum value: FTWIN = 17;
     */
    FTWIN = 17,
    /**
     * @generated from protobuf enum value: ITWIN = 18;
     */
    ITWIN = 18,
    /**
     * @generated from protobuf enum value: SIS = 19;
     */
    SIS = 19,
    /**
     * @generated from protobuf enum value: STPSIS = 20;
     */
    STPSIS = 20,
    /**
     * @generated from protobuf enum value: STPSIB = 21;
     */
    STPSIB = 21
}
/**
 * Operations supported by REST at the system level.
 * See http://hl7.org/fhir/ValueSet/system-restful-interaction
 *
 * @generated from protobuf message google.fhir.r4.core.SystemRestfulInteractionValueSet
 */
export interface SystemRestfulInteractionValueSet {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.SystemRestfulInteractionValueSet.Value
 */
export enum SystemRestfulInteractionValueSet_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: TRANSACTION = 1;
     */
    TRANSACTION = 1,
    /**
     * @generated from protobuf enum value: BATCH = 2;
     */
    BATCH = 2,
    /**
     * @generated from protobuf enum value: SEARCH_SYSTEM = 3;
     */
    SEARCH_SYSTEM = 3,
    /**
     * @generated from protobuf enum value: HISTORY_SYSTEM = 4;
     */
    HISTORY_SYSTEM = 4
}
/**
 * Distinguishes whether the task is a proposal, plan or full order.
 * See http://hl7.org/fhir/ValueSet/task-intent
 *
 * @generated from protobuf message google.fhir.r4.core.TaskIntentValueSet
 */
export interface TaskIntentValueSet {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.TaskIntentValueSet.Value
 */
export enum TaskIntentValueSet_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: UNKNOWN = 1;
     */
    UNKNOWN = 1,
    /**
     * @generated from protobuf enum value: PROPOSAL = 2;
     */
    PROPOSAL = 2,
    /**
     * @generated from protobuf enum value: PLAN = 3;
     */
    PLAN = 3,
    /**
     * @generated from protobuf enum value: ORDER = 4;
     */
    ORDER = 4,
    /**
     * @generated from protobuf enum value: ORIGINAL_ORDER = 5;
     */
    ORIGINAL_ORDER = 5,
    /**
     * @generated from protobuf enum value: REFLEX_ORDER = 6;
     */
    REFLEX_ORDER = 6,
    /**
     * @generated from protobuf enum value: FILLER_ORDER = 7;
     */
    FILLER_ORDER = 7,
    /**
     * @generated from protobuf enum value: INSTANCE_ORDER = 8;
     */
    INSTANCE_ORDER = 8,
    /**
     * @generated from protobuf enum value: OPTION = 9;
     */
    OPTION = 9
}
/**
 * The status indicates the level of maturity of the design and may be used to
 * manage the use of the design. See
 * http://hl7.org/fhir/ValueSet/template-status-code
 *
 * @generated from protobuf message google.fhir.r4.core.TemplateStatusCodeValueSet
 */
export interface TemplateStatusCodeValueSet {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.TemplateStatusCodeValueSet.Value
 */
export enum TemplateStatusCodeValueSet_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: DRAFT = 1;
     */
    DRAFT = 1,
    /**
     * @generated from protobuf enum value: PENDING = 2;
     */
    PENDING = 2,
    /**
     * @generated from protobuf enum value: ACTIVE = 3;
     */
    ACTIVE = 3,
    /**
     * @generated from protobuf enum value: REVIEW = 4;
     */
    REVIEW = 4,
    /**
     * @generated from protobuf enum value: CANCELLED = 5;
     */
    CANCELLED = 5,
    /**
     * @generated from protobuf enum value: REJECTED = 6;
     */
    REJECTED = 6,
    /**
     * @generated from protobuf enum value: RETIRED = 7;
     */
    RETIRED = 7,
    /**
     * @generated from protobuf enum value: TERMINATED = 8;
     */
    TERMINATED = 8
}
/**
 * Operations supported by REST at the type or instance level.
 * See http://hl7.org/fhir/ValueSet/type-restful-interaction
 *
 * @generated from protobuf message google.fhir.r4.core.TypeRestfulInteractionValueSet
 */
export interface TypeRestfulInteractionValueSet {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.TypeRestfulInteractionValueSet.Value
 */
export enum TypeRestfulInteractionValueSet_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: READ = 1;
     */
    READ = 1,
    /**
     * @generated from protobuf enum value: VREAD = 2;
     */
    VREAD = 2,
    /**
     * @generated from protobuf enum value: UPDATE = 3;
     */
    UPDATE = 3,
    /**
     * @generated from protobuf enum value: PATCH = 4;
     */
    PATCH = 4,
    /**
     * @generated from protobuf enum value: DELETE = 5;
     */
    DELETE = 5,
    /**
     * @generated from protobuf enum value: HISTORY_INSTANCE = 6;
     */
    HISTORY_INSTANCE = 6,
    /**
     * @generated from protobuf enum value: HISTORY_TYPE = 7;
     */
    HISTORY_TYPE = 7,
    /**
     * @generated from protobuf enum value: CREATE = 8;
     */
    CREATE = 8,
    /**
     * @generated from protobuf enum value: SEARCH_TYPE = 9;
     */
    SEARCH_TYPE = 9
}
/**
 * A unit of time (units from UCUM).
 * See http://hl7.org/fhir/ValueSet/units-of-time
 *
 * @generated from protobuf message google.fhir.r4.core.UnitsOfTimeValueSet
 */
export interface UnitsOfTimeValueSet {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.UnitsOfTimeValueSet.Value
 */
export enum UnitsOfTimeValueSet_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: S = 1;
     */
    S = 1,
    /**
     * @generated from protobuf enum value: MIN = 2;
     */
    MIN = 2,
    /**
     * @generated from protobuf enum value: H = 3;
     */
    H = 3,
    /**
     * @generated from protobuf enum value: D = 4;
     */
    D = 4,
    /**
     * @generated from protobuf enum value: WK = 5;
     */
    WK = 5,
    /**
     * @generated from protobuf enum value: MO = 6;
     */
    MO = 6,
    /**
     * @generated from protobuf enum value: A = 7;
     */
    A = 7
}
/**
 *  Set of codes used to value Act.Confidentiality and Role.Confidentiality
 *  attribute in accordance with the definition for concept domain
 *  "Confidentiality".
 * See http://terminology.hl7.org/ValueSet/v3-ConfidentialityClassification
 *
 * @generated from protobuf message google.fhir.r4.core.V3ConfidentialityClassificationValueSet
 */
export interface V3ConfidentialityClassificationValueSet {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.V3ConfidentialityClassificationValueSet.Value
 */
export enum V3ConfidentialityClassificationValueSet_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: U = 1;
     */
    U = 1,
    /**
     * @generated from protobuf enum value: L = 2;
     */
    L = 2,
    /**
     * @generated from protobuf enum value: M = 3;
     */
    M = 3,
    /**
     * @generated from protobuf enum value: N = 4;
     */
    N = 4,
    /**
     * @generated from protobuf enum value: R = 5;
     */
    R = 5,
    /**
     * @generated from protobuf enum value: V = 6;
     */
    V = 6
}
/**
 * Common UCUM units for recording Vital Signs
 * See http://hl7.org/fhir/ValueSet/ucum-vitals-common
 *
 * @generated from protobuf message google.fhir.r4.core.VitalSignsUnitsValueSet
 */
export interface VitalSignsUnitsValueSet {
}
/**
 * @generated from protobuf enum google.fhir.r4.core.VitalSignsUnitsValueSet.Value
 */
export enum VitalSignsUnitsValueSet_Value {
    /**
     * @generated from protobuf enum value: INVALID_UNINITIALIZED = 0;
     */
    INVALID_UNINITIALIZED = 0,
    /**
     * @generated from protobuf enum value: PERCENT = 1;
     */
    PERCENT = 1,
    /**
     * @generated from protobuf enum value: CM = 2;
     */
    CM = 2,
    /**
     * @generated from protobuf enum value: IN_I = 3;
     */
    IN_I = 3,
    /**
     * @generated from protobuf enum value: KG = 4;
     */
    KG = 4,
    /**
     * @generated from protobuf enum value: G = 5;
     */
    G = 5,
    /**
     * @generated from protobuf enum value: LB_AV = 6;
     */
    LB_AV = 6,
    /**
     * @generated from protobuf enum value: CEL = 7;
     */
    CEL = 7,
    /**
     * @generated from protobuf enum value: DEG_F = 8;
     */
    DEG_F = 8,
    /**
     * @generated from protobuf enum value: MM_HG = 9;
     */
    MM_HG = 9,
    /**
     * @generated from protobuf enum value: PER_MIN = 10;
     */
    PER_MIN = 10,
    /**
     * @generated from protobuf enum value: KG_M2 = 11;
     */
    KG_M2 = 11,
    /**
     * @generated from protobuf enum value: M2 = 12;
     */
    M2 = 12
}
// @generated message type with reflection information, may provide speed optimized methods
class BodyLengthUnitsValueSet$Type extends MessageType<BodyLengthUnitsValueSet> {
    constructor() {
        super("google.fhir.r4.core.BodyLengthUnitsValueSet", []);
    }
    create(value?: PartialMessage<BodyLengthUnitsValueSet>): BodyLengthUnitsValueSet {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BodyLengthUnitsValueSet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BodyLengthUnitsValueSet): BodyLengthUnitsValueSet {
        return target ?? this.create();
    }
    internalBinaryWrite(message: BodyLengthUnitsValueSet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.BodyLengthUnitsValueSet
 */
export const BodyLengthUnitsValueSet = new BodyLengthUnitsValueSet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BodyTemperatureUnitsValueSet$Type extends MessageType<BodyTemperatureUnitsValueSet> {
    constructor() {
        super("google.fhir.r4.core.BodyTemperatureUnitsValueSet", []);
    }
    create(value?: PartialMessage<BodyTemperatureUnitsValueSet>): BodyTemperatureUnitsValueSet {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BodyTemperatureUnitsValueSet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BodyTemperatureUnitsValueSet): BodyTemperatureUnitsValueSet {
        return target ?? this.create();
    }
    internalBinaryWrite(message: BodyTemperatureUnitsValueSet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.BodyTemperatureUnitsValueSet
 */
export const BodyTemperatureUnitsValueSet = new BodyTemperatureUnitsValueSet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BodyWeightUnitsValueSet$Type extends MessageType<BodyWeightUnitsValueSet> {
    constructor() {
        super("google.fhir.r4.core.BodyWeightUnitsValueSet", []);
    }
    create(value?: PartialMessage<BodyWeightUnitsValueSet>): BodyWeightUnitsValueSet {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BodyWeightUnitsValueSet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BodyWeightUnitsValueSet): BodyWeightUnitsValueSet {
        return target ?? this.create();
    }
    internalBinaryWrite(message: BodyWeightUnitsValueSet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.BodyWeightUnitsValueSet
 */
export const BodyWeightUnitsValueSet = new BodyWeightUnitsValueSet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CarePlanActivityKindValueSet$Type extends MessageType<CarePlanActivityKindValueSet> {
    constructor() {
        super("google.fhir.r4.core.CarePlanActivityKindValueSet", []);
    }
    create(value?: PartialMessage<CarePlanActivityKindValueSet>): CarePlanActivityKindValueSet {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CarePlanActivityKindValueSet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CarePlanActivityKindValueSet): CarePlanActivityKindValueSet {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CarePlanActivityKindValueSet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.CarePlanActivityKindValueSet
 */
export const CarePlanActivityKindValueSet = new CarePlanActivityKindValueSet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CarePlanIntentValueSet$Type extends MessageType<CarePlanIntentValueSet> {
    constructor() {
        super("google.fhir.r4.core.CarePlanIntentValueSet", []);
    }
    create(value?: PartialMessage<CarePlanIntentValueSet>): CarePlanIntentValueSet {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CarePlanIntentValueSet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CarePlanIntentValueSet): CarePlanIntentValueSet {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CarePlanIntentValueSet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.CarePlanIntentValueSet
 */
export const CarePlanIntentValueSet = new CarePlanIntentValueSet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClinicalImpressionStatusValueSet$Type extends MessageType<ClinicalImpressionStatusValueSet> {
    constructor() {
        super("google.fhir.r4.core.ClinicalImpressionStatusValueSet", []);
    }
    create(value?: PartialMessage<ClinicalImpressionStatusValueSet>): ClinicalImpressionStatusValueSet {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ClinicalImpressionStatusValueSet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ClinicalImpressionStatusValueSet): ClinicalImpressionStatusValueSet {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ClinicalImpressionStatusValueSet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ClinicalImpressionStatusValueSet
 */
export const ClinicalImpressionStatusValueSet = new ClinicalImpressionStatusValueSet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EntityNamePartQualifierValueSet$Type extends MessageType<EntityNamePartQualifierValueSet> {
    constructor() {
        super("google.fhir.r4.core.EntityNamePartQualifierValueSet", []);
    }
    create(value?: PartialMessage<EntityNamePartQualifierValueSet>): EntityNamePartQualifierValueSet {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EntityNamePartQualifierValueSet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EntityNamePartQualifierValueSet): EntityNamePartQualifierValueSet {
        return target ?? this.create();
    }
    internalBinaryWrite(message: EntityNamePartQualifierValueSet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.EntityNamePartQualifierValueSet
 */
export const EntityNamePartQualifierValueSet = new EntityNamePartQualifierValueSet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventTimingValueSet$Type extends MessageType<EventTimingValueSet> {
    constructor() {
        super("google.fhir.r4.core.EventTimingValueSet", []);
    }
    create(value?: PartialMessage<EventTimingValueSet>): EventTimingValueSet {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EventTimingValueSet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventTimingValueSet): EventTimingValueSet {
        return target ?? this.create();
    }
    internalBinaryWrite(message: EventTimingValueSet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.EventTimingValueSet
 */
export const EventTimingValueSet = new EventTimingValueSet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FHIRAllTypesValueSet$Type extends MessageType<FHIRAllTypesValueSet> {
    constructor() {
        super("google.fhir.r4.core.FHIRAllTypesValueSet", []);
    }
    create(value?: PartialMessage<FHIRAllTypesValueSet>): FHIRAllTypesValueSet {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FHIRAllTypesValueSet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FHIRAllTypesValueSet): FHIRAllTypesValueSet {
        return target ?? this.create();
    }
    internalBinaryWrite(message: FHIRAllTypesValueSet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.FHIRAllTypesValueSet
 */
export const FHIRAllTypesValueSet = new FHIRAllTypesValueSet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FHIRDefinedTypeValueSet$Type extends MessageType<FHIRDefinedTypeValueSet> {
    constructor() {
        super("google.fhir.r4.core.FHIRDefinedTypeValueSet", []);
    }
    create(value?: PartialMessage<FHIRDefinedTypeValueSet>): FHIRDefinedTypeValueSet {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FHIRDefinedTypeValueSet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FHIRDefinedTypeValueSet): FHIRDefinedTypeValueSet {
        return target ?? this.create();
    }
    internalBinaryWrite(message: FHIRDefinedTypeValueSet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.FHIRDefinedTypeValueSet
 */
export const FHIRDefinedTypeValueSet = new FHIRDefinedTypeValueSet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HumanNameAssemblyOrderValueSet$Type extends MessageType<HumanNameAssemblyOrderValueSet> {
    constructor() {
        super("google.fhir.r4.core.HumanNameAssemblyOrderValueSet", []);
    }
    create(value?: PartialMessage<HumanNameAssemblyOrderValueSet>): HumanNameAssemblyOrderValueSet {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<HumanNameAssemblyOrderValueSet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HumanNameAssemblyOrderValueSet): HumanNameAssemblyOrderValueSet {
        return target ?? this.create();
    }
    internalBinaryWrite(message: HumanNameAssemblyOrderValueSet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.HumanNameAssemblyOrderValueSet
 */
export const HumanNameAssemblyOrderValueSet = new HumanNameAssemblyOrderValueSet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImmunizationEvaluationStatusCodesValueSet$Type extends MessageType<ImmunizationEvaluationStatusCodesValueSet> {
    constructor() {
        super("google.fhir.r4.core.ImmunizationEvaluationStatusCodesValueSet", []);
    }
    create(value?: PartialMessage<ImmunizationEvaluationStatusCodesValueSet>): ImmunizationEvaluationStatusCodesValueSet {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImmunizationEvaluationStatusCodesValueSet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImmunizationEvaluationStatusCodesValueSet): ImmunizationEvaluationStatusCodesValueSet {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ImmunizationEvaluationStatusCodesValueSet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ImmunizationEvaluationStatusCodesValueSet
 */
export const ImmunizationEvaluationStatusCodesValueSet = new ImmunizationEvaluationStatusCodesValueSet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImmunizationStatusCodesValueSet$Type extends MessageType<ImmunizationStatusCodesValueSet> {
    constructor() {
        super("google.fhir.r4.core.ImmunizationStatusCodesValueSet", []);
    }
    create(value?: PartialMessage<ImmunizationStatusCodesValueSet>): ImmunizationStatusCodesValueSet {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImmunizationStatusCodesValueSet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImmunizationStatusCodesValueSet): ImmunizationStatusCodesValueSet {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ImmunizationStatusCodesValueSet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ImmunizationStatusCodesValueSet
 */
export const ImmunizationStatusCodesValueSet = new ImmunizationStatusCodesValueSet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LDLCodesValueSet$Type extends MessageType<LDLCodesValueSet> {
    constructor() {
        super("google.fhir.r4.core.LDLCodesValueSet", []);
    }
    create(value?: PartialMessage<LDLCodesValueSet>): LDLCodesValueSet {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LDLCodesValueSet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LDLCodesValueSet): LDLCodesValueSet {
        return target ?? this.create();
    }
    internalBinaryWrite(message: LDLCodesValueSet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.LDLCodesValueSet
 */
export const LDLCodesValueSet = new LDLCodesValueSet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NameRepresentationUseValueSet$Type extends MessageType<NameRepresentationUseValueSet> {
    constructor() {
        super("google.fhir.r4.core.NameRepresentationUseValueSet", []);
    }
    create(value?: PartialMessage<NameRepresentationUseValueSet>): NameRepresentationUseValueSet {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NameRepresentationUseValueSet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NameRepresentationUseValueSet): NameRepresentationUseValueSet {
        return target ?? this.create();
    }
    internalBinaryWrite(message: NameRepresentationUseValueSet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.NameRepresentationUseValueSet
 */
export const NameRepresentationUseValueSet = new NameRepresentationUseValueSet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ParentRelationshipCodesValueSet$Type extends MessageType<ParentRelationshipCodesValueSet> {
    constructor() {
        super("google.fhir.r4.core.ParentRelationshipCodesValueSet", []);
    }
    create(value?: PartialMessage<ParentRelationshipCodesValueSet>): ParentRelationshipCodesValueSet {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ParentRelationshipCodesValueSet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ParentRelationshipCodesValueSet): ParentRelationshipCodesValueSet {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ParentRelationshipCodesValueSet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ParentRelationshipCodesValueSet
 */
export const ParentRelationshipCodesValueSet = new ParentRelationshipCodesValueSet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostalAddressUseValueSet$Type extends MessageType<PostalAddressUseValueSet> {
    constructor() {
        super("google.fhir.r4.core.PostalAddressUseValueSet", []);
    }
    create(value?: PartialMessage<PostalAddressUseValueSet>): PostalAddressUseValueSet {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostalAddressUseValueSet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostalAddressUseValueSet): PostalAddressUseValueSet {
        return target ?? this.create();
    }
    internalBinaryWrite(message: PostalAddressUseValueSet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.PostalAddressUseValueSet
 */
export const PostalAddressUseValueSet = new PostalAddressUseValueSet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProbabilityDistributionTypeValueSet$Type extends MessageType<ProbabilityDistributionTypeValueSet> {
    constructor() {
        super("google.fhir.r4.core.ProbabilityDistributionTypeValueSet", []);
    }
    create(value?: PartialMessage<ProbabilityDistributionTypeValueSet>): ProbabilityDistributionTypeValueSet {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProbabilityDistributionTypeValueSet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProbabilityDistributionTypeValueSet): ProbabilityDistributionTypeValueSet {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ProbabilityDistributionTypeValueSet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.ProbabilityDistributionTypeValueSet
 */
export const ProbabilityDistributionTypeValueSet = new ProbabilityDistributionTypeValueSet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuestionnaireResponseModeValueSet$Type extends MessageType<QuestionnaireResponseModeValueSet> {
    constructor() {
        super("google.fhir.r4.core.QuestionnaireResponseModeValueSet", []);
    }
    create(value?: PartialMessage<QuestionnaireResponseModeValueSet>): QuestionnaireResponseModeValueSet {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<QuestionnaireResponseModeValueSet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QuestionnaireResponseModeValueSet): QuestionnaireResponseModeValueSet {
        return target ?? this.create();
    }
    internalBinaryWrite(message: QuestionnaireResponseModeValueSet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.QuestionnaireResponseModeValueSet
 */
export const QuestionnaireResponseModeValueSet = new QuestionnaireResponseModeValueSet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SiblingRelationshipCodesValueSet$Type extends MessageType<SiblingRelationshipCodesValueSet> {
    constructor() {
        super("google.fhir.r4.core.SiblingRelationshipCodesValueSet", []);
    }
    create(value?: PartialMessage<SiblingRelationshipCodesValueSet>): SiblingRelationshipCodesValueSet {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SiblingRelationshipCodesValueSet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SiblingRelationshipCodesValueSet): SiblingRelationshipCodesValueSet {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SiblingRelationshipCodesValueSet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.SiblingRelationshipCodesValueSet
 */
export const SiblingRelationshipCodesValueSet = new SiblingRelationshipCodesValueSet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SystemRestfulInteractionValueSet$Type extends MessageType<SystemRestfulInteractionValueSet> {
    constructor() {
        super("google.fhir.r4.core.SystemRestfulInteractionValueSet", []);
    }
    create(value?: PartialMessage<SystemRestfulInteractionValueSet>): SystemRestfulInteractionValueSet {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SystemRestfulInteractionValueSet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SystemRestfulInteractionValueSet): SystemRestfulInteractionValueSet {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SystemRestfulInteractionValueSet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.SystemRestfulInteractionValueSet
 */
export const SystemRestfulInteractionValueSet = new SystemRestfulInteractionValueSet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskIntentValueSet$Type extends MessageType<TaskIntentValueSet> {
    constructor() {
        super("google.fhir.r4.core.TaskIntentValueSet", []);
    }
    create(value?: PartialMessage<TaskIntentValueSet>): TaskIntentValueSet {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TaskIntentValueSet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TaskIntentValueSet): TaskIntentValueSet {
        return target ?? this.create();
    }
    internalBinaryWrite(message: TaskIntentValueSet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.TaskIntentValueSet
 */
export const TaskIntentValueSet = new TaskIntentValueSet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TemplateStatusCodeValueSet$Type extends MessageType<TemplateStatusCodeValueSet> {
    constructor() {
        super("google.fhir.r4.core.TemplateStatusCodeValueSet", []);
    }
    create(value?: PartialMessage<TemplateStatusCodeValueSet>): TemplateStatusCodeValueSet {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TemplateStatusCodeValueSet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TemplateStatusCodeValueSet): TemplateStatusCodeValueSet {
        return target ?? this.create();
    }
    internalBinaryWrite(message: TemplateStatusCodeValueSet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.TemplateStatusCodeValueSet
 */
export const TemplateStatusCodeValueSet = new TemplateStatusCodeValueSet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TypeRestfulInteractionValueSet$Type extends MessageType<TypeRestfulInteractionValueSet> {
    constructor() {
        super("google.fhir.r4.core.TypeRestfulInteractionValueSet", []);
    }
    create(value?: PartialMessage<TypeRestfulInteractionValueSet>): TypeRestfulInteractionValueSet {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TypeRestfulInteractionValueSet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TypeRestfulInteractionValueSet): TypeRestfulInteractionValueSet {
        return target ?? this.create();
    }
    internalBinaryWrite(message: TypeRestfulInteractionValueSet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.TypeRestfulInteractionValueSet
 */
export const TypeRestfulInteractionValueSet = new TypeRestfulInteractionValueSet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UnitsOfTimeValueSet$Type extends MessageType<UnitsOfTimeValueSet> {
    constructor() {
        super("google.fhir.r4.core.UnitsOfTimeValueSet", []);
    }
    create(value?: PartialMessage<UnitsOfTimeValueSet>): UnitsOfTimeValueSet {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UnitsOfTimeValueSet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UnitsOfTimeValueSet): UnitsOfTimeValueSet {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UnitsOfTimeValueSet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.UnitsOfTimeValueSet
 */
export const UnitsOfTimeValueSet = new UnitsOfTimeValueSet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class V3ConfidentialityClassificationValueSet$Type extends MessageType<V3ConfidentialityClassificationValueSet> {
    constructor() {
        super("google.fhir.r4.core.V3ConfidentialityClassificationValueSet", []);
    }
    create(value?: PartialMessage<V3ConfidentialityClassificationValueSet>): V3ConfidentialityClassificationValueSet {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<V3ConfidentialityClassificationValueSet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: V3ConfidentialityClassificationValueSet): V3ConfidentialityClassificationValueSet {
        return target ?? this.create();
    }
    internalBinaryWrite(message: V3ConfidentialityClassificationValueSet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.V3ConfidentialityClassificationValueSet
 */
export const V3ConfidentialityClassificationValueSet = new V3ConfidentialityClassificationValueSet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VitalSignsUnitsValueSet$Type extends MessageType<VitalSignsUnitsValueSet> {
    constructor() {
        super("google.fhir.r4.core.VitalSignsUnitsValueSet", []);
    }
    create(value?: PartialMessage<VitalSignsUnitsValueSet>): VitalSignsUnitsValueSet {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<VitalSignsUnitsValueSet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VitalSignsUnitsValueSet): VitalSignsUnitsValueSet {
        return target ?? this.create();
    }
    internalBinaryWrite(message: VitalSignsUnitsValueSet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message google.fhir.r4.core.VitalSignsUnitsValueSet
 */
export const VitalSignsUnitsValueSet = new VitalSignsUnitsValueSet$Type();
