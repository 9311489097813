import {CircularProgress} from '@verily-src/react-design-system';
import {useTranslation} from 'react-i18next';
import styles from './LoadingCircularProgress.css';

export default ({loading}) => {
  const {t} = useTranslation();
  return loading ? (
    <div className={styles.spinnerOverlay}>
      <CircularProgress
        useUnlaunchedComponent={true}
        aria-label={t('ariaCircularProgress_common_common')}
        data-testid="spinner"
      />
    </div>
  ) : null;
};
