import {Box, InputAdornment, useMediaQuery, useTheme} from '@mui/material';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {
  AddCircleIcon,
  Button,
  PhotoIcon,
  SendIcon,
  TextField,
} from '@verily-src/react-design-system';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import AttachmentInput from '../../../../foreground/components/AttachmentInput/AttachmentInput';
import AttachmentPreviewWithClose from '../../../../foreground/components/AttachmentPreview/AttachmentPreviewWithClose';
import {ThreadDetailSendBoxProps} from '../../../../foreground/utility/custComponentProps';
import hideNav from '../../../../foreground/utility/hideNav';
import {breakpointValues} from '../../styles/breakpoints';

export default function SendBox(props: ThreadDetailSendBoxProps) {
  const {t} = useTranslation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(`(min-width:${breakpointValues.md}px)`);
  useEffect(() => {
    hideNav(!isDesktop);
    return () => {
      hideNav(false);
    };
  }, [isDesktop]);
  const extraSx = isDesktop
    ? {
        padding: '16px',
        border: 1,
        borderRadius: '0px 0px 16px 16px',
      }
    : {
        padding: '16px 16px 0px 16px',
        margin: '0px -16px -74px -16px',
        borderTop: 1,
      };
  const iconTheme = props.fileAttachment
    ? theme.palette.icon.disabled
    : theme.palette.primary.main;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
        gap: '4px',
        ...extraSx,
        borderColor: 'background.separator',
      }}
    >
      <AttachmentInput
        buttonProps={{
          id: 'messaging_threaddetail_attachment',
          sx: {
            minHeight: '48px',
            minWidth: '48px',
            '&:hover': {backgroundColor: 'state.hoverPrimary'},
          },
          disabled: !!props.fileAttachment,
        }}
        attachIconHandler={props.attachIconHandler}
      >
        {
          // TODO: Delete feature flag after merged to production
          config.getBoolean('FEATURE_PDF_ATTACHMENT_ENABLED') ? (
            <AddCircleIcon sx={{['& path']: {fill: iconTheme}}} />
          ) : (
            <PhotoIcon sx={{['& path']: {fill: iconTheme}}} />
          )
        }
      </AttachmentInput>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 'auto',
        }}
      >
        {props.fileAttachment && (
          <Box
            sx={{
              display: 'flex',
              padding: '16px',
              border: 1,
              borderBottom: 0,
              borderRadius: '16px 16px 0px 0px',
              borderColor: 'background.separator',
            }}
          >
            <AttachmentPreviewWithClose
              mimeType={props.fileAttachment.type}
              url={props.fileAttachment.url}
              dimension={160}
              onClickHandler={props.closeAttachHandler}
              meta={props.fileAttachment.meta}
            />
          </Box>
        )}
        <TextField
          id={props.textFieldId}
          placeholder={t('messagePlaceHolder_threadDetail_verilyMe')}
          value={props.textBoxValue}
          maxRows={4}
          autoFocus={true}
          fullWidth={true}
          onChange={props.textBoxHandler}
          inputProps={{
            style: {
              margin: '0 8px 0 16px',
              padding: '12px 0',
            },
          }}
          InputProps={{
            style: {
              borderRadius: props.fileAttachment ? '0px 0px 24px 24px' : '24px',
            },
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  id="messaging_threaddetail_send"
                  color="primary"
                  variant="text"
                  onClick={props.sendIconHandler}
                  aria-label={t('ariaSendMessage_common_common')}
                  title={t('ariaSendMessage_common_common')}
                  sx={{minHeight: '48px', minWidth: '48px'}}
                  icon={<SendIcon />}
                  data-testid="send-icon"
                  disabled={props.buttonDisable}
                />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  );
}
