import {useEffect, useState} from 'react';
import createBFFClient from '../../../api/createBFFClient';
import {listThreads} from '../../../api/listThreads';
import {useThemeContext} from '../../../foreground/components/ThemeContext/ThemeContext';
import {getTrigger, pubTrigger} from '../../utility/pollingNotifier';
import {pubThreads, resetThreads} from '../../utility/threadNotifier';
const pollingInterval = 30000;

export default function ThreadsListener() {
  const [pollingTrigger, setPollingTrigger] = useState<number>(0);
  const themeCont = useThemeContext();
  const applicationSuite = themeCont.applicationSuite;
  const applicationSuiteData = themeCont?.applicationSuiteData;
  const professionalAccountRefName =
    applicationSuiteData?.professionalAccountRefName;
  const participantRecordRefName =
    applicationSuiteData?.participantRecordRefName;
  const client = createBFFClient();
  let threadReceived;
  useEffect(() => {
    const triggerObserable = getTrigger();
    const sub = triggerObserable.subscribe({
      next: obs => {
        setPollingTrigger(obs);
      },
    });
    return () => {
      sub.unsubscribe();
      resetThreads();
    };
  }, [participantRecordRefName]);
  useEffect(() => {
    // TODO: update to use timestamp for incremental polling.
    const threadUpdate = async () => {
      try {
        threadReceived = (
          await listThreads(
            client,
            {},
            participantRecordRefName,
            professionalAccountRefName,
            applicationSuite
          )
        ).threadWraps;
        pubThreads({
          threads: threadReceived,
          error: null,
          maxUpdateTime: 0,
        });
      } catch (err) {
        console.log(err);
        pubThreads({
          threads: threadReceived,
          error: err,
          maxUpdateTime: 0,
        });
      }
    };
    threadUpdate();
    const nextTrigger = setTimeout(() => {
      pubTrigger();
    }, pollingInterval);
    return () => {
      clearTimeout(nextTrigger);
    };
  }, [participantRecordRefName, pollingTrigger]);
  return null;
}
