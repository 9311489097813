import {ThreadDetailHeaderProps} from '../../../../foreground/utility/custComponentProps';
import CommonHeader from '../Common/CommonHeader';
export default function Header(props: ThreadDetailHeaderProps) {
  return (
    <CommonHeader
      head={props.threadName}
      onClickHandler={props.onClickHandler}
    />
  );
}
