// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ThreadHeader__LfuNb{align-items:center;align-self:stretch;display:flex;gap:8px}`, "",{"version":3,"sources":["webpack://./src/customizations/verilyme/components/ThreadsOverview/Thread.css"],"names":[],"mappings":"AAAA,qBAIE,kBAAmB,CADnB,kBAAmB,CAFnB,YAAa,CACb,OAGF","sourcesContent":[".ThreadHeader {\n  display: flex;\n  gap: 8px;\n  align-self: stretch;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ThreadHeader": `ThreadHeader__LfuNb`
};
export default ___CSS_LOADER_EXPORT___;
